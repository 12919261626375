// React core imports
import { useState, useEffect, useRef } from "react"

// React Router imports for navigation and URL management
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

// Redux Toolkit Query (RTK Query) for data fetching
import { useGetBusinessDetailsQuery } from "../../redux/business/business.api"

// Material-UI core components
import { Card, CardContent, Typography, IconButton, Box, CardHeader } from "@mui/material"

// Material-UI icons
import MoreVertIcon from "@mui/icons-material/MoreVert"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined"
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined"
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined"
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined"
import PhoneIcon from "@mui/icons-material/Phone"

// MUI styled component
import { styled } from "@mui/material/styles"

// Type imports
import type { IBusiness, ILocation } from "../../types/businessDetailsTypes"

// Custom project components
import { ThreeSixtyMenu } from "../../components/ThreeSixtyMenu"
import { BusinessDetailsSkeleton } from "../../layouts/Skeletons/BusinessDetailsSkeleton"

// Utility functions
import { formatDate } from "../../utils/formatDate"
import { formatPhoneNumber } from "../../utils/formatPhoneNumber"
import { getAddressString } from "../../utils/getAddressString"
import { getOptions } from "../../data/menuOptions"

// Contexts
import { useUserContext } from "../../context/UserContext"

export const CardStyled = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  "&:first-of-type": { marginTop: 0 },
  "&:last-of-type": { marginBottom: theme.spacing(0) },
}))

export function BusinessDetails() {
  const userData = useUserContext()
  const [businessDetails, setBusinessDetails] = useState<IBusiness>()
  const [anchorEls, setAnchorEls] = useState<Record<string, HTMLElement | null>>({})
  const businessId360 = useRef<string>("")
  const locationId360 = useRef<string>("")
  const locationName360 = useRef<string>("")
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const businessId = searchParams.get("businessId") ?? ""
  const { data, error, isLoading } = useGetBusinessDetailsQuery(businessId)

  const location = useLocation()

  useEffect(() => {
    if (error != null) {
      console.log(error)
    } else if (data?.data !== undefined) {
      // Copy the business data from the API response
      const businessData: IBusiness = { ...data.data.business }
      setBusinessDetails(businessData)
    }
  }, [data, error, userData])

  if (isLoading) {
    return <BusinessDetailsSkeleton />
  }

  if (error != null || businessDetails == null || businessDetails === undefined) {
    // Optionally, provide more information on the error based on the error object
    return <div>Error loading business details</div>
  }

  const handleClose = (storeId: string) => {
    setAnchorEls({ ...anchorEls, [storeId]: null })
  }

  const handleBussinesClick = (event: React.MouseEvent<HTMLButtonElement>, business: IBusiness) => {
    localStorage.setItem("option", "false")
    businessId360.current = business.businessInfo.businessId
    locationId360.current = business.stores[0].id
    locationName360.current = business.stores[0].name
    setAnchorEls({
      ...anchorEls,
      [businessId360.current]: event.currentTarget,
    })
  }

  const handleStoreClick = (event: React.MouseEvent<HTMLButtonElement>, storeItem: ILocation) => {
    localStorage.setItem("option", "true")
    businessId360.current = storeItem.businessId
    locationId360.current = storeItem.id
    locationName360.current = storeItem.name
    setAnchorEls({ ...anchorEls, [locationId360.current]: event.currentTarget })
  }

  const handleCloseBusiness = () => {
    handleClose(businessDetails.businessInfo.businessId)
  }

  const businessOptions = getOptions(
    handleCloseBusiness,
    navigate,
    businessId360.current,
    locationId360.current,
    locationName360.current,
    location
  )

  const handleCloseStore = (storeItem?: any) => {
    handleClose(storeItem?.id)
  }

  if (isLoading) {
    return <BusinessDetailsSkeleton />
  }

  return (
    <div className="w-full sm:max-w-3xl mb-20 mx-auto">
      <Typography component="div" textAlign="center" variant="h4">
        Business Details
      </Typography>
      <CardStyled variant="outlined">
        <CardHeader
          sx={{
            marginX: 0,
            paddingX: 1,
            paddingY: { xs: 0.5, sm: 1 },
            borderRadius: "1px",
            backgroundColor: "#DEE2E6",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          title={
            <>
              <Typography variant="h5" fontWeight="500" sx={{ gridColumn: "span 2" }}>
                {businessDetails?.businessInfo.name}
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  color: "#000c",
                  marginTop: 1,
                  gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                  columnGap: 1,
                  rowGap: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "18px 1fr",
                    rowGap: 0.5,
                    columnGap: 1,
                    alignItems: "flex-start",
                  }}
                >
                  <>
                    <LocationOnIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
                    <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                      {getAddressString(businessDetails.businessInfo)}
                    </Typography>
                  </>
                  <>
                    <PersonOutlineOutlinedIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
                    <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                      Owner: {businessDetails?.businessInfo.owner}
                    </Typography>
                  </>
                  <>
                    <ContactPhoneOutlinedIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
                    <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                      Primary Contact: {businessDetails?.businessInfo.primaryContact}
                    </Typography>
                  </>
                  <>
                    <WatchLaterOutlinedIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
                    <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                      Hours of Operation: {businessDetails?.stores[0].hours}
                    </Typography>
                  </>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "18px 1fr",
                    rowGap: 0.5,
                    columnGap: 1,
                    alignItems: "flex-start",
                  }}
                >
                  <>
                    <PhoneIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
                    <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                      {businessDetails?.businessInfo.phone !== ""
                        ? `Phone 1: ${formatPhoneNumber(businessDetails.businessInfo.phone)}`
                        : "No primary phone"}
                    </Typography>
                  </>{" "}
                  <>
                    <PhoneIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
                    <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                      {businessDetails?.businessInfo.secondaryPhone !== ""
                        ? `Phone 2: ${formatPhoneNumber(
                            businessDetails.businessInfo.secondaryPhone
                          )}`
                        : "No secondary phone"}
                    </Typography>
                  </>
                  <>
                    <BusinessOutlinedIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
                    <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                      Business Type: {businessDetails.businessInfo.businessType}
                    </Typography>
                  </>
                </Box>
              </Box>
            </>
          }
          action={
            <>
              <IconButton
                onClick={(event) => {
                  handleBussinesClick(event, businessDetails)
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <ThreeSixtyMenu
                id={`business-menu-${businessDetails.businessInfo.businessId}`}
                anchorEl={anchorEls[businessDetails.businessInfo.businessId]}
                onClose={() => {
                  handleClose(businessDetails.businessInfo.businessId)
                }}
                options={businessOptions}
              />
            </>
          }
        />
      </CardStyled>
      {businessDetails?.stores.map((storeItem: ILocation) => {
        const storeOptions = getOptions(
          handleCloseStore,
          navigate,
          businessId360.current,
          locationId360.current,
          locationName360.current,
          location,
          storeItem
        )
        return (
          <CardStyled
            sx={{
              backgroundColor: "#E9ECEF",
              paddingLeft: 0,
            }}
            key={storeItem.id}
          >
            <CardHeader
              sx={{
                marginX: 0,
                paddingX: 1,
                paddingY: 0,
                borderRadius: "1px",
                backgroundColor: "#DEE2E6",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              title={
                <Typography fontWeight="500">
                  {storeItem.id} {storeItem.sofwareName}
                </Typography>
              }
              action={
                <IconButton
                  onClick={(event) => {
                    handleStoreClick(event, storeItem)
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              }
            />
            <CardContent
              sx={{
                marginX: 0,
                paddingX: 1,
                paddingY: 0,
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 1,
                "@media (max-width:600px)": {
                  gridTemplateColumns: "1fr",
                  gap: 0,
                },
              }}
            >
              <Box>
                <Typography variant="body2">Terminals: {storeItem.terminalCount}</Typography>
                <Typography variant="body2">
                  Play From Home: {storeItem.pfh ? "True" : "False"}
                </Typography>
                <Typography variant="body2">Session Type: {storeItem.sessionType}</Typography>
                <Typography variant="body2">Skill Type: {storeItem.skillType}</Typography>
              </Box>
              <Box>
                <Typography variant="body2">Updated: {formatDate(storeItem.lastUpdate)}</Typography>
                <Typography variant="body2">Status: {storeItem.locationStatus}</Typography>
                {(storeItem.locationStatus.toLowerCase() === "hold" ||
                  storeItem.locationStatus.toLowerCase() === "closed") && (
                  <Typography variant="body2">
                    Status Reason:{" "}
                    {storeItem.locationStatusReason !== "" ? storeItem.locationStatusReason : "N/A"}
                  </Typography>
                )}
                <Typography variant="body2">
                  Next Potential Win: {storeItem.nextPotentialWin}
                </Typography>
                <Typography variant="body2">
                  External Progressive: {storeItem.extProgressives}
                </Typography>
              </Box>

              <ThreeSixtyMenu
                id={`store-menu-${storeItem.id}`}
                anchorEl={anchorEls[storeItem.id]}
                onClose={() => {
                  handleClose(storeItem.id)
                }}
                options={storeOptions}
              />
            </CardContent>
          </CardStyled>
        )
      })}
    </div>
  )
}
