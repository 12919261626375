import Box from "@mui/material/Box"
import { Container, CssBaseline, Typography, Alert, Link } from "@mui/material"
import logo from "../../assets/logo.svg"
import { useState, type FormEvent } from "react"
import { PasswordResetRequest } from "../../components/Recover/PasswordResetRequest"
import { PasswordResetSent } from "../../components/Recover/PasswordResetSent"
import { useRequestPasswordResetMutation } from "../../redux/user/user.api"
import { isValidEmail } from "../../utils/isValidEmail"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

export const Recover = () => {
  const [email, setEmail] = useState("")
  const [showResetRequest, setShowResetRequest] = useState(true)
  const [showResetRequestSent, setShowResetRequestSent] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [emailError, setEmailError] = useState("")
  const [loading, setLoading] = useState(false)
  const [requestPasswordReset] = useRequestPasswordResetMutation()

  // Use the built-in useGoogleReCaptcha hook
  const { executeRecaptcha } = useGoogleReCaptcha()

  const resetHandler = async (e: FormEvent<HTMLFormElement>) => {
    if (!isValidEmail(email)) {
      setEmailError("Invalid email address")
      return
    }

    setEmailError("")
    setLoading(true)

    try {
      // Execute reCAPTCHA before proceeding
      const recaptchaToken = await executeRecaptcha?.("password_reset")
      if (recaptchaToken == null) {
        setErrorMessage("reCAPTCHA verification failed.")
        setLoading(false)
        return
      }

      const res = await requestPasswordReset({ email, recaptchaToken }).unwrap()
      if (res.rc === 1000) {
        setErrorMessage("")
        setShowResetRequest(false)
        setShowResetRequestSent(true)
      }
    } catch (error) {
      setErrorMessage("Error sending password reset email")
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    resetHandler(e).catch(console.error)
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="Logo" className="w-48 mb-6" />
        {errorMessage !== "" && (
          <Alert severity="error" sx={{ width: "100%", mb: 2 }}>
            {errorMessage}
          </Alert>
        )}
        {showResetRequest && (
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <PasswordResetRequest
              loading={loading}
              email={email}
              setEmail={setEmail}
              emailError={emailError}
            />
            <Box sx={{ textAlign: "center", width: "100%" }}>
              <Typography
                sx={{
                  marginBottom: 2,
                }}
                variant="body2"
              >
                Go back to{" "}
                <Link variant="body2" fontWeight={700} underline="hover" href="/login">
                  Login
                </Link>{" "}
                page
              </Typography>
            </Box>
          </Box>
        )}
        {showResetRequestSent && (
          <PasswordResetSent setShowResetRequestSent={setShowResetRequestSent} email={email} />
        )}
      </Box>
    </Container>
  )
}
