import { Button, IconButton, Tooltip } from "@mui/material"
import ShareIcon from "@mui/icons-material/Share"
interface ShareProps {
  shareText: string
  title?: string
  fontSize?: string
  padding?: string
  button?: boolean
}
export function ShareButton({ shareText, title, fontSize = "16px", padding, button }: ShareProps) {
  const handleSharing = () => {
    navigator
      .share({ title, text: shareText })
      .then(() => {
        console.log("Content shared successfully")
      })
      .catch((error) => {
        console.error("Error sharing content:", error)
      })
  }

  return (
    <>
      {button !== true || button === undefined ? (
        <Tooltip placement="right" title="Share">
          <IconButton sx={{ padding }} onClick={handleSharing}>
            <ShareIcon sx={{ fontSize, color: "black" }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          onClick={handleSharing}
          variant="outlined"
          color="primary"
          className="w-full max-w-[120px]"
        >
          Share
        </Button>
      )}
    </>
  )
}
