import React from "react"
import { Box, Typography } from "@mui/material"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"

interface PasswordsMatchIndicatorProps {
  match: boolean
}

export const PasswordsMatchIndicator: React.FC<PasswordsMatchIndicatorProps> = ({ match }) => {
  const Icon = match ? CheckCircleOutlineIcon : CancelOutlinedIcon
  const color = match ? "success.main" : "text.disabled"

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Icon fontSize="small" color={match ? "success" : "disabled"} />
      <Typography variant="body2" color={color} sx={{ ml: 0.5 }}>
        Match
      </Typography>
    </Box>
  )
}
