export function formatDate(
  dateString: string,
  separateTime: true
): { formattedDate: string; formattedTime: string }
export function formatDate(dateString: string, separateTime?: false): string
export function formatDate(
  dateString: string,
  separateTime = false
): string | { formattedDate: string; formattedTime: string } {
  if (dateString == null || dateString.trim() === "") {
    console.error("No date string provided")
    return ""
  }

  // Normalize date string formats to enhance Date constructor compatibility
  const normalizedDateString = String(
    dateString.replace(
      /(\d{1,2})\/(\d{1,2})\/(\d{4})\s(\d{1,2}):(\d{2}):(\d{2})\s(AM|PM)/,
      "$1/$2/$3 $4:$5:$6 $7"
    )
  )

  const date = new Date(normalizedDateString)

  if (isNaN(date.getTime())) {
    console.error("Invalid date format")
    return ""
  }

  // Use Intl.DateTimeFormat for formatting parts separately
  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  })
  const timeFormatter = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  })

  // Combine date and time parts with a single space separator
  const formattedDate = String(dateFormatter.format(date))
  const formattedTime = String(timeFormatter.format(date).replace(/^(\d{2}):(\d{2})$/, "$1:$2"))

  if (separateTime) {
    return { formattedDate, formattedTime }
  } else {
    return `${formattedDate} ${formattedTime}`
  }
}
