import "./styles/index.css"
import ReactDOM from "react-dom/client"
import { App } from "./App"
import { BrowserRouter } from "react-router-dom"
import { store } from "./redux/store"
import { Provider } from "react-redux"
import { UserContextProvider } from "./context/UserContext"
import "./sentryConfig"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <UserContextProvider>
        <App />
      </UserContextProvider>
    </BrowserRouter>
  </Provider>
)
