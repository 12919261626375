import Typography from "@mui/material/Typography"

interface SalesTypographyStyledProps {
  children: React.ReactNode
  variant?: "subtitle2"
  color?: string
  className?: string
}

export const SalesTypographyStyled = ({
  children,
  variant,
  color,
  className,
}: SalesTypographyStyledProps) => (
  <Typography
    variant={variant}
    color={color}
    className={className}
    sx={{
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      ...(variant === "subtitle2" && {
        fontSize: "0.75rem",
        fontWeight: 500,
      }),
    }}
  >
    {children}
  </Typography>
)
