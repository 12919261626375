export interface FlattenConfig {
  path: string[]
  fields: Record<string, string | ((item: any, parentItem?: any, parentKey?: string) => any)>
}
export function flattenExport(data: any[], config: FlattenConfig[], parentData: any = {}): any[] {
  const results: any[] = []
  data.forEach((item) => {
    processLevel(item, config, results, parentData)
  })
  return results
}

// Main function to flatten data based on a configuration
function processLevel(
  item: any,
  config: FlattenConfig[],
  results: any[],
  parentData: any = {},
  parentKey?: string
) {
  if (config.length === 0) {
    results.push(parentData)
    return
  }

  const currentConfig = config[0]
  let currentData = item

  // Navigate down the path to the desired data level
  for (const path of currentConfig.path) {
    if (currentData !== null && path in currentData) {
      currentData = currentData[path]
    } else {
      // Log a warning instead of an error and continue with processing
      console.warn(`Path '${path}' not found. Skipping this path.`)
      continue // Skip to next path instead of returning
    }
  }

  if (currentData === undefined) {
    console.warn("Data undefined after path resolution, skipping...")
    return
  }

  // Handle both objects and arrays
  if (Array.isArray(currentData)) {
    // Process each item in the array
    currentData.forEach((subItem) => {
      const newData = { ...parentData }
      Object.entries(currentConfig.fields).forEach(([key, value]) => {
        newData[key] =
          typeof value === "function" ? value(subItem, item, parentKey) : subItem[value]
      })
      processLevel(subItem, config.slice(1), results, newData, parentKey)
    })
  } else if (currentData !== null && typeof currentData === "object") {
    // Process a single object
    const newData = { ...parentData }
    Object.entries(currentConfig.fields).forEach(([key, value]) => {
      newData[key] =
        typeof value === "function" ? value(currentData, item, parentKey) : currentData[value]
    })
    processLevel(currentData, config.slice(1), results, newData, parentKey)
  } else {
    console.error(
      "Expected array or object at",
      currentConfig.path.join("."),
      "but got",
      typeof currentData
    )
  }
}
