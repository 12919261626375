import React from "react"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

interface LicensingInfoDialogProps {
  title: string
  content: React.ReactNode
  dialogOpen: boolean
  onClose: () => void
  dontShowAgain: boolean
  handleDontShowAgainChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const LicensingInfoDialog: React.FC<LicensingInfoDialogProps> = ({
  title,
  content,
  dialogOpen,
  onClose,
  dontShowAgain,
  handleDontShowAgainChange,
}) => {
  return (
    <Dialog open={dialogOpen} onClose={onClose}>
      <div className="border-[3px] border-black">
        <DialogTitle sx={{ fontWeight: "700", textAlign: "center" }}>
          {title}
          <hr className="mt-3 border-[1px] border-black rounded-md" />
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: "absolute", right: "0px", top: "16px" }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="text-black text-left">{content}</div>
          <FormControlLabel
            control={<Checkbox checked={dontShowAgain} onChange={handleDontShowAgainChange} />}
            label="Don't show this again"
          />
        </DialogContent>
      </div>
    </Dialog>
  )
}
