import React from "react"
import { Alert } from "@mui/material"
import { AccordionPerformanceItem } from "./AccordionPerformanceItem"
import type { Performance } from "../../../types/performanceTypes"

export function PerformanceReport({ report, hasBusinesses }: Performance) {
  const collapseAllBusinesses = report.length === 1

  if (!hasBusinesses) {
    return (
      <Alert severity="error" sx={{ width: "100%" }}>
        No data to show with the current selection.
      </Alert>
    )
  }

  return (
    <div className="shadow-lg">
      {report.map(({ businessId, businessName, play, win, net, locations }, index) => {
        return (
          <React.Fragment key={index}>
            <AccordionPerformanceItem
              collapseAllBusinesses={collapseAllBusinesses}
              businessId={businessId}
              businessName={businessName}
              play={play}
              win={win}
              net={net}
              locations={locations}
            />
          </React.Fragment>
        )
      })}
    </div>
  )
}
