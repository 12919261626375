import { useEffect } from "react"
import { HomeButton } from "../../components/HomeButton"
import { toast } from "react-hot-toast"
import { useUserContext } from "../../context/UserContext"
import logo from "../../assets/logo.svg"
import { greeting } from "../../utils/greeting"
import { homeButtons } from "../../data/homepage"
import { EncryptStorage } from "encrypt-storage"

export function Home() {
  const data = useUserContext()

  const name = localStorage.getItem("firstName")

  const storage = new EncryptStorage("jfkejfklrjklgjerlk485859809gjtkljkgjl")
  const locations = storage.getItem("locations")
  console.log("locations in home: ", locations)
  const isLocationsEmpty = !!(locations?.length === 0)

  useEffect(() => {
    toast.dismiss()
  }, [])

  return (
    <div className="max-w-xl mx-auto flex flex-col items-center mt-10 px-6">
      <img className="w-48" alt="Company Logo" src={logo} />

      <p className="mb-6 mt-8 font-medium text-lg">
        {greeting()}, {name}
      </p>

      {homeButtons(data.signOutHandler)
        .slice(1)
        .map((value, index) => (
          <HomeButton
            key={index}
            href={value.href}
            onClick={value.onclick}
            icon={<value.Icon {...value.iconProps} />}
            iconProps={value.iconProps}
            isLocationsEmpty={isLocationsEmpty}
          >
            {value.title}
          </HomeButton>
        ))}
    </div>
  )
}
