import React from "react"
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material"
import { ListItem } from "@mui/material"

interface PasswordCriteriaListProps {
  criteria: string
  isRemoving: boolean
}

export const PasswordCriteriaList: React.FC<PasswordCriteriaListProps> = ({
  criteria,
  isRemoving,
}) => {
  return (
    <ListItem
      dense
      sx={{
        pl: 0,
        color: isRemoving ? "success.main" : "text.secondary",
        display: "flex",
        alignItems: "flex-start",
        fontSize: "0.875rem",
        opacity: isRemoving ? 0 : 1,
        transform: isRemoving ? "translateX(-20px)" : "translateX(0)",
        transition: "opacity 0.5s ease, transform 0.5s ease, color 0.5s ease",
      }}
    >
      {isRemoving ? (
        <CheckCircleOutline sx={{ mr: 0.5, color: "success.main" }} />
      ) : (
        <CancelOutlined sx={{ mr: 0.5 }} />
      )}

      {criteria}
    </ListItem>
  )
}
