import { type FC, useState, type ChangeEvent, memo, useEffect } from "react"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"

interface RememberMeCheckboxProps {
  setRememberMe: (rememberMe: boolean) => void
}

const RememberMeCheckbox: FC<RememberMeCheckboxProps> = ({ setRememberMe }) => {
  const [localRememberMe, setLocalRememberMe] = useState(false)
  const storedRememberMe = localStorage.getItem("rememberMe")
  const rememberMeExists: boolean =
    storedRememberMe != null ? JSON.parse(storedRememberMe) === true : false
  useEffect(() => {
    if (rememberMeExists) {
      setLocalRememberMe(true)
      setRememberMe(true)
    }
  }, [setRememberMe])
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalRememberMe(e.target.checked)
    setRememberMe(e.target.checked)
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          value="remember"
          color="primary"
          checked={localRememberMe}
          onChange={handleChange}
        />
      }
      label="Remember me"
    />
  )
}

export default memo(RememberMeCheckbox)
