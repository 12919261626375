import {
  IconButton,
  // ListSubheader,
  Menu,
  MenuItem,
} from "@mui/material"
import React, { useState } from "react"
import * as XLSX from "xlsx"
import DownloadIcon from "@mui/icons-material/Download"
import ShareIcon from "@mui/icons-material/Share"
import dayjs from "dayjs"
import { safeGetFromLocalStorage } from "../utils/localStorageUtils"
// import { formatDate } from "../utils/formatDate"

interface ExportProps {
  exportFlattenedSummaryData: any[] | undefined
  exportFlattenedDetailedData: any[] | undefined
  disabled: boolean
  reportName: string
  headerDateRangeFrom: string
  headerDateRangeTo: string
}

export const Export: React.FC<ExportProps> = ({
  reportName,
  headerDateRangeFrom,
  headerDateRangeTo,
  exportFlattenedDetailedData,
  exportFlattenedSummaryData,
  disabled,
}) => {
  const [downloadAnchorEl, setDownloadAnchorEl] = useState<null | HTMLElement>(null)
  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null)
  const openDownloadMenu = Boolean(downloadAnchorEl)
  const openShareMenu = Boolean(shareAnchorEl)
  const currentDateTime = {
    fileNameSafe: dayjs().format("YYYY-MM-DD HH-mm-ss"),
    presentation: dayjs().format("YYYY-MM-DD HH:mm:ss"),
  }

  const handleClickDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (
      exportFlattenedDetailedData !== null &&
      exportFlattenedDetailedData !== undefined &&
      exportFlattenedDetailedData.length > 0 &&
      exportFlattenedSummaryData !== null &&
      exportFlattenedSummaryData !== undefined &&
      exportFlattenedSummaryData.length > 0
    ) {
      setDownloadAnchorEl(event.currentTarget)
    }
  }

  const handleClickShare = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (
      exportFlattenedDetailedData !== null &&
      exportFlattenedDetailedData !== undefined &&
      exportFlattenedDetailedData.length > 0 &&
      exportFlattenedSummaryData !== null &&
      exportFlattenedSummaryData !== undefined &&
      exportFlattenedSummaryData.length > 0
    ) {
      setShareAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setDownloadAnchorEl(null)
    setShareAnchorEl(null)
  }

  const customHeaders = (reportType: string, reportName: string) => {
    const firstName = safeGetFromLocalStorage("firstName")
    const lastName = safeGetFromLocalStorage("lastName")
    let userName = ""

    if (firstName != null || lastName != null) {
      userName = `${firstName ?? ""} ${lastName ?? ""}`.trim()
    }

    const baseHeaders = [
      ["Report Name", `${reportName} Report (${reportType})`],
      ["Generated On", currentDateTime.presentation],
      ["Generated By", userName],
    ]

    const dateRangeHeaders =
      reportName !== "Period" && reportName !== "Current Cash"
        ? [
            ...(headerDateRangeFrom !== "" ? [["From", headerDateRangeFrom]] : []),
            ...(headerDateRangeTo !== "" ? [["To", headerDateRangeTo]] : []),
          ]
        : []

    return [...baseHeaders, ...dateRangeHeaders]
  }

  const handleExcelExport = (data: any, reportType: string, reportName: string) => {
    console.log("reportName:", reportName)
    if (data !== null && data !== undefined && data.length > 0) {
      handleClose()
      const headerData = customHeaders(reportType, reportName)
      const ws = XLSX.utils.json_to_sheet([], { skipHeader: true })
      XLSX.utils.sheet_add_aoa(ws, headerData, { origin: "A1" })
      XLSX.utils.sheet_add_json(ws, data, { origin: +2 + headerData.length })
      ws["!cols"] =
        data[0] !== null && data[0] !== undefined
          ? Object.keys(data[0]).map(() => ({ wch: 20 }))
          : []
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, `${reportName} (${reportType})`)
      XLSX.writeFile(wb, `${reportName}_${reportType}_${currentDateTime.fileNameSafe}.xlsx`)
    }
  }

  const handleCSVExport = (data: any[], reportType: string, reportName: string) => {
    if (data !== null && data !== undefined && data.length > 0) {
      handleClose()
      const headerData = customHeaders(reportType, reportName)
      const ws = XLSX.utils.json_to_sheet([], { skipHeader: true })
      XLSX.utils.sheet_add_aoa(ws, headerData, { origin: "A1" })
      XLSX.utils.sheet_add_json(ws, data, { origin: +2 + headerData.length })
      let csvString = XLSX.utils.sheet_to_csv(ws)

      // Adding BOM to the CSV string to ensure UTF-8 encoding
      const bom = "\uFEFF"
      csvString = bom + csvString

      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" })
      const link = document.createElement("a")
      const url = URL.createObjectURL(blob)
      link.href = url
      link.download = `${reportName}_${reportType}_${currentDateTime.fileNameSafe}.csv`
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const handleShareCSV = async (data: any[], reportType: string, reportName: string) => {
    if (data !== null && data !== undefined && data.length > 0) {
      handleClose()
      const headerData = customHeaders(reportType, reportName)
      console.log("header share:", headerData)
      const ws = XLSX.utils.json_to_sheet([], { skipHeader: true })
      XLSX.utils.sheet_add_aoa(ws, headerData, { origin: "A1" })
      XLSX.utils.sheet_add_json(ws, data, { origin: +2 + headerData.length })

      // Adding BOM to the CSV string to ensure UTF-8 encoding
      const csvString = "\uFEFF" + XLSX.utils.sheet_to_csv(ws)

      const file = new File(
        [csvString],
        `${reportName}_${reportType}_${currentDateTime.fileNameSafe}.csv`,
        {
          type: "text/csv",
        }
      )

      // Check if file is empty
      if (file.size === 0) {
        console.error("Generated CSV file is empty.")
        return null
      }

      return file
    }
    return null
  }

  const shareFile = async (file: File | null) => {
    if (file != null) {
      try {
        await navigator.share({ files: [file] })
        console.log("CSV shared successfully")
      } catch (error) {
        console.error("Error sharing the file", error)
      }
    }
  }

  return (
    <div>
      <IconButton onClick={handleClickDownload} disabled={disabled}>
        <DownloadIcon fontSize="small" sx={{ color: disabled ? "#c0c0c0" : "white" }} />
      </IconButton>
      <IconButton onClick={handleClickShare} disabled={disabled}>
        <ShareIcon fontSize="small" sx={{ color: disabled ? "#c0c0c0" : "white" }} />
      </IconButton>
      {exportFlattenedDetailedData !== null &&
        exportFlattenedDetailedData !== undefined &&
        exportFlattenedDetailedData.length > 0 &&
        exportFlattenedSummaryData !== null &&
        exportFlattenedSummaryData !== undefined &&
        exportFlattenedSummaryData.length > 0 &&
        exportFlattenedDetailedData?.[0] != null &&
        exportFlattenedSummaryData?.[0] != null && (
          <>
            <Menu anchorEl={downloadAnchorEl} open={openDownloadMenu} onClose={handleClose}>
              <MenuItem
                onClick={() => {
                  handleCSVExport(exportFlattenedDetailedData, "detailed", reportName)
                }}
              >
                Export CSV (detailed)
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCSVExport(exportFlattenedSummaryData, "summary", reportName)
                }}
              >
                Export CSV (summary)
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleExcelExport(exportFlattenedDetailedData, "detailed", reportName)
                }}
              >
                Export Excel (detailed)
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleExcelExport(exportFlattenedSummaryData, "summary", reportName)
                }}
              >
                Export Excel (summary)
              </MenuItem>
            </Menu>

            <Menu anchorEl={shareAnchorEl} open={openShareMenu} onClose={handleClose}>
              <MenuItem
                onClick={() => {
                  void (async () => {
                    const file = await handleShareCSV(
                      exportFlattenedDetailedData,
                      "detailed",
                      reportName
                    )
                    if (file != null && file.size !== 0) {
                      void shareFile(file)
                    }
                  })()
                }}
              >
                Share CSV (detailed)
              </MenuItem>
              <MenuItem
                onClick={() => {
                  void (async () => {
                    const file = await handleShareCSV(
                      exportFlattenedSummaryData,
                      "summary",
                      reportName
                    )
                    if (file != null && file.size !== 0) {
                      void shareFile(file)
                    }
                  })()
                }}
              >
                Share CSV (summary)
              </MenuItem>
            </Menu>
          </>
        )}
    </div>
  )
}
