import { Button, IconButton, Tooltip } from "@mui/material"
import CopyAllIcon from "@mui/icons-material/CopyAll"
import copy from "copy-to-clipboard"
import toast from "react-hot-toast"

interface Props {
  copyText: string
  fontSize?: string
  button?: boolean
  onCopy?: () => void
}

export function CopyButton({ copyText, button, fontSize = "16px", onCopy }: Props) {
  const handleCopyClick = (fillCode: string) => () => {
    copy(fillCode)
    toast.dismiss()
    toast.success("Copied!")
    if (onCopy != null) {
      onCopy()
    }
  }

  return (
    <>
      {button !== true || button === undefined ? (
        <Tooltip placement="left" title="Copy Fill Code">
          <IconButton
            sx={{
              display: "flex",
              alignItems: "baseline",
              padding: 0,
            }}
            onClick={handleCopyClick(copyText)}
          >
            <CopyAllIcon sx={{ fontSize, color: "black" }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          onClick={handleCopyClick(copyText)}
          variant="contained"
          color="primary"
          className="w-full "
        >
          Copy
        </Button>
      )}
    </>
  )
}
