import React, { useState, memo } from "react"
import {
  Accordion,
  AccordionDetails,
  SummarySection,
  SummaryItem,
  AccordionSummary,
} from "../../../styles/AccordionStyles"
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded"
import { RevenueTable } from "./RevenueTable"
import { TablePaginationItem } from "../../TablePaginationItem"
import { useLocation } from "react-router-dom"
import { Truncate } from "../../Truncate"
import { getNetColor } from "../../../utils/getNetColor"

interface AccordionItemProps {
  collapseAllBusinesses: boolean
  businessId: string
  name: string
  fullSales: string
  shortSales: string
  fullRedemption: string
  shortRedemption: string
  fullNet: string
  shortNet: string
  stores: any
  totalSummary: any
}

const AccordionItemComponent = ({
  collapseAllBusinesses,
  businessId,
  name,
  fullSales,
  shortSales,
  fullRedemption,
  shortRedemption,
  fullNet,
  shortNet,
  stores,
  totalSummary,
}: AccordionItemProps) => {
  const [rowsPerPage, setRowsPerPage] = useState(5)

  // Get the selected location ID from the URL
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const selectedLocationId = urlParams.get("locationId")

  const getPageIndexOfSelectedStore = (selectedStoreId: string | null) => {
    if (selectedStoreId === null || selectedStoreId === "") return 0

    const selectedIndex = stores.findIndex(
      (store: any) =>
        store?.store?.id !== undefined && store?.store?.id?.toString() === selectedStoreId
    )

    return selectedIndex >= 0 ? Math.floor(selectedIndex / rowsPerPage) : 0
  }

  const [page, setPage] = useState(getPageIndexOfSelectedStore(selectedLocationId))

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  // const [expandedBusiness, setExpandedBusiness] = useState<string | false>(false)

  const [expandedBusiness, setExpandedBusiness] = useState<string | false>(
    collapseAllBusinesses ? businessId : false
  )

  const handleBusinessChange =
    (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpandedBusiness(isExpanded ? panel : false)
    }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const visibleStores = stores.slice(page * rowsPerPage, (page + 1) * rowsPerPage)

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - stores.length) : 0

  return (
    <Accordion
      expanded={expandedBusiness === businessId}
      onChange={handleBusinessChange(businessId)}
    >
      <AccordionSummary
        sx={{
          backgroundColor: "#DEE2E6",
        }}
        expandIcon={<NavigateNextRoundedIcon />}
      >
        <SummarySection sx={{ flexBasis: "100%" }}>
          <SummaryItem
            sx={{
              textAlign: "left",
              minWidth: "68px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              flexBasis: "25%",
            }}
          >
            <Truncate input={name} />
          </SummaryItem>
          <SummaryItem
            sx={{
              textAlign: "right",
              minWidth: "68px",
              flexBasis: "25%",
            }}
          >
            <span className="hidden sm:block">{fullSales}</span>
            <span className="block sm:hidden">{shortSales}</span>
          </SummaryItem>
          <SummaryItem
            sx={{
              textAlign: "right",
              minWidth: "68px",
              flexBasis: "25%",
            }}
          >
            <span className="hidden sm:block">{fullRedemption}</span>
            <span className="block sm:hidden">{shortRedemption}</span>
          </SummaryItem>
          <SummaryItem
            sx={{
              textAlign: "right",
              minWidth: "68px",
              flexBasis: "25%",
              color: getNetColor(totalSummary.n),
            }}
          >
            <span className="hidden sm:block">{fullNet}</span>
            <span className="block sm:hidden">{shortNet}</span>
          </SummaryItem>
        </SummarySection>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionDetails>
          {/* Table of transactions */}
          <RevenueTable stores={visibleStores} name={name} emptyRows={emptyRows} />

          <TablePaginationItem
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={stores.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </AccordionDetails>
      </AccordionDetails>
    </Accordion>
  )
}

export const AccordionRevenueItem = memo(AccordionItemComponent)
