import { Visibility, VisibilityOff } from "@mui/icons-material"
import { IconButton, InputAdornment, TextField } from "@mui/material"

interface Props {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  label: string
  helperText?: string | null
  error?: boolean
  required?: boolean
}

export function PasswordTextField(props: Props) {
  return (
    <TextField
      required={props.required}
      type={props.show ? "text" : "password"}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      helperText={props.helperText}
      error={props.error}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                props.setShow(!props.show)
              }}
            >
              {props.show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
