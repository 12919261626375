import { Tooltip } from "@mui/material"
import { ReactComponent as PurchaseIcon } from "../../assets/licensing/purchase_time.svg"
import { getPaymentMethodColor } from "../../utils/getPaymentMethodColor"

interface PurchaseTimeIconProps {
  purchaseMethod?: string
}

export const PurchaseTimeIcon = ({ purchaseMethod }: PurchaseTimeIconProps) => {
  const color =
    purchaseMethod !== null && purchaseMethod !== undefined
      ? getPaymentMethodColor(purchaseMethod.toLowerCase()).color
      : "black"

  return (
    <Tooltip placement="left" title="Purchase Date">
      <PurchaseIcon
        style={{
          color,
        }}
        className="w-5 h-4 mr-1"
      />
    </Tooltip>
  )
}
