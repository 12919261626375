import React, { useState } from "react"
import DateRangeIcon from "@mui/icons-material/DateRange"
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined"
import { DatePicker, Button, ConfigProvider } from "antd"
import en from "antd/es/locale/en_GB"
import { Typography } from "@mui/material"
import dayjs, { type Dayjs } from "dayjs"
import "./DateRangePicker.css"
import "./DatePickerWeek.css"
import "dayjs/locale/en-gb"
dayjs.locale("en-gb")
const { RangePicker } = DatePicker

interface Props {
  value: [Dayjs, Dayjs] | null
  onChange: (dates: Array<Dayjs | null> | null, dateStrings: string[]) => void
  disabled?: boolean
}

interface CustomFooterProps {
  onClick: (value: [Dayjs, Dayjs]) => void
  closePicker: () => void
  togglePickerType: () => void
  pickerType: "date" | "week"
}

const getRangePresets = (): Array<{
  label: string
  value: [Dayjs, Dayjs]
}> => [
  { label: "Today", value: [dayjs().startOf("day"), dayjs().endOf("day")] },
  {
    label: "Yesterday",
    value: [dayjs().subtract(1, "day").startOf("day"), dayjs().subtract(1, "day").endOf("day")],
  },
  { label: "This Week", value: [dayjs().startOf("week"), dayjs().endOf("day")] },
  {
    label: "Last Week",
    value: [dayjs().startOf("week").subtract(1, "week"), dayjs().endOf("week").subtract(1, "week")],
  },
  { label: "This Month", value: [dayjs().startOf("month"), dayjs()] },
  {
    label: "Last Month",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
  { label: "This Year", value: [dayjs().startOf("year"), dayjs()] },
]

const CustomFooter: React.FC<CustomFooterProps> = ({
  onClick,
  closePicker,
  togglePickerType,
  pickerType,
}) => {
  const handlePresetClick = (label: string) => {
    const presets = getRangePresets()
    const preset = presets.find((p) => p.label === label)
    if (preset != null) {
      onClick(preset.value)
      closePicker()
    }
  }

  return (
    <div className="presets">
      {getRangePresets().map(({ label }) => (
        <Button
          key={label}
          onClick={() => {
            handlePresetClick(label)
          }}
        >
          {label}
        </Button>
      ))}
      <Button className="switch-range-btn" onClick={togglePickerType}>
        {pickerType === "week" ? "Select Custom Range" : "Select Week"}
      </Button>
      <Typography variant="caption" sx={{ color: "#000000", opacity: 0.6, fontSize: "0.65rem" }}>
        Transactions before 4:00am belong to previous day.
      </Typography>
    </div>
  )
}

const isFuture = (date: Dayjs) => {
  return date.isAfter(dayjs().endOf("day"))
}

export function DateRangePicker({ value, onChange, disabled = false }: Props) {
  const [selectedRange, setSelectedRange] = useState<[Dayjs, Dayjs] | null>(value)
  const [isOpen, setIsOpen] = useState(false)
  const [pickerType, setPickerType] = useState<"date" | "week">("date") // state for picker type

  const handleRangeChange = (dates: Array<Dayjs | null> | null, dateStrings: string[]) => {
    setSelectedRange(dates as [Dayjs, Dayjs] | null)
    onChange(dates as [Dayjs, Dayjs] | null, dateStrings)
  }

  const togglePickerType = () => {
    const newPickerType = pickerType === "date" ? "week" : "date"
    setPickerType(newPickerType)
  }

  return (
    <ConfigProvider locale={en}>
      {pickerType === "week" ? (
        <DatePicker
          placement="bottomRight"
          suffixIcon={
            <DateRangeIcon
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            />
          }
          picker="week"
          value={selectedRange?.[0]}
          className="week-picker"
          size="large"
          inputReadOnly
          allowClear={false}
          onChange={(date): void => {
            const weekStart = date != null ? date.startOf("week") : null // Ensure weekStart is null if date is undefined
            const weekEnd = date.endOf("week").isAfter(dayjs().endOf("day"))
              ? dayjs().endOf("day")
              : date.endOf("week") // Ensure weekEnd is null if weekStart is undefined
            handleRangeChange(
              [weekStart, weekEnd],
              [
                weekStart != null ? weekStart.format("YYYY-MM-DD") : "",
                weekEnd != null ? weekEnd.format("YYYY-MM-DD") : "",
              ]
            )
          }}
          disabledDate={isFuture}
          disabled={disabled}
          open={isOpen}
          onOpenChange={setIsOpen}
          renderExtraFooter={() => (
            <CustomFooter
              onClick={(value) => {
                console.log("value in extra foooter in week picker:", value)
                handleRangeChange(
                  value,
                  value.map((d) => d.format("YYYY-MM-DD"))
                )
                setPickerType("date")
              }}
              closePicker={() => {
                setIsOpen(!isOpen)
              }}
              togglePickerType={togglePickerType}
              pickerType={pickerType}
            />
          )}
        />
      ) : (
        <RangePicker
          placement="bottomRight"
          suffixIcon={
            <DateRangeIcon
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            />
          }
          separator={<ArrowRightAltOutlinedIcon />}
          className="range-picker"
          size="large"
          inputReadOnly
          open={isOpen}
          allowClear={false}
          value={selectedRange}
          onChange={handleRangeChange}
          onOpenChange={setIsOpen}
          disabledDate={isFuture}
          disabled={disabled}
          picker={pickerType}
          renderExtraFooter={() => (
            <CustomFooter
              onClick={(value) => {
                handleRangeChange(
                  value,
                  value.map((d) => d.format("YYYY-MM-DD"))
                )
              }}
              closePicker={() => {
                setIsOpen(false)
              }}
              togglePickerType={togglePickerType}
              pickerType={pickerType}
            />
          )}
        />
      )}
    </ConfigProvider>
  )
}
