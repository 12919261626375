import Typography from "@mui/material/Typography"

interface SelectableTypographyProps {
  selected: boolean
  children: React.ReactNode
  className?: string
}

export const SelectableTypography = ({
  selected,
  children,
  className,
}: SelectableTypographyProps) => (
  <Typography
    className={className}
    sx={{
      fontWeight: selected ? "bold" : "normal",
      fontSize: { xs: "0.8rem", sm: "1rem" },
    }}
  >
    {children}
  </Typography>
)
