import {
  type BaseQueryFn,
  type FetchArgs,
  type FetchBaseQueryError,
  type FetchBaseQueryMeta,
  type MutationDefinition,
} from "@reduxjs/toolkit/dist/query"
import { type MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks"
import { toast } from "react-hot-toast"
import { type HandleSnackBarType } from "../types/reportTypes"
import { showDelayedLoadingToast } from "./showDelayedLoadingToast"

type FetchReportType = MutationTrigger<
  MutationDefinition<
    any,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    never,
    any,
    "businessApi"
  >
>

export const getReportsHandler = async (
  fetchReport: FetchReportType,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  handleSnackBar: HandleSnackBarType,
  setReport: any,
  location: string | null,
  hasHeaderDate: boolean,
  requiresAPICall: boolean,
  setShowHeaderDate: React.Dispatch<React.SetStateAction<boolean>>,
  reportTitle: string,
  options?: any,
  isMountedRef?: React.MutableRefObject<boolean>
) => {
  setLoading(true)
  setShowHeaderDate(false)

  // to show the message if report takes longer to show
  const toastId = showDelayedLoadingToast(3000, isMountedRef)
  console.log("toastId: ", toastId)

  try {
    if (requiresAPICall) {
      const res: any = await fetchReport({
        ...options,
      })

      console.log("fetchReport from getReportsHandler", reportTitle)
      if (res.error !== undefined) {
        console.log("res.error:", res.error)
        console.log(res.error)
        return
      }

      // helper variable for API drilling
      const reportData = reportTitle
        .split(".")
        .reduce((accumulator, currentValue) => accumulator[currentValue], res)

      if (reportData?.length === 0) {
        handleSnackBar(true, "error", "No data to show with the current selection.")
        setReport([]) // Clear the report data state
      } else {
        console.log("reportHandler GetReport", reportData)
        setReport(reportData)
      }
    } else {
      // do the filtering here
    }
  } catch (error) {
    console.log(error)
  } finally {
    if (hasHeaderDate) {
      setShowHeaderDate(true)
    }
    setLoading(false) // Set the isFetchingReport state variable to false
    clearTimeout(toastId) // Clear the scheduled toast
    toast.dismiss("loadingToast") // Dismiss the toast if it's showing
  }
}
