import { type Location } from "react-router-dom"

export const isPasswordResetPage = (location: Location) => {
  console.log("location sent:", location)
  const params = new URLSearchParams(location.search)
  const token = params.get("token")

  if (location.pathname.startsWith("/recover")) {
    return true
  }
  if (location.pathname.startsWith("/reset") && token != null) {
    return true
  } else return false
}
