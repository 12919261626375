import { Button, CircularProgress } from "@mui/material"
import customStyles from "../styles/tokens.json"

interface PrimaryButtonProps {
  primaryActionText: string
  disabledPrimaryAction?: boolean
  isLoading?: boolean
  onPrimaryAction?: () => void
}

export const CustomDialogPrimaryButton = ({
  primaryActionText,
  onPrimaryAction,
  disabledPrimaryAction,
  isLoading = false,
}: PrimaryButtonProps) => {
  return (
    <Button
      sx={{
        fontSize: "min(16px, calc(10px + 0.8vmin))",
        width: "100%",
        bgcolor: customStyles.components.buttons.primary.color,
        ":hover": { bgcolor: customStyles.components.buttons.primary.hoverColor },
      }}
      variant="contained"
      onClick={onPrimaryAction}
      disabled={disabledPrimaryAction}
    >
      {isLoading !== null && isLoading !== undefined && isLoading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
      {primaryActionText}
    </Button>
  )
}
