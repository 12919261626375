export interface Store {
  isComplete: boolean
  lastUpdate: string
  store: StoreDetails
  summary: Summary
  id: string
  name: string
}

export interface StoreDetails {
  address: string
  alerts: any[]
  businessId: string
  id: string
  isSweeps: boolean
  lastUpdate: string
  productType: string
  locationStatus: string
  name: string
  online: boolean
  pfh: boolean
  platform: string
  sofwareName: string
  statusMsg: string
  terminalCount: number
  terminals: any[]
}

interface Summary {
  s: number
  r: number
  n: number
}

export interface OutputJson {
  stores: Record<string, { name: string; stores: Store[]; totalSummary: Summary }>
  totalSummary: Summary
}

export interface Business {
  revenueReport: Store[]
  totalSummary: Summary
  businessRevenueReport: BusinessRevenueReport
}

interface BusinessRevenueReport {
  businessInfo: BusinessInfo
}

interface BusinessInfo {
  businessId: string
  name: string
  city: string
  country: string
  locality: string
  phone: string
  secondaryPhone: string
  street: string
  zipPostal: string
}

export interface InputJson {
  revenueData: Business
}

export function useFlattenRevenue(input: InputJson): OutputJson {
  const revenueData = input.revenueData.businessRevenueReport
  const groupedRevenueReport: Record<
    string,
    { name: string; stores: Store[]; totalSummary: Summary }
  > = {}

  // Check if revenueData is an array before iterating
  if (!Array.isArray(revenueData)) {
    console.error("revenueData is not an array:", revenueData)
    return {
      stores: groupedRevenueReport,
      totalSummary: input.revenueData.totalSummary,
    }
  }

  for (const businessRevenue of revenueData) {
    const { business, revenueReport, totalSummary } = businessRevenue

    // Check if business, revenueReport, and totalSummary are defined
    if (business === undefined || revenueReport === undefined || totalSummary === undefined) {
      console.error("Missing required properties in businessRevenue:", businessRevenue)
      continue
    }

    const { businessId, name, city, country, locality, phone, secondaryPhone, street, zipPostal } =
      business.businessInfo

    // Check if businessId and name are defined
    if (businessId === undefined || name === undefined) {
      console.error("Missing required properties in businessInfo:", business.businessInfo)
      continue
    }

    // Initialize groupedRevenueReport[businessId] if it hasn't been initialized yet
    if (groupedRevenueReport[businessId] === undefined) {
      groupedRevenueReport[businessId] = {
        name,
        stores: [],
        totalSummary: { s: 0, r: 0, n: 0 },
      }
    }

    // Check if revenueReport is an array before iterating
    if (!Array.isArray(revenueReport)) {
      console.error("revenueReport is not an array:", revenueReport)
      continue
    }

    for (const storeRevenue of revenueReport) {
      const { s, r, n } = storeRevenue.summary
      const enhancedStore = {
        ...storeRevenue,
        city,
        country,
        locality,
        phone,
        secondaryPhone,
        street,
        zipPostal,
      }

      groupedRevenueReport[businessId].stores.push(enhancedStore)
      groupedRevenueReport[businessId].totalSummary.s += Number(s)
      groupedRevenueReport[businessId].totalSummary.r += Number(r)
      groupedRevenueReport[businessId].totalSummary.n += Number(n)
    }
  }

  const sortedGroupedRevenueReport: Record<
    string,
    { name: string; stores: Store[]; totalSummary: Summary }
  > = {}

  Object.entries(groupedRevenueReport)
    .sort((a, b) => a[1].name.localeCompare(b[1].name, undefined, { numeric: true }))
    .forEach(([key, value]) => {
      sortedGroupedRevenueReport[key] = value
    })

  const outputJson: OutputJson = {
    stores: sortedGroupedRevenueReport,
    totalSummary: input.revenueData.totalSummary,
  }

  console.log("outputJson:", outputJson)
  return outputJson
}
