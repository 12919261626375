import ReactGA from "react-ga"

export const initAnalytics = () => {
  ReactGA.initialize(process.env.REACT_APP_GTAG_ID ?? "")
}

export const trackPageView = (path: string) => {
  ReactGA.pageview(path)
}

export const trackEvent = (category: string, action: string, label: string) => {
  ReactGA.event({ category, action, label })
}
