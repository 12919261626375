import { useState } from "react"
import { useLocation } from "react-router-dom"
import { Box, Container, CssBaseline, Collapse, CircularProgress } from "@mui/material"
import { useResetPasswordMutation, useValidateResetTokenQuery } from "../../redux/user/user.api"
import { SuccessfulPasswordUpdate } from "../../components/ResetPassword/PasswordUpdateSuccessful"
import { PasswordForm } from "../../components/ResetPassword/PasswordForm"
import { ErrorPasswordUpdate } from "../../components/ResetPassword/PasswordUpdateError"
import logo from "../../assets/logo.svg"

export const ResetPassword = () => {
  const [formSuccessful, setFormSuccessful] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const location = useLocation()
  const [resetPassword] = useResetPasswordMutation()

  const params = new URLSearchParams(location.search)
  const token = params.get("token")
  const {
    data: isValidToken,
    isError,
    isLoading,
  } = useValidateResetTokenQuery(token as string, {
    skip: token == null,
  })

  const showErrorPasswordUpdate = isError || isValidToken === true
  const updatePasswordHandler = async (newPassword: string, confirmPassword: string) => {
    setError(null)

    if (token == null) {
      return
    }

    if (newPassword === "" || confirmPassword === "") {
      return
    }

    try {
      const res: any = await resetPassword({
        body: { newPassword, confirmPassword },
        token,
      })
      if (res.data.rc === 1200) {
        setError("Passwords do not match")
      } else if (res.data.rc === 1201) {
        setError("The new password doesn't meet the password criteria")
      } else if (res.data.rc === 1000) {
        setError(null)
        setFormSuccessful(true)
      }
    } catch (error) {
      setError("Error updating password")
    }
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress color="primary" size={50} />
      </div>
    )
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="Logo" className="w-48 mb-4" />
        <Collapse in={!formSuccessful}>
          {showErrorPasswordUpdate ? (
            <ErrorPasswordUpdate />
          ) : (
            <PasswordForm error={error} onSubmit={updatePasswordHandler} />
          )}
        </Collapse>
        <Collapse in={formSuccessful}>
          <SuccessfulPasswordUpdate />
        </Collapse>
      </Box>
    </Container>
  )
}
