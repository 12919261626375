import React, { useState, useCallback, useEffect } from "react"
import useUserActivity from "../hooks/useUserActivity"
import InactiveSessionDialog from "./InactiveSessionDialog"
import { useUserContext } from "../context/UserContext"
import { decodeJwtTtl } from "../utils/decodeJwtTtl"
import { useDispatch } from "react-redux"
import { showInactivityToast } from "../redux/toastSlice"
const inactivityBuffer = 600
const countDown = 59

export const ActivityMonitoring: React.FC = () => {
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [timeLeft, setTimeLeft] = useState(countDown)
  const { signOutHandler, refreshTokenHandler } = useUserContext()
  const dispatch = useDispatch()
  const token = localStorage.getItem("token") ?? ""
  const ttl = decodeJwtTtl(token)

  const handleInactivity = useCallback(() => {
    setTimeLeft(countDown)
    setDialogOpen(true)
  }, [])

  useUserActivity(inactivityBuffer * 1000, handleInactivity)

  useEffect(() => {
    if (!isDialogOpen) return
    const countdownInterval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(countdownInterval)
          setDialogOpen(false)
          signOutHandler()
          dispatch(showInactivityToast("You have been signed out due to inactivity."))
          return 0
        }
        return prevTime - 1
      })
    }, 1000)

    return () => {
      clearInterval(countdownInterval)
    }
  }, [isDialogOpen, signOutHandler])

  const handlePrimaryAction = async () => {
    if (ttl !== null && ttl > 0) {
      if (ttl <= inactivityBuffer) {
        await refreshTokenHandler()
      }
      setDialogOpen(false)
    }
  }

  return (
    <InactiveSessionDialog
      timeLeft={timeLeft}
      open={isDialogOpen}
      onPrimaryAction={() => {
        handlePrimaryAction().catch((error) => {
          console.error("Error during session renewal:", error)
        })
      }}
      onSecondaryAction={signOutHandler}
    />
  )
}
