import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import { memo, type FC } from "react"

interface LoginButtonProps {
  loading: boolean
}

const LoginButton: FC<LoginButtonProps> = ({ loading }) => {
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      disabled={loading}
      sx={{
        mt: 3,
        mb: 2,
        position: "relative",
      }}
    >
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
      Sign In
    </Button>
  )
}

export default memo(LoginButton)
