import { type ReactNode, memo, useMemo } from "react"
import { Table, TableBody, TableRow, Box, TableContainer } from "@mui/material"
import type { Terminal } from "../../../types/performanceTypes"
import { formatAmount, formatNegative } from "../../../utils/formatAmount"
import { PerformanceBoxStyled } from "../../../styles/PerformanceBoxStyled"
import { PerformanceTypographyStyled } from "../../../styles/PerformanceTypographyStyled"
import { getNetColor } from "../../../utils/getNetColor"
import { formatDate } from "../../../utils/formatDate"

interface Terminals {
  visibleTerminals: Terminal[]
  emptyRows: any
}

interface MemoizedTableContainerProps {
  children: ReactNode
}

const MemoizedTableContainer = memo(({ children }: MemoizedTableContainerProps) => {
  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table component="div" sx={{ tableLayout: "fixed" }} stickyHeader={true} size="small">
        {children}
      </Table>
    </TableContainer>
  )
})

const TerminalsTable = memo((props: Terminals) => {
  const { visibleTerminals, emptyRows } = props
  const terminalRows = useMemo(() => {
    return visibleTerminals.map((terminal: Terminal, index: number) => {
      const { name, play, win, net, lastUpdated } = terminal
      return (
        <TableRow component="div" key={index}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "minmax(24px, auto) 1fr",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Box display="grid" alignItems="center" justifyContent="center"></Box>
            <Box
              sx={{
                paddingLeft: "8px",
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
              }}
            >
              <PerformanceBoxStyled textAlign="left">
                <PerformanceTypographyStyled>{name}</PerformanceTypographyStyled>
                <PerformanceTypographyStyled variant="subtitle2" color="textSecondary">
                  {formatDate(lastUpdated)}
                </PerformanceTypographyStyled>
              </PerformanceBoxStyled>
              <PerformanceBoxStyled>
                <PerformanceTypographyStyled>
                  <span className="hidden md:block">{formatAmount(play).full}</span>
                  <span className="block md:hidden">{formatAmount(play).short}</span>
                </PerformanceTypographyStyled>
              </PerformanceBoxStyled>
              <PerformanceBoxStyled>
                <PerformanceTypographyStyled>
                  <span className="hidden md:block">{formatAmount(win).full}</span>
                  <span className="block md:hidden">{formatAmount(win).short}</span>
                </PerformanceTypographyStyled>
              </PerformanceBoxStyled>
              <PerformanceBoxStyled>
                <PerformanceTypographyStyled color={getNetColor(net)}>
                  <span className="hidden md:block">{formatNegative(net).full}</span>
                  <span className="block md:hidden">{formatNegative(net).short}</span>
                </PerformanceTypographyStyled>
              </PerformanceBoxStyled>
            </Box>
          </Box>
        </TableRow>
      )
    })
    // used to prevent unnecessary re-renders
  }, [visibleTerminals])

  const emptyRowsElements = useMemo(() => {
    return emptyRows > 0
      ? Array.from({ length: emptyRows }, (_, i) => (
          <TableRow component="div" style={{ height: 33 }} key={`emptyRow-${i}`}>
            <Box sx={{ flexBasis: "100%" }} />
          </TableRow>
        ))
      : null
  }, [emptyRows])

  return (
    <Box>
      <MemoizedTableContainer>
        <TableBody component="div">
          {terminalRows}
          {emptyRowsElements}
        </TableBody>
      </MemoizedTableContainer>
    </Box>
  )
})
MemoizedTableContainer.displayName = "MemoizedTableContainer"
TerminalsTable.displayName = "TerminalsTable"
export { TerminalsTable }
