import React, { useCallback, useEffect, useState } from "react"
import { ScannerControls } from "../../../components/Licensing/PurchaseFills/ScannerControls"
import { MachineList } from "../../../components/Licensing/PurchaseFills/MachinelList"
import useFillActions from "../../../hooks/useFillActions"
import useDecodeRequest from "../../../hooks/useDecodeRequest"
import {
  useGetUserCartItemsQuery,
  useGetCreateCartQuery,
} from "../../../redux/business/business.api"
import { LicensingHeader } from "../../../components/LicensingHeader"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Checkout } from "../../../components/Licensing/PurchaseFills/Checkout"
import { Alert, Typography } from "@mui/material"
import type { CartItem, CartItemsData } from "../../../types/cartItemTypes"
import { PurchaseFillsSkeleton } from "../../../layouts/Skeletons/PurchaseFillsSkeleton"
import { formatAmountNoCents } from "../../../utils/formatAmount"

export function PurchaseFills() {
  const {
    data: cartItemData,
    error: cartItemError,
    isLoading: isCartItemLoading,
    refetch: refetchCartItemData,
  } = useGetUserCartItemsQuery({}, {})

  const skipCreateCartQuery =
    cartItemData?.data?.cartId !== null && cartItemData?.data?.cartId !== undefined
  const { data: createCartData } = useGetCreateCartQuery(
    {},
    { skip: skipCreateCartQuery || isCartItemLoading }
  )

  console.log("createCartData", createCartData)

  const transformCartItemsToMachines = (cartItems: CartItemsData) => {
    return cartItems?.data?.cartItems?.map((item: CartItem) => ({
      cartItemId: item.cartItemId,
      machineId: item.machineId,
      amount: formatAmountNoCents(String(item.amount) ?? ""),
      price: item.price,
      dateTime: item.dateTime,
    }))
  }

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const {
    cartItem,
    handleDeleteFill,
    addCartItem,
    fillActionsLoading,
    setFillActionsLoading,
    handleDropdownChange,
  } = useFillActions([])

  const {
    handleDecodeRequest,
    isSubmitting,
    showCodeInput,
    setShowCodeInput,
    newMachineCode,
    setNewMachineCode,
    codeParam,
    setCodeParam,
    handleScannedData,
  } = useDecodeRequest(addCartItem, refetchCartItemData as () => void)

  const [allMachinesHavePrices, setAllMachinesHavePrices] = useState(true)

  const getTotalPurchase = useCallback(() => {
    return parseFloat(
      cartItem
        .reduce<number>((accumulator, m) => {
          const purchaseValue = typeof m.price === "string" ? parseFloat(m.price) : m.price

          if (typeof purchaseValue === "number" && !isNaN(purchaseValue)) {
            return accumulator + purchaseValue
          } else {
            return accumulator
          }
        }, 0)
        .toFixed(0)
    )
  }, [cartItem])

  useEffect(() => {
    const allHavePricesGreaterThanZero = cartItem.every((m) => {
      // Here i'm  making sure  that price is not undefined
      if (m.price === undefined || m.price === null) {
        return false
      }

      const price = parseFloat(m.price.toString())
      return !isNaN(price) && price > 0
    })
    setAllMachinesHavePrices(allHavePricesGreaterThanZero)
  }, [cartItem])

  useEffect(() => {
    if (cartItemData !== null && cartItemData !== undefined) {
      const saveCartItemsToArray = transformCartItemsToMachines(cartItemData)
      if (saveCartItemsToArray !== null && saveCartItemsToArray !== undefined) {
        addCartItem(saveCartItemsToArray, true)
      }
    }
  }, [cartItemData])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const codeParam = urlParams.get("c")
    setCodeParam(codeParam)
  }, [])

  useEffect(() => {
    if (codeParam !== null && codeParam.trim() !== "") {
      handleDecodeRequest(codeParam).catch((err) => {
        console.error("Error processing codeParam:", err)
      })

      setCodeParam(null)
      return
    }

    if (newMachineCode !== null && newMachineCode.trim() !== "") {
      let codeToProcess = null
      if (newMachineCode.includes("http") || newMachineCode.includes("https")) {
        const url = new URL(newMachineCode)
        const code = url.searchParams.get("c")
        if (code !== null && code.trim() !== "") {
          codeToProcess = code
        }
      } else {
        codeToProcess = newMachineCode
      }

      if (codeToProcess !== null && codeToProcess.trim() !== "") {
        handleDecodeRequest(codeToProcess).catch((err) => {
          console.error("Error processing newMachineCode:", err)
        })

        setNewMachineCode("")
      }
    }
  }, [newMachineCode, codeParam])

  console.log("cartItemArray", cartItem)

  if (isCartItemLoading) {
    return <PurchaseFillsSkeleton reportName="Purchase Fills" />
  }

  if (cartItemError !== null && cartItemError !== undefined && !isCartItemLoading) {
    console.log(cartItemError)
  }

  return (
    <div className="w-full sm:max-w-4xl mx-auto mb-20 ">
      <nav className=" shadow-top shadow-bottom:hidden border-b border-gray-300 sticky top-0 z-50 bg-white">
        <LicensingHeader title="Purchase Fills" />
        <div className="grid grid-cols-1 gap-2 my-4">
          <ScannerControls
            handleDecodeRequest={handleDecodeRequest}
            showCodeInput={showCodeInput}
            setShowCodeInput={setShowCodeInput}
            isSubmitting={isSubmitting}
            fillActionsLoading={fillActionsLoading}
            onScanned={handleScannedData}
          />
        </div>
      </nav>
      {cartItem.length === 0 && (
        <Alert severity="info">
          <Typography variant="body1">
            Your cart is empty. Please add a terminal to your order by scanning its{" "}
            <span className="font-bold">Fill Request QR code</span> or by manually entering its{" "}
            <span className="font-bold"> 40-character Fill Request code.</span>
          </Typography>
        </Alert>
      )}
      {cartItem.length > 0 && (
        <MachineList
          cartItems={cartItem}
          handleDeleteFill={handleDeleteFill}
          fillActionsLoading={fillActionsLoading}
          handleDropdownChange={handleDropdownChange}
          isSubmitting={isSubmitting}
        />
      )}

      {cartItem.length > 0 && (
        <div
          className={`${
            isSmallScreen ? "fixed bottom-20 w-full bg-white z-50 shadow-top" : ""
          } pt-5  `}
        >
          <Checkout
            isSubmitting={isSubmitting}
            setFillActionsLoading={setFillActionsLoading}
            fillActionsLoading={fillActionsLoading}
            allMachinesHavePrices={allMachinesHavePrices}
            getTotalPurchase={getTotalPurchase}
          />
        </div>
      )}
    </div>
  )
}
