import React from "react"
import type { FlattenedSalesReport } from "../../../types/ISalesReportTypes"
import { AccordionSalesItem } from "./AccordionSalesItem"
import { Alert } from "@mui/material"

interface Props {
  report: FlattenedSalesReport | null
}

export function SalesReport({ report }: Props) {
  if (report === null || Object.keys(report.stores).length === 0) {
    return (
      <Alert severity="error" sx={{ width: "100%" }}>
        No data to show with the current selection.
      </Alert>
    )
  }
  return (
    <div>
      {Object.entries(report.stores).map(([businessId, { name, stores, totalSummary }], index) => {
        const storeName = name
        const isSingleBusiness = Object.entries(report.stores).length === 1

        return (
          <React.Fragment key={`${name}${index}`}>
            <AccordionSalesItem
              isSingleBusiness={isSingleBusiness}
              businessId={businessId}
              name={storeName}
              stores={stores}
              totalSummary={totalSummary}
            />
          </React.Fragment>
        )
      })}
    </div>
  )
}
