import { createTheme } from "@mui/material/styles"

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#14213D",
      contrastText: "#ffffff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          "@media (hover: hover)": {
            "&:hover": {
              backgroundColor: "#FCA311",
            },
          },
          "&:active": {
            backgroundColor: "#FCA311",
          },
        },
      },
    },
  },
})
