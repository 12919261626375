import { useState, useEffect, type FC, type ChangeEvent, memo } from "react"
import TextField from "@mui/material/TextField"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { InputAdornment, IconButton } from "@mui/material"

interface PasswordFieldProps {
  setPassword: (password: string) => void
}

const PasswordField: FC<PasswordFieldProps> = ({ setPassword }) => {
  const [localPassword, setLocalPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("rememberMe") === "true") {
      const storedPassword = localStorage.getItem("password") ?? ""
      setLocalPassword(storedPassword)
      setPassword(storedPassword)
    }
  }, [setPassword])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalPassword(e.target.value)
    setPassword(e.target.value)
  }

  return (
    <TextField
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type={showPassword ? "text" : "password"}
      id="numeric"
      onChange={handleChange}
      value={localPassword}
      autoComplete="off"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setShowPassword(!showPassword)
              }}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default memo(PasswordField)
