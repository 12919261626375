import { Typography, Button, Alert, Box } from "@mui/material"
import { useNavigate } from "react-router-dom"
// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
interface PasswordResetRequestProps {
  email: string
  setShowResetRequestSent: (showResetRequestSent: boolean) => void
}

export const PasswordResetSent = ({
  email,
  setShowResetRequestSent,
}: PasswordResetRequestProps) => {
  const navigate = useNavigate()

  return (
    <Box>
      <Alert>
        <Typography>
          We will send further instructions to reset your password, if an account is associated
          with:
        </Typography>
        <Typography sx={{ fontWeight: "700", wordBreak: "break-all" }}>
          &quot;{email}&quot;
        </Typography>
      </Alert>
      <Button
        fullWidth
        variant="contained"
        onClick={() => {
          navigate("/login")
          setShowResetRequestSent(false)
        }}
        sx={{
          mt: 3,
          mb: 2,
          position: "relative",
        }}
      >
        Got it!
      </Button>
    </Box>
  )
}
