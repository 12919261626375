import React, { useState, memo } from "react"
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded"
import {
  SummarySection,
  SummaryItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../../styles/AccordionStyles"
import { TransactionsTable } from "./TransactionsTable"
import { formatAmount } from "../../../utils/formatAmount"
import { TablePaginationItem } from "../../TablePaginationItem"
import { useLocation } from "react-router-dom"
import { Truncate } from "../../Truncate"
import { type Location } from "../../../types/ICollection"
interface Props {
  collapseAllBusinesses: boolean
  businessId: string
  businessName: string
  totalBusinessAdd: number
  totalBusinessRemove: number
  totalBusinessStack: number
  totalBusinessBill: number
  locations: Location[]
  hasTransactions: boolean
}

const AccordionItemComponent = ({
  collapseAllBusinesses,
  businessId,
  businessName,
  totalBusinessRemove,
  totalBusinessBill,
  locations,
  hasTransactions,
}: Props) => {
  const [expandedBusiness, setExpandedBusiness] = useState<string | false>(
    collapseAllBusinesses ? businessId : false
  )
  const [expandedLocation, setExpandedLocation] = useState<string | false>(false)
  const [expandedTerminal, setExpandedTerminal] = useState<string | false>(false)

  // Get the selected location ID from the URL
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const selectedLocationId = urlParams.get("locationId")

  const handleBusinessChange =
    (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpandedBusiness(isExpanded ? panel : false)
    }

  const handleLocationChange =
    (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpandedLocation(isExpanded ? panel : false)
      setPage(0)
      setRowsPerPage(5)
    }

  const handleTerminalChange =
    (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpandedTerminal(isExpanded ? panel : false)
      setPage(0)
      setRowsPerPage(5)
    }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [page, setPage] = useState(0)

  if (!hasTransactions) {
    return null
  }

  const dontShowBusiness = locations.every((location) => {
    const dontShowTerminals = location.terminals.every((terminal) => {
      const filteredTransactions = terminal.transactions
      if (filteredTransactions.length === 0) return true
      else return false
    })
    return dontShowTerminals
  })

  if (dontShowBusiness) return null

  return (
    <Accordion
      expanded={expandedBusiness === businessId}
      onChange={handleBusinessChange(businessId)}
      key={businessId}
      TransitionProps={{ timeout: 10 }}
    >
      {/* waterfall effect in accordion, push summary 12px to the left - default 16px */}
      <AccordionSummary
        sx={{
          backgroundColor: "#DEE2E6",
          borderTop: 2,
          borderTopColor: "darkgrey",
          paddingLeft: "4px",
        }}
        expandIcon={<NavigateNextRoundedIcon />}
      >
        {/* compensate the 12px move to left in the summary items to keep columns aligned */}
        <SummarySection sx={{ flexBasis: "100%", paddingLeft: "12px" }}>
          <SummaryItem
            sx={{
              textAlign: "left",
              minWidth: "136px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              flexBasis: "50%",
            }}
          >
            <Truncate input={businessName} />
          </SummaryItem>
          <SummaryItem
            sx={{
              textAlign: "right",
              minWidth: "68px",
              flexBasis: "25%",
            }}
          >
            {totalBusinessBill === -1 ? "-" : totalBusinessBill}
          </SummaryItem>
          <SummaryItem
            sx={{
              textAlign: "right",
              minWidth: "68px",
              flexBasis: "25%",
            }}
          >
            <span className="hidden sm:block">{formatAmount(totalBusinessRemove).full}</span>
            <span className="block sm:hidden">{formatAmount(totalBusinessRemove).short}</span>
          </SummaryItem>
        </SummarySection>
      </AccordionSummary>
      <AccordionDetails>
        {locations
          .filter((location) =>
            location.terminals.some((terminal) => terminal.transactions.length > 0)
          )
          .map(
            ({ locationId, softwareName, totalLocationRemove, terminals, totalLocationBill }) => {
              const isSelected = locationId.toString() === selectedLocationId
              return (
                <Accordion
                  expanded={expandedLocation === locationId}
                  onChange={handleLocationChange(locationId)}
                  key={locationId}
                  TransitionProps={{ timeout: 10 }}
                >
                  {/* waterfall effect in accordion, push summary 6px to the left - default 16px */}
                  <AccordionSummary
                    sx={{
                      backgroundColor: isSelected ? "#FFF6E8" : "#E9ECEF",
                      paddingLeft: "10px",
                    }}
                    expandIcon={<NavigateNextRoundedIcon />}
                  >
                    {/* compensate the 6px move to left in the summary items to keep columns aligned */}
                    <SummarySection sx={{ flexBasis: "100%", paddingLeft: "6px" }}>
                      <SummaryItem
                        sx={{
                          textAlign: "left",
                          minWidth: "136px",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          flexBasis: "50%",
                        }}
                      >
                        {locationId} {softwareName}
                      </SummaryItem>

                      <SummaryItem
                        sx={{
                          textAlign: "right",
                          minWidth: "68px",
                          flexBasis: "25%",
                        }}
                      >
                        {totalLocationBill === -1 ? "-" : totalLocationBill}
                      </SummaryItem>
                      <SummaryItem
                        sx={{
                          textAlign: "right",
                          minWidth: "68px",
                          flexBasis: "25%",
                        }}
                      >
                        <span className="hidden sm:block">
                          {formatAmount(totalLocationRemove).full}
                        </span>
                        <span className="block sm:hidden">
                          {formatAmount(totalLocationRemove).short}
                        </span>
                      </SummaryItem>
                    </SummarySection>
                  </AccordionSummary>
                  <AccordionDetails>
                    {terminals.map(
                      ({ terminalName, totalTerminalRemove, totalTerminalBill, transactions }) => {
                        const filteredTransactions = transactions
                        const visibleTransactions = filteredTransactions.slice(
                          page * rowsPerPage,
                          (page + 1) * rowsPerPage
                        )
                        const emptyRows =
                          page > 0
                            ? Math.max(0, (1 + page) * rowsPerPage - filteredTransactions.length)
                            : 0
                        return (
                          <Accordion
                            expanded={expandedTerminal === terminalName}
                            onChange={handleTerminalChange(terminalName)}
                            key={terminalName}
                            TransitionProps={{ timeout: 10 }}
                          >
                            <AccordionSummary
                              sx={{
                                backgroundColor: "#F8F9FA",
                              }}
                              expandIcon={<NavigateNextRoundedIcon />}
                            >
                              <SummarySection sx={{ flexBasis: "100%" }}>
                                <SummaryItem
                                  sx={{
                                    textAlign: "left",
                                    minWidth: "136px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    flexBasis: "50%",
                                  }}
                                >
                                  {terminalName}
                                </SummaryItem>

                                <SummaryItem
                                  sx={{
                                    textAlign: "right",
                                    minWidth: "68px",
                                    flexBasis: "25%",
                                  }}
                                >
                                  {totalTerminalBill === -1 ? "-" : totalTerminalBill}
                                </SummaryItem>

                                <SummaryItem
                                  sx={{
                                    textAlign: "right",
                                    minWidth: "68px",
                                    flexBasis: "25%",
                                  }}
                                >
                                  <span className="hidden sm:block">
                                    {formatAmount(totalTerminalRemove).full}
                                  </span>
                                  <span className="block sm:hidden">
                                    {formatAmount(totalTerminalRemove).short}
                                  </span>
                                </SummaryItem>
                              </SummarySection>
                            </AccordionSummary>
                            <AccordionDetails>
                              <TransactionsTable
                                visibleTransactions={visibleTransactions}
                                emptyRows={emptyRows}
                              />

                              <TablePaginationItem
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={transactions.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </AccordionDetails>
                          </Accordion>
                        )
                      }
                    )}
                  </AccordionDetails>
                </Accordion>
              )
            }
          )}
      </AccordionDetails>
    </Accordion>
  )
}

export const AccordionCollectionsItem = memo(AccordionItemComponent)
