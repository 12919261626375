import React, { useEffect } from "react"
import { useTheme } from "@mui/material/styles"
import Pagination from "@mui/material/Pagination"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"
import { BusinessCard } from "./OverviewBusinessCard"
import { CardContentStyled, CardStyled } from "../../styles/OverviewCardStyles"
import type { Business } from "./OverviewTypesFlattened"
import { useLocation, useNavigate } from "react-router-dom"
import { Alert } from "@mui/material"

interface OverviewBusinessesProps {
  businesses: Business[]
  businessCount: number
  locationCount: number
  terminalCount: number
  page: number
  setPage: (value: number) => void
}

const ITEMS_PER_PAGE = 10

export function OverviewBusinesses({
  businesses,
  businessCount,
  locationCount,
  terminalCount,
  page,
  setPage,
}: OverviewBusinessesProps): React.ReactElement {
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"))

  const variant = matchesXS ? "body2" : "h6"
  const paginationSize = matchesXS ? "small" : "medium"
  const location = useLocation()
  const navigate = useNavigate()
  const query = new URLSearchParams(location.search)

  const numberOfPages = Math.ceil(businessCount / ITEMS_PER_PAGE)

  // Update the page state when the URL changes
  useEffect(() => {
    const newPage = parseInt(query.get("page") ?? "1", 10)
    if (newPage !== page) {
      setPage(newPage)
    }
  }, [location.search, page])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value !== page) {
      const params = new URLSearchParams(location.search)
      params.set("page", value.toString())
      navigate(`?${params.toString()}`, { replace: false })
      setPage(value)
      window.scrollTo({ top: 0 })
    }
  }

  // Calculate the start and end index for slicing the businesses array
  const startIndex = (page - 1) * ITEMS_PER_PAGE
  const endIndex = startIndex + ITEMS_PER_PAGE

  useEffect(() => {
    if (page > numberOfPages) {
      const params = new URLSearchParams(location.search)
      params.set("page", "1")
      navigate(`?${params.toString()}`, { replace: true })
      setPage(1)
    }
  }, [page, numberOfPages])

  if (businessCount === 0) {
    return (
      <Alert severity="error" sx={{ width: "100%" }}>
        No data to show with the current selection.
      </Alert>
    )
  }
  return (
    <div>
      <CardStyled>
        <CardContentStyled>
          <div className="flex mx-4 my-2 justify-between">
            <Typography variant={variant} noWrap>
              Businesses: {businessCount}
            </Typography>
            <Typography variant={variant} noWrap>
              Locations: {locationCount}
            </Typography>
            <Typography variant={variant} noWrap>
              Terminals: {terminalCount}
            </Typography>
          </div>
        </CardContentStyled>
      </CardStyled>
      {businesses.slice(startIndex, endIndex).map((business: Business) => (
        <React.Fragment key={business.businessInfo.businessId}>
          <BusinessCard business={business} />
        </React.Fragment>
      ))}
      <div className="flex my-8 justify-center">
        <Pagination
          size={paginationSize}
          count={numberOfPages}
          page={page}
          boundaryCount={1}
          siblingCount={1}
          onChange={handleChange}
          sx={{
            "& .MuiPagination-ul": {
              flexWrap: "nowrap",
            },
            "& .MuiPaginationItem-root": {
              "& .MuiTouchRipple-root": {
                display: "none",
              },
              "&:hover": {
                backgroundColor: matchesXS ? "transparent" : "undefined",
              },
            },
          }}
        />
      </div>
    </div>
  )
}
