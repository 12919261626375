import { useState, useEffect, type FC, type ChangeEvent, memo } from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import { Email } from "@mui/icons-material"
import { InputAdornment, Icon } from "@mui/material"

interface UsernameFieldProps {
  setUsername: (username: string) => void
}

const UsernameField: FC<UsernameFieldProps> = ({ setUsername }) => {
  const [localUsername, setLocalUsername] = useState("")

  useEffect(() => {
    if (localStorage.getItem("rememberMe") === "true") {
      const storedUsername = localStorage.getItem("username") ?? ""
      setLocalUsername(storedUsername)
      setUsername(storedUsername)
    }
  }, [setUsername])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalUsername(e.target.value)
    setUsername(e.target.value)
  }

  return (
    <TextField
      margin="normal"
      required
      fullWidth
      id="username"
      label="Username"
      name="username"
      autoComplete="username"
      value={localUsername}
      onChange={handleChange}
      autoFocus
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Box sx={{ margin: "0px -12px 0px 0px", padding: "8px" }}>
              <Icon component={Email} />
            </Box>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default memo(UsernameField)
