import type {
  IBusiness,
  ILocationItem,
  ILocations,
  ITerminal,
} from "../components/Terminals/TerminalsTypesFlattened"

interface IFlattenedTerminals {
  businesses: Record<string, ITerminal[]>
  locations: ILocationItem[]
}

export function flattenTerminals(data: IFlattenedTerminals): IBusiness[] {
  const businesses: IBusiness[] = []

  if (data?.locations !== undefined) {
    for (const originalStore of data.locations) {
      const businessId = originalStore.businessInfo.businessId
      const storeId = originalStore.id
      const softwareName = originalStore.sofwareName
      const terminalKey = `${storeId} ${softwareName}`
      const terminals = data.businesses[terminalKey] ?? []

      const store: ILocations = {
        isComplete: false,
        lastUpdate: originalStore.lastUpdate,
        store: {
          ...originalStore,

          terminals: [...terminals], // Removed sorting
          terminalCount: terminals.length,
        },
      }

      let business: IBusiness | undefined = businesses.find(
        (b) => b.businessInfo.businessId === businessId
      )

      if (business === undefined) {
        business = {
          businessInfo: { ...originalStore.businessInfo },
          stores: [],
        }
        businesses.push(business)
      }

      business.stores.push(store)
    }

    // Removed sorting logic for locations and businesses here as well

    console.log("flattenTerminals Output:", businesses)
    return businesses
  } else {
    return []
  }
}
