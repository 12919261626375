import { Box, Alert, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"

export const SuccessfulPasswordUpdate = () => {
  const navigate = useNavigate()

  return (
    <Box sx={{ textAlign: "center" }}>
      <Alert>Your password has been updated successfully.</Alert>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        sx={{ mt: 1, mb: 2, position: "relative" }}
        onClick={() => {
          navigate("/login")
        }}
      >
        Go to Login
      </Button>
    </Box>
  )
}
