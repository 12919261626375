export interface Business {
  businessId: string
  businessName: string
  phone: string
  secondaryPhone: string
  street: string
  city: string
  zipPostal: string
  locality: string
  country: string
  locations: BusinessLocation[]
}

interface BusinessLocation {
  locationId: string
  locationName: string
  softwareName: string
  productType: string
  deviceStatuses: DeviceStatus[]
}

interface DeviceStatus {
  cashStatusDateTime: string
  name: string
  recycler1Count: number
  recycler1Value: number
  recycler2Count: number
  recycler2Value: number
  stackerCount: number
  stackerValue: number
  terminalName: string
}

export interface InputJson {
  businesses: Business[]
}

export type OutputJson = Array<{
  businessId: string
  businessName: string
  phone: string
  secondaryPhone: string
  street: string
  city: string
  zipPostal: string
  locality: string
  country: string
  totalBusinessRecycler1Count: number
  totalBusinessRecycler2Count: number
  totalBusinessStackerCount: number
  totalBusinessRecycler1Value: number
  totalBusinessRecycler2Value: number
  totalBusinessStackerValue: number
  locations: Array<{
    locationId: string
    softwareName: string
    productType: string
    totalLocationRecycler1Count: number
    totalLocationRecycler2Count: number
    totalLocationStackerCount: number
    totalLocationRecycler1Value: number
    totalLocationRecycler2Value: number
    totalLocationStackerValue: number
    terminals: Array<{
      terminalName: string
      cashStatusDateTime: string
      recycler1Count: number
      recycler2Count: number
      stackerCount: number
      recycler1Value: number
      recycler2Value: number
      stackerValue: number
      deviceStatuses: any
    }>
  }>
}>
export function flattenCurrent(input: InputJson): OutputJson {
  const output: OutputJson = []

  //  Copy of the businesses array and sort it by businessName
  const sortedBusinesses = [...input.businesses].sort((a, b) =>
    a.businessName.localeCompare(b.businessName, undefined, { numeric: true })
  )

  sortedBusinesses.forEach((business) => {
    output.push({
      businessId: business.businessId,
      businessName: business.businessName,
      phone: business.phone,
      secondaryPhone: business.secondaryPhone,
      street: business.street,
      city: business.city,
      zipPostal: business.zipPostal,
      locality: business.locality,
      country: business.country,
      totalBusinessRecycler1Count: 0,
      totalBusinessRecycler2Count: 0,
      totalBusinessStackerCount: 0,
      totalBusinessRecycler1Value: 0,
      totalBusinessRecycler2Value: 0,
      totalBusinessStackerValue: 0,
      locations: [],
    })

    const currentBusinessLocations = [...business.locations].sort((a, b) =>
      a.locationId.localeCompare(b.locationId, undefined, { numeric: true })
    )

    currentBusinessLocations.forEach((location) => {
      output
        .find((biz) => biz.businessId === business.businessId)
        ?.locations.push({
          locationId: location.locationId,
          softwareName: location.softwareName,
          productType: location.productType,
          totalLocationRecycler1Count: 0,
          totalLocationRecycler2Count: 0,
          totalLocationStackerCount: 0,
          totalLocationRecycler1Value: 0,
          totalLocationRecycler2Value: 0,
          totalLocationStackerValue: 0,
          terminals: [],
        })

      const currentLocationDeviceStatuses = [...location.deviceStatuses].sort((a, b) =>
        a.terminalName.localeCompare(b.terminalName, undefined, { numeric: true })
      )

      currentLocationDeviceStatuses.forEach((deviceStatus) => {
        const currentLocation = output
          .find((biz) => biz.businessId === business.businessId)
          ?.locations.find((loc) => loc.locationId === location.locationId)

        currentLocation?.terminals.push({
          terminalName: deviceStatus.terminalName,
          recycler1Count: deviceStatus.recycler1Count,
          recycler2Count: deviceStatus.recycler2Count,
          stackerCount: deviceStatus.stackerCount,
          recycler1Value: deviceStatus.recycler1Value,
          recycler2Value: deviceStatus.recycler2Value,
          stackerValue: deviceStatus.stackerValue,
          cashStatusDateTime: deviceStatus.cashStatusDateTime,
          deviceStatuses: deviceStatus,
        })

        currentLocation?.terminals.sort((a, b) =>
          a.terminalName.localeCompare(b.terminalName, undefined, { numeric: true })
        )

        if (currentLocation !== null && currentLocation !== undefined) {
          currentLocation.totalLocationRecycler1Count += deviceStatus.recycler1Count
          currentLocation.totalLocationRecycler2Count += deviceStatus.recycler2Count
          currentLocation.totalLocationStackerCount += deviceStatus.stackerCount
          currentLocation.totalLocationRecycler1Value += deviceStatus.recycler1Value
          currentLocation.totalLocationRecycler2Value += deviceStatus.recycler2Value
          currentLocation.totalLocationStackerValue += deviceStatus.stackerValue
        }

        const currentBusiness = output.find((biz) => biz.businessId === business.businessId)
        if (currentBusiness !== null && currentBusiness !== undefined) {
          currentBusiness.totalBusinessRecycler1Count += deviceStatus.recycler1Count
          currentBusiness.totalBusinessRecycler2Count += deviceStatus.recycler2Count
          currentBusiness.totalBusinessStackerCount += deviceStatus.stackerCount
          currentBusiness.totalBusinessRecycler1Value += deviceStatus.recycler1Value
          currentBusiness.totalBusinessRecycler2Value += deviceStatus.recycler2Value
          currentBusiness.totalBusinessStackerValue += deviceStatus.stackerValue
        }
      })
    })
  })

  console.log("Flattened device statuses: ", output)
  return output
}
