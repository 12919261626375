import { Box, Grid, Button, Alert, Typography } from "@mui/material"
import { useState, type FormEvent } from "react"
import { NewPasswordTextField } from "../NewPasswordTextField"
import { ConfirmPasswordTextField } from "../ConfirmPasswordTextField"

interface PasswordFormProps {
  onSubmit: (newPassword: string, confirmPassword: string) => Promise<void>
  error: string | null
}

export const PasswordForm: React.FC<PasswordFormProps> = ({
  onSubmit,
  error,
}: PasswordFormProps) => {
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const passwordsMatch =
    newPassword.trim() === confirmPassword.trim() && confirmPassword.trim() !== ""

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFormSubmitted(true)
    void (async () => {
      await onSubmit(newPassword, confirmPassword)
    })()
  }

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: "100%", mt: 1 }}>
      <Grid container rowGap={2}>
        <Grid item xs={12}>
          <Typography variant="body1">Create a New Password</Typography>
        </Grid>
        <Grid item xs={12}>
          <NewPasswordTextField
            required
            label="New Password"
            value={newPassword.trim()}
            error={formSubmitted && newPassword.trim() === ""}
            helperText={
              formSubmitted && newPassword.trim() === "" ? "This field cannot be empty." : " "
            }
            onChange={(e) => {
              setNewPassword(e.target.value)
            }}
            show={showNewPassword}
            setShow={setShowNewPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <ConfirmPasswordTextField
            required
            label="Confirm New Password"
            value={confirmPassword.trim()}
            error={formSubmitted && confirmPassword.trim() === ""}
            helperText={
              formSubmitted && confirmPassword.trim() === "" ? "This field cannot be empty." : " "
            }
            onChange={(e) => {
              setConfirmPassword(e.target.value)
            }}
            show={showConfirmPassword}
            setShow={setShowConfirmPassword}
            match={passwordsMatch}
          />
        </Grid>

        {error != null && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            sx={{
              mt: 1,
              mb: 2,
              position: "relative",
            }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Update Password
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
