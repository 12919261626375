import { jwtDecode } from "jwt-decode"

interface JwtPayload {
  exp: number
}

export const decodeJwtTtl = (token: string): number | null => {
  try {
    const decoded = jwtDecode<JwtPayload>(token)
    const currentTime = Math.floor(Date.now() / 1000)
    const ttl = decoded.exp - currentTime
    return ttl > 0 ? ttl : null
  } catch (error) {
    console.error("Invalid token:", error)
    return null
  }
}
