import { useEffect, useState } from "react"
import type dayjs from "dayjs"
import {
  Button,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Skeleton,
} from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { AutoCompleteDropDown } from "../components/AutoCompleteDropDown"
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined"
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined"
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined"
import { type CalendarInputType, LocationInputType } from "../types/reportTypes"
import { SummarySection, SummaryItem, AccordionSummary, Accordion } from "../styles/AccordionStyles"
import { ExpandCollapseAll } from "../components/ExpandCollapseAll"
import { useDispatch, useSelector } from "react-redux"
import { type RootState } from "../redux/store"
import { toggleExpandAll } from "../redux/buttons/expandAll.slice"
import { RenderCalendar } from "../components/RenderCalendar"
// import { FullscreenToggle } from "../components/FullscreenToggle"
import { EncryptStorage } from "encrypt-storage"
import { setProductFilters } from "../redux/filters/filters.slice"
import { SummaryCard } from "../components/SummaryCard"
import { Export } from "../components/Export"
const storage = new EncryptStorage("jfkejfklrjklgjerlk485859809gjtkljkgjl")

interface Props {
  children: JSX.Element
  hasExport?: boolean
  exportFlattenedDetailedData?: any[]
  exportFlattenedSummaryData?: any[]
  reportName: string
  showHeaderDate: boolean
  showTotalSummaries?: boolean
  calendarInput: CalendarInputType
  locationInput: LocationInputType
  hasHeaderDate?: boolean
  headerDateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null]
  showForm: boolean
  toggleForm: (show: boolean) => void
  handleLocationChange: (e: React.SyntheticEvent<Element, Event>, value: number | null) => void
  selectedValue: {
    name: string
    id: null
  }
  handleSetSelectedValue: (value: any | null) => void
  setReportDateRange: React.Dispatch<React.SetStateAction<[dayjs.Dayjs | null, dayjs.Dayjs | null]>>
  openFiltersHandler: (() => void) | undefined
  location: string | null
  isFetchingReport?: boolean
  getReportHandler: () => Promise<void>
  accordionHeadings: string[]
  showAccordionHeadings: boolean
  accordionSummaries?: string[]
  showSummaries?: boolean
  isActiveReport?: boolean
  disableChooseLocation?: boolean
}

export const ReportLayout = ({
  children,
  hasExport = false,
  exportFlattenedDetailedData,
  exportFlattenedSummaryData,
  calendarInput,
  locationInput,
  showHeaderDate,
  showTotalSummaries,
  hasHeaderDate = false,
  headerDateRange,
  showForm,
  toggleForm,
  handleLocationChange,
  reportName,
  selectedValue,
  handleSetSelectedValue,
  setReportDateRange,
  openFiltersHandler,
  location,
  isFetchingReport,
  getReportHandler,
  accordionHeadings,
  showAccordionHeadings,
  accordionSummaries,
  showSummaries,
  isActiveReport,
  disableChooseLocation,
}: Props) => {
  const dispatch = useDispatch()

  const [loadingReport, setLoadingReport] = useState(false)

  const expandAll = useSelector((state: RootState) => state.buttons.expandAll)
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))
  const toggleExpand = (items: any) => dispatch(toggleExpandAll(items))
  const [data, setData] = useState<any | null>(null)

  const [giveMargin, setGiveMargin] = useState(false)

  // Accessing summary data from redux

  const revenueData = useSelector((state: RootState) => state.dataSlice.revenueData)

  const salesData = useSelector((state: RootState) => state.dataSlice.salesData)

  const showHeader = useSelector((state: RootState) => state.hideReportHeader.value)

  const summaryData = reportName === "Revenue" ? revenueData : salesData

  console.log("summaryData", summaryData)

  const shouldRenderForm = () => {
    return (
      locationInput === LocationInputType.SelectLoad ||
      locationInput === LocationInputType.SelectGetLoad ||
      locationInput === LocationInputType.NoLocation ||
      openFiltersHandler !== undefined
    )
  }

  // getting location from local storage when we load the report

  useEffect(() => {
    ;(async () => {
      let locations = storage.getItem("locations")

      locations = locations.map((location: any) => {
        return {
          id: location.id,
          name: location.name,
          businessId: location.businessId,
          productType: location.productType,
        }
      })
      const getProductTypes = (locations: Location[]) => {
        const productTypes: Record<string, boolean> = {}
        locations
          .map((location: any) => location.productType)
          .forEach((productType) => (productTypes[productType] = false))
        return productTypes
      }

      const productTypes = getProductTypes(locations)
      dispatch(setProductFilters(productTypes))
      setData(locations)
    })().catch((error) => {
      console.log(error)
    })
  }, [])

  return (
    <div className={`w-full md:max-w-4xl mx-auto ${giveMargin ? "mb-[600px]" : "mb-1"}`}>
      <nav
        id="reportHeader"
        className="shadow-top shadow-bottom:hidden border-b border-gray-300 sticky top-0 z-50 bg-white"
      >
        <div className="flex justify-between items-center py-1 px-2 bg-[#14213D]">
          {hasExport && (
            <Export
              exportFlattenedDetailedData={exportFlattenedDetailedData}
              exportFlattenedSummaryData={exportFlattenedSummaryData}
              disabled={exportFlattenedDetailedData?.[0] === undefined || loadingReport}
              reportName={reportName}
              headerDateRangeFrom={
                headerDateRange[0] !== null ? headerDateRange[0].format("YYYY-MM-DD") : ""
              }
              headerDateRangeTo={
                headerDateRange[1] !== null ? headerDateRange[1].format("YYYY-MM-DD") : ""
              }
            />
          )}
          <div className="flex-grow text-center">
            <h1
              className="text-2xl font-bold text-white"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" })
              }}
            >
              {reportName}
            </h1>
            {hasHeaderDate &&
              (showHeaderDate ? (
                <Typography variant="subtitle2" color="white">
                  {headerDateRange[0]?.format("YYYY-MM-DD")} to{" "}
                  {headerDateRange[1]?.format("YYYY-MM-DD")}
                </Typography>
              ) : (
                <Skeleton
                  sx={{ display: loadingReport ? "block" : "none", height: "21.99px" }}
                  animation="wave"
                  variant="text"
                />
              ))}
          </div>
          <div className="flex">
            <div
              style={{
                width: hasExport ? "72px" : "40px",
                height: "40px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {!isLargeScreen &&
                (showForm ? (
                  <IconButton
                    onClick={() => {
                      toggleForm(!showForm)
                    }}
                    sx={{ color: "#fff" }}
                  >
                    <ExpandLessOutlinedIcon />
                  </IconButton>
                ) : shouldRenderForm() ? (
                  <IconButton
                    onClick={() => {
                      toggleForm(!showForm)
                    }}
                    sx={{ color: "#fff" }}
                  >
                    <ExpandMoreOutlinedIcon />
                  </IconButton>
                ) : (
                  <div style={{ width: hasExport ? "72px" : "40px", height: "40px" }} />
                ))}
            </div>
          </div>
        </div>

        {showForm && shouldRenderForm() && (
          <div
            className={`${
              showForm ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
            } overflow-hidden transition-all duration-500`}
          >
            <form className="px-4 pt-2 pb-5 mt-3 w-full">
              <div className="flex gap-6 justify-between flex-col sm:flex-row">
                {(locationInput === LocationInputType.SelectLoad ||
                  locationInput === LocationInputType.SelectGetLoad) && (
                  <AutoCompleteDropDown
                    disabled={loadingReport || disableChooseLocation}
                    excludeAllLocations={reportName === "Terminals"}
                    options={data ?? []}
                    // this is how it worked before cashing  options={data?.data?.allLocations || data || []}
                    onChange={handleLocationChange}
                    label="Choose Location"
                    value={location}
                    selectedValue={selectedValue}
                    setSelectedValue={handleSetSelectedValue}
                  />
                )}
                <RenderCalendar
                  disabled={loadingReport}
                  calendarInput={calendarInput}
                  headerDateRange={headerDateRange}
                  setReportDateRange={setReportDateRange}
                  setGiveMargin={setGiveMargin}
                />
              </div>

              <div className="mt-2 flex justify-between">
                <div className="flex">
                  {(reportName === "Cash Adjustments" ||
                    reportName === "Performance" ||
                    reportName === "Collections" ||
                    reportName === "Revenue" ||
                    reportName === "Sales" ||
                    reportName === "Current Cash" ||
                    reportName === "Overview") &&
                    openFiltersHandler !== undefined && (
                      <Button
                        disabled={loadingReport || isFetchingReport}
                        variant="outlined"
                        onClick={openFiltersHandler}
                        sx={{ mr: 1 }}
                      >
                        <FilterListOutlinedIcon sx={{ mr: isLargeScreen ? 1 : 0 }} />
                        {isLargeScreen && "Filters"}
                      </Button>
                    )}
                </div>

                <div className="flex">
                  {(locationInput === LocationInputType.SelectGetLoad ||
                    locationInput === LocationInputType.NoLocation) && (
                    <Button
                      variant="contained"
                      disabled={loadingReport}
                      onClick={() => {
                        ;(async () => {
                          setLoadingReport(true)
                          await getReportHandler()
                          setLoadingReport(false)
                        })()
                          .then(() => {})
                          .catch(() => {})
                      }}
                    >
                      {loadingReport && (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                      Get Report
                    </Button>
                  )}

                  {reportName === "" && (isActiveReport ?? false) ? (
                    <div className="flex">
                      <ExpandCollapseAll
                        expandAll={expandAll}
                        toggleExpandAll={() => toggleExpand(data?.data.allLocations)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </form>
          </div>
        )}
        <>
          {" "}
          {showHeader &&
            showSummaries !== null &&
            showSummaries !== undefined &&
            showTotalSummaries !== undefined &&
            showTotalSummaries !== null &&
            showTotalSummaries && (
              <SummaryCard
                summaryData={summaryData}
                reportType={reportName}
                accordionSummaries={accordionSummaries}
                loadingReport={loadingReport}
              />
            )}
          {showAccordionHeadings && (
            <Accordion
              sx={{ backgroundColor: "#F2F3F4", cursor: "default", pointerEvents: "none" }}
            >
              <AccordionSummary>
                <SummarySection
                  sx={{
                    marginLeft: "24px",
                    flexBasis: "100%",
                  }}
                >
                  {accordionHeadings.map((heading, index) => {
                    return index === 0 ? (
                      <SummaryItem
                        key={heading}
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          minWidth: "56px",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          flexBasis: `${Math.round(100 / accordionHeadings.length)}%`,
                        }}
                      >
                        {heading}
                      </SummaryItem>
                    ) : (
                      <SummaryItem
                        key={heading}
                        sx={{
                          textAlign: "right",
                          fontWeight: "bold",
                          minWidth: "56px",
                          flexBasis: `${Math.round(100 / accordionHeadings.length)}%`,
                        }}
                      >
                        {heading}
                      </SummaryItem>
                    )
                  })}
                </SummarySection>
              </AccordionSummary>
            </Accordion>
          )}
        </>
      </nav>
      <div className={`${giveMargin ? "hidden report-container" : "block"}`}>{children}</div>
    </div>
  )
}
