import { useMemo, useState, useEffect, useCallback, useRef } from "react"
import dayjs, { type Dayjs } from "dayjs"
import { useGetCurrentCashMutation } from "../../../redux/business/business.api"
import { Report } from "../../../components/Reports/Current"
import { flattenCurrent } from "../../../middlewares/flattenCurrent"
import { ReportSkeleton } from "../../../layouts/Skeletons/ReportSkeleton"

import { useMediaQuery, useTheme } from "@mui/material"

import Alert, { type AlertColor } from "@mui/material/Alert"
import { type HandleSnackBarType, LocationInputType } from "../../../types/reportTypes"
import { ReportLayout } from "../../../layouts/ReportLayout"
import { getReportsHandler } from "../../../utils/ReportHandlerInput"
import { CalendarInputType } from "../../../types/reportTypes"
import { useNavigate, useSearchParams } from "react-router-dom"
import { flattenExport } from "../../../utils/flattenExport"
import { currentCashDetailedConfig, currentCashSummaryConfig } from "../../../data/exportConfigs"
import { useSelector } from "react-redux"
import { type RootState } from "../../../redux/store"
import { type OutputBusiness, type OutputLocation } from "../../../types/ICurrent"

interface Props {
  openFiltersHandler?: () => void
}

export function CurrentCash(props: Props) {
  const [fetchReport] = useGetCurrentCashMutation()
  const [location, setLocation] = useState<string | null>(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showHeaderDate, setShowHeaderDate] = useState(false)
  const hasHeaderDate = false
  const requiresAPICall = true
  const [reportDateRange, setReportDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs(),
    dayjs(),
  ])
  const [headerDateRange, setHeaderDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null])
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("error")

  const [report, setReport] = useState([])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const [showForm, setShowForm] = useState(isMobile)
  const [isFetchingReport, setIsFetchingReport] = useState(false)
  const allLocationsOption = { name: "All Locations", id: null, businessId: null }
  const [selectedValue, setSelectedValue] = useState<any>(allLocationsOption)

  const [firstSelectedValue, setFirstSelectedValue] = useState<any>(null)

  const isMountedRef = useRef(true)

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  useEffect(() => {
    // When the component mounts
    isMountedRef.current = true

    return () => {
      // When the component unmounts
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (!isMobile) {
      setShowForm(true)
    }
  }, [isMobile])

  const toggleForm = useCallback(
    (show: boolean) => {
      if (!isMobile) {
        setShowForm(true)
      } else {
        setShowForm(show)
      }
    },
    [isMobile]
  )

  const handleLocationChange = (e: React.SyntheticEvent<Element, Event>, value: number | null) => {
    setLocation(value !== null ? value.toString() : null)
  }

  const handleSetSelectedValue = (value: any | null) => {
    setSelectedValue(value)
    if (firstSelectedValue === null) {
      setFirstSelectedValue(value)
    }
  }
  const handleSnackBar: HandleSnackBarType = (isOpen, severity, message) => {
    setSnackbarSeverity(severity)
    setSnackbarMessage(message)
    setSnackbarOpen(isOpen)
  }

  const flattenedReport = useMemo(() => {
    return flattenCurrent({
      businesses: report,
    })
  }, [report])

  // this useEffect allows us to navigate through browser back and forward button
  // uncomment the code below for browser back and forward button

  useEffect(() => {
    // If locationId is not in the URL parameters, don't run the effect.
    if (!searchParams.has("locationId")) {
      return
    }

    // Fetch the locationId from URL search parameters
    const locationId = searchParams.get("locationId") ?? ""
    const locationName = searchParams.get("locationName")
    const businessId = searchParams.get("businessId")

    // Determine the selected value based on the presence of locationId
    const newSelectedValue =
      locationId === ""
        ? allLocationsOption
        : {
            name: locationName,
            id: locationId,
            businessId,
          }

    setLocation(newSelectedValue.id)
    setSelectedValue(newSelectedValue)
    setFirstSelectedValue(newSelectedValue)
    setReportDateRange([reportDateRange[0], reportDateRange[1]])
    setHeaderDateRange([reportDateRange[0], reportDateRange[1]])
    getReportsHandler(
      fetchReport,
      setIsFetchingReport,
      handleSnackBar,
      setReport,
      searchParams.get("locationId"),
      hasHeaderDate,
      requiresAPICall,
      setShowHeaderDate,
      "data.data.businesses",
      {
        businessId: newSelectedValue.businessId,
        locationIds: "",
      },
      isMountedRef
    ).catch((error) => {
      console.log(error)
    })
  }, [])

  useEffect(() => {
    const handleOrientationChange = () => {
      // We'll close the form on any orientation change if there's content in flattenedReport.
      if (flattenedReport.length > 0) {
        setShowForm(false)
      }
    }

    window.addEventListener("orientationchange", handleOrientationChange)

    // Cleanup the event listener when the component unmounts or the flattenedReport changes.
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange)
    }
  }, [flattenedReport.length])

  useEffect(() => {
    if (isMobile && flattenedReport.length !== 0) {
      toggleForm(false)
    }
  }, [report, isMobile, flattenedReport.length, toggleForm])
  const productFilters = useSelector((state: RootState) => state.filters.productFilters)

  const filterLocations = (locations: OutputLocation[]): OutputLocation[] => {
    const noFiltersSelected = Object.values(productFilters).every((value) => !value)

    if (noFiltersSelected) {
      return locations
    }

    return locations.filter((location) => {
      const productFilterKey = Object.keys(productFilters).find(
        (key) => key.toLowerCase() === location.productType.toLowerCase()
      )

      let productFilterValue = true
      if (productFilterKey !== undefined) {
        productFilterValue = productFilters[productFilterKey]
      }

      return productFilterValue
    })
  }

  const filterBusinesses = (businesses: OutputBusiness[]): OutputBusiness[] => {
    let filteredBusinesses = businesses
      .map((business) => ({
        ...business,
        locations: filterLocations(business.locations),
      }))
      .filter((business) => business.locations.length > 0)

    filteredBusinesses = filteredBusinesses.map((business) => {
      const summary = business.locations.reduce(
        (prev, location) => {
          return {
            totalLocationRecycler1Count:
              prev.totalLocationRecycler1Count + location.totalLocationRecycler1Count,
            totalLocationRecycler2Count:
              prev.totalLocationRecycler2Count + location.totalLocationRecycler2Count,
            totalLocationStackerCount:
              prev.totalLocationStackerCount + location.totalLocationStackerCount,
            totalLocationStackerValue:
              prev.totalLocationStackerValue + location.totalLocationStackerValue,
          }
        },
        {
          totalLocationRecycler1Count: 0,
          totalLocationRecycler2Count: 0,
          totalLocationStackerCount: 0,
          totalLocationStackerValue: 0,
        }
      )

      business.totalBusinessRecycler1Count = summary.totalLocationRecycler1Count
      business.totalBusinessRecycler2Count = summary.totalLocationRecycler2Count
      business.totalBusinessStackerCount = summary.totalLocationStackerCount
      business.totalBusinessStackerValue = summary.totalLocationStackerValue

      return business
    })

    return filteredBusinesses
  }

  const filteredReport = filterBusinesses(flattenedReport)
  const hasBusinesses = filteredReport.length > 0

  const hasDeviceStatuses = filteredReport.some((business) =>
    business.locations.some((location) =>
      location.terminals.some(
        (terminal) =>
          terminal.deviceStatuses !== null &&
          terminal.deviceStatuses !== undefined &&
          Object.keys(terminal.deviceStatuses).length > 0
      )
    )
  )

  const exportFlattenedDetailedData =
    Array.isArray(filteredReport) && filteredReport.length > 0
      ? flattenExport(filteredReport, currentCashDetailedConfig)
      : []
  const exportFlattenedSummaryData =
    Array.isArray(filteredReport) && filteredReport.length > 0
      ? flattenExport(filteredReport, currentCashSummaryConfig)
      : []
  return (
    <ReportLayout
      hasExport={true}
      exportFlattenedDetailedData={exportFlattenedDetailedData}
      exportFlattenedSummaryData={exportFlattenedSummaryData}
      reportName={"Current Cash"}
      calendarInput={CalendarInputType.NoDate}
      locationInput={LocationInputType.SelectGetLoad}
      showHeaderDate={showHeaderDate}
      headerDateRange={headerDateRange}
      showForm={showForm}
      accordionHeadings={["Name", "RC1", "RC2", "STK", "AMNT"]}
      showAccordionHeadings={hasDeviceStatuses}
      toggleForm={toggleForm}
      handleLocationChange={handleLocationChange}
      selectedValue={selectedValue}
      handleSetSelectedValue={handleSetSelectedValue}
      setReportDateRange={setReportDateRange}
      openFiltersHandler={props.openFiltersHandler}
      location={location}
      getReportHandler={async () => {
        if (selectedValue.id === null) {
          // Create a new URLSearchParams object with the current search params
          const newSearchParams = new URLSearchParams(searchParams.toString())

          // Delete the parameters you want to clear
          newSearchParams.set("locationId", "")
          newSearchParams.delete("businessId")
          newSearchParams.delete("locationName")

          // Use the 'navigate' method with the 'replace' option
          navigate(`?${newSearchParams.toString()}`, { replace: true })
        } else if (selectedValue.id !== null && selectedValue.id !== undefined) {
          // Create a new URLSearchParams object with the current search params
          const newSearchParams = new URLSearchParams(searchParams.toString())

          // Set the search params as you had before
          newSearchParams.set("locationId", selectedValue.id)
          newSearchParams.set("businessId", selectedValue.businessId ?? "")
          newSearchParams.set("locationName", selectedValue.name)

          // Use the 'navigate' method with the 'replace' option
          navigate(`?${newSearchParams.toString()}`, { replace: true })
        }
        try {
          await getReportsHandler(
            fetchReport,
            setIsFetchingReport,
            handleSnackBar,
            setReport,
            location,
            hasHeaderDate,
            requiresAPICall,
            setShowHeaderDate,
            "data.data.businesses",
            {
              businessId: selectedValue.businessId,
              locationIds: "",
            },
            isMountedRef
          )

          setHeaderDateRange([reportDateRange[0], reportDateRange[1]])
          setFirstSelectedValue(selectedValue)
        } catch (error) {
          console.log(error)
        }
      }}
    >
      <>
        {isFetchingReport && <ReportSkeleton />}
        {!isFetchingReport && flattenedReport.length === 0 && snackbarOpen && (
          <Alert severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        )}
        {!isFetchingReport && flattenedReport.length > 0 && (
          <Report
            hasBusinesses={hasBusinesses}
            hasDeviceStatuses={hasDeviceStatuses}
            report={filteredReport}
          />
        )}
      </>
    </ReportLayout>
  )
}
