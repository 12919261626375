/**
 * Safely retrieves data from local storage.
 *
 * This function handles cases where local storage is not available,
 * the key does not exist, or the stored data is not in JSON format.
 *
 * @param {string} key - The key under which the data is stored in local storage.
 * @returns {string | null} The data from local storage as a string, or null if any error occurs.
 *
 * @example
 * const userName = safeGetFromLocalStorage('user');
 * if (userName) {
 *   console.log(userName);
 * }
 */
export const safeGetFromLocalStorage = (key: string): string | null => {
  if (typeof window === "undefined" || !("localStorage" in window)) {
    console.warn("Local storage is not available.")
    return null
  }

  try {
    return localStorage.getItem(key)
  } catch (error) {
    console.error("Error reading from local storage:", error)
    return null
  }
}

/**
 * Safely stores data in local storage.
 *
 * This function handles cases where local storage is not available
 * or where an error occurs during the stringification of the data.
 *
 * @param {string} key - The key under which to store the data.
 * @param {string} value - The data to be stored as a string.
 *
 * @example
 * const userName = 'John Doe';
 * safeSetToLocalStorage('user', userName);
 */
export const safeSetToLocalStorage = (key: string, value: string): void => {
  if (typeof window === "undefined" || !("localStorage" in window)) {
    console.warn("Local storage is not available.")
    return
  }

  try {
    localStorage.setItem(key, value)
  } catch (error) {
    console.error("Error writing to local storage:", error)
  }
}
