// useDecodeRequest.js
import { useCallback, useState } from "react"
import {
  useGetAddItemToCartMutation,
  useGetDecodeCodeMutation,
} from "../redux/business/business.api"
import { type CartItem } from "../types/cartItemTypes"

// Correct the type of addMachine to be a function that takes a Machine object as an argument
const useDecodeRequest = (
  addMachine: (machines: CartItem[], arg: boolean) => void,
  refetchCartItemData: () => void
) => {
  const [decodeCode] = useGetDecodeCodeMutation({})
  const [addItemToCart] = useGetAddItemToCartMutation({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [showCodeInput, setShowCodeInput] = useState(false)
  const [newMachineCode, setNewMachineCode] = useState("")
  const [codeParam, setCodeParam] = useState<string | null>(null)

  const clearUrlParam = () => {
    const url = new URL(window.location.href)
    url.searchParams.delete("c")
    window.history.pushState({}, "", url.href)
  }

  const handleScannedData = useCallback((data: string) => {
    setNewMachineCode(data)
  }, [])

  const handleDecodeRequest = async (newMachineCode: string, code?: string) => {
    setIsSubmitting(true)
    try {
      const codeToDecode = code ?? newMachineCode
      if (codeToDecode.trim() === "") return

      const response = await decodeCode({ fillRequestCode: codeToDecode }).unwrap()

      if (response.rc === 1000) {
        const { decodeData } = response.data
        if (decodeData !== null && decodeData !== undefined) {
          const newMachine: CartItem = {
            machineId: decodeData.machineId,
          }

          const addItemRes = await addItemToCart({
            machineId: newMachine.machineId,
            requestCode: codeToDecode,
          }).unwrap()
          refetchCartItemData()
          if (addItemRes.rc === 1000) {
            newMachine.cartItemId = addItemRes.data.cartItemId
            addMachine([newMachine], false)
          }
        } else {
          console.error("Invalid response data:", response)
        }
      }
      setNewMachineCode("")
      setShowCodeInput(false)
    } catch (err) {
      console.log(err)
    } finally {
      setIsSubmitting(false)
      sessionStorage.removeItem("cParam")
      clearUrlParam()
    }
  }

  return {
    handleDecodeRequest,
    isSubmitting,
    showCodeInput,
    setShowCodeInput,
    newMachineCode,
    setNewMachineCode,
    codeParam,
    setCodeParam,
    handleScannedData,
  }
}

export default useDecodeRequest
