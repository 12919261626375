import React from "react"
import { LinearProgress } from "@mui/material"
import { usePasswordStrength } from "../hooks/usePasswordStrength"

interface PasswordStrengthIndicatorProps {
  password: string
}

export const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ({
  password,
}) => {
  const { calculatePasswordStrength, strengthColor } = usePasswordStrength(password)

  return (
    <LinearProgress
      variant="determinate"
      value={(calculatePasswordStrength / 5) * 100}
      sx={{
        height: 10,
        borderRadius: 5,
        backgroundColor: password !== "" ? "#e0e0df" : "transparent",
        "& .MuiLinearProgress-bar": {
          backgroundColor: strengthColor,
        },
      }}
    />
  )
}
