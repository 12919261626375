import React, { useState, memo } from "react"
import {
  Accordion,
  AccordionDetails,
  SummarySection,
  SummaryItem,
  AccordionSummary,
} from "../../../styles/AccordionStyles"
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded"
import { SalesTable } from "./SalesTable"
import { formatAmount, formatNegative, formatNegativePercent } from "../../../utils/formatAmount"
import { TablePaginationItem } from "../../TablePaginationItem"
import { useLocation } from "react-router-dom"
import { Truncate } from "../../Truncate"
import type { SalesStore, Summary } from "../../../types/ISalesReportTypes"

interface AccordionItemProps {
  isSingleBusiness: boolean
  businessId: string
  name: string
  stores: SalesStore[]
  totalSummary: Summary
}

const AccordionItemComponent = ({
  businessId,
  name,
  stores,
  totalSummary,
  isSingleBusiness,
}: AccordionItemProps) => {
  console.log("stores in Sales: ", stores)
  // Get the selected location ID from the URL
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const selectedLocationId = urlParams.get("locationId")

  const [rowsPerPage, setRowsPerPage] = useState(5)

  const getPageIndexOfSelectedStore = (selectedStoreId: string | null) => {
    if (selectedStoreId === null || selectedStoreId === "") return 0

    const selectedIndex = stores.findIndex(
      (store: SalesStore) =>
        store?.store?.id !== undefined && store?.store?.id?.toString() === selectedStoreId
    )
    return selectedIndex >= 0 ? Math.floor(selectedIndex / rowsPerPage) : 0
  }

  const [page, setPage] = useState(getPageIndexOfSelectedStore(selectedLocationId))

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const [expandedBusiness, setExpandedBusiness] = useState<string | false>(
    isSingleBusiness ? businessId : false
  )

  const handleBusinessChange =
    (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpandedBusiness(isExpanded ? panel : false)
    }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getNetColor = (amount: number) => {
    return amount < 0 ? "red" : "inherit"
  }
  const visibleStores = stores.slice(page * rowsPerPage, (page + 1) * rowsPerPage)

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - stores.length) : 0

  return (
    <Accordion
      expanded={expandedBusiness === businessId}
      onChange={handleBusinessChange(businessId)}
    >
      <AccordionSummary
        sx={{
          backgroundColor: "#DEE2E6",
        }}
        expandIcon={<NavigateNextRoundedIcon />}
      >
        <SummarySection sx={{ flexBasis: "100%", minWidth: "280px" }}>
          <SummaryItem
            sx={{
              textAlign: "left",
              minWidth: "56px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              flexBasis: "20%",
            }}
          >
            <Truncate input={name} />
          </SummaryItem>
          <SummaryItem
            sx={{
              textAlign: "right",
              minWidth: "56px",
              flexBasis: "20%",
            }}
          >
            <span className="hidden sm:block">{formatAmount(totalSummary.s).full}</span>
            <span className="block sm:hidden">{formatAmount(totalSummary.s).short}</span>
          </SummaryItem>

          <SummaryItem
            sx={{
              textAlign: "right",
              minWidth: "56px",
              flexBasis: "20%",
            }}
          >
            {visibleStores.some((storeData) => storeData.store.isSweeps) ? (
              <>
                <span className="hidden sm:block">{formatAmount(totalSummary.f).full}</span>
                <span className="block sm:hidden">{formatAmount(totalSummary.f).short}</span>
              </>
            ) : (
              "-"
            )}
          </SummaryItem>

          <SummaryItem
            sx={{
              textAlign: "right",
              minWidth: "56px",
              flexBasis: "20%",
              color: getNetColor(totalSummary.p),
            }}
          >
            <span className="hidden sm:block">{formatNegative(totalSummary.p).full}</span>
            <span className="block sm:hidden">{formatNegative(totalSummary.p).short}</span>
          </SummaryItem>

          <SummaryItem
            sx={{
              display: { xs: "none", sm: "block" },
              textAlign: "right",
              minWidth: "56px",
              flexBasis: "20%",
              color: getNetColor(totalSummary.percentage),
            }}
          >
            {formatNegativePercent(totalSummary.percentage)}
          </SummaryItem>
        </SummarySection>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionDetails>
          {/* Table of transactions */}
          <SalesTable stores={visibleStores} name={name} emptyRows={emptyRows} />

          <TablePaginationItem
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={stores.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </AccordionDetails>
      </AccordionDetails>
    </Accordion>
  )
}

export const AccordionSalesItem = memo(AccordionItemComponent)
