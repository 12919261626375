import React from "react"
import { AccordionCashItem } from "./AccordionCashItem"
import { Alert } from "@mui/material"
import { type Business } from "../../../types/ICashAdjustments"

interface Props {
  report: Business[]
  hasBusinesses: boolean
  hasTransactions: boolean
  dontShowBusinesses: boolean
}

export function Report({ report, hasBusinesses, hasTransactions, dontShowBusinesses }: Props) {
  const collapseAllBusinesses = report.length === 1
  if (!hasBusinesses || !hasTransactions || dontShowBusinesses) {
    return (
      <Alert severity="error" sx={{ width: "100%" }}>
        No data to show with the current selection.
      </Alert>
    )
  }

  return (
    <div className="shadow-lg">
      {report.map(
        (
          {
            businessId,
            businessName,
            totalBusinessAdd,
            totalBusinessRemove,
            totalBusinessStack,
            locations,
          },
          index
        ) => {
          return (
            <React.Fragment key={index}>
              <AccordionCashItem
                collapseAllBusinesses={collapseAllBusinesses}
                businessId={businessId}
                businessName={businessName}
                totalBusinessAdd={totalBusinessAdd}
                totalBusinessRemove={totalBusinessRemove}
                totalBusinessStack={totalBusinessStack}
                locations={locations}
              />
            </React.Fragment>
          )
        }
      )}
    </div>
  )
}
