import { createSlice } from "@reduxjs/toolkit"

interface InactivityToastState {
  message: string
  isVisible: boolean
}

const initialState: InactivityToastState = {
  message: "",
  isVisible: false,
}

export const inactivityToastSlice = createSlice({
  name: "inactivityToast",
  initialState,
  reducers: {
    showInactivityToast: (state, action) => {
      state.message = action.payload
      state.isVisible = true
    },
    hideInactivityToast: (state) => {
      state.isVisible = false
      state.message = ""
    },
  },
})

export const { showInactivityToast, hideInactivityToast } = inactivityToastSlice.actions
export const inactivityToastReducer = inactivityToastSlice.reducer
