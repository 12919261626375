import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { userApi } from "./user/user.api"
import { userReducer } from "./user/user.slice"

import { businessApi } from "./business/business.api"
import { filtersReducer } from "./filters/filters.slice"
import { buttonsReducer } from "./buttons/expandAll.slice"
import { fullscreenReducer } from "./buttons/fullscreenSlice"
import { dataReducer } from "./business/business.slice"
import { hideHeaderReducer } from "./filters/hideHeader.slice"
import { inactivityToastReducer } from "./toastSlice"
export const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [businessApi.reducerPath]: businessApi.reducer,
  user: userReducer,
  filters: filtersReducer,
  buttons: buttonsReducer,
  fullscreen: fullscreenReducer,
  dataSlice: dataReducer,
  hideReportHeader: hideHeaderReducer,
  inactivityToast: inactivityToastReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userApi.middleware, businessApi.middleware),
})

export type RootState = ReturnType<typeof rootReducer>
