import { type BaseQueryApi, type FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { handleError, showToastForResponseCode } from "../../utils/errorHandler"
import type { ApiErrorResponse } from "../../types/errorTypes"

// this function we need to catch the response and handle the error and status codes
const baseQueryWithRejection = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: Record<string, unknown>
) => {
  const baseQuery = fetchBaseQuery({ baseUrl: process.env.REACT_APP_MAIN_URL })
  const result = await baseQuery(args, api, extraOptions)

  const url = typeof args === "string" ? args : args.url

  // Check if result.data exists and has the expected properties
  if (
    result.data !== undefined &&
    result.data !== null &&
    typeof result.data === "object" &&
    "rc" in result.data
  ) {
    const data = result.data as ApiErrorResponse
    if (data.rc !== undefined) {
      showToastForResponseCode(data.rc, data.message)
    }
  } else if (
    result.error !== null &&
    result.error !== undefined &&
    url !== "/api/User/VerifyToken"
  ) {
    console.log("Global error handler:", result.error)
    handleError(result.error)
  }

  return result
}

export default baseQueryWithRejection
