import { IconButton } from "@mui/material"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import { useEffect, useState } from "react"
import { LicensingInfoDialog } from "./Licensing/LicensingInfoDialog"

interface LicensingHeaderProps {
  title: string
}

export function LicensingHeader({ title }: LicensingHeaderProps) {
  const [openDialog, setOpenDialog] = useState(false)
  const [dontShowAgain, setDontShowAgain] = useState(false)

  useEffect(() => {
    const showAgain = localStorage.getItem("dontShowInfoDialog")
    if (showAgain === "false") {
      setDontShowAgain(true)
    } else {
      setOpenDialog(true)
    }
  }, [])

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    localStorage.setItem("dontShowInfoDialog", dontShowAgain ? "false" : "true")
  }

  const handleDontShowAgainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    setDontShowAgain(checked)
    if (!checked) {
      localStorage.setItem("dontShowInfoDialog", "true")
    }
  }

  const dialogContent =
    location.pathname === "/licensing/purchase-fills" ? (
      <div className="text-black text-left">
        <h4 className="pb-2">
          1. Access your Terminal&apos;s <span className="font-bold">Maintenance Menu</span>.
        </h4>
        <h4 className="py-2">
          2. Select <span className="font-bold">Terminal Diagnostics</span>.
        </h4>
        <h4 className="py-2">
          3. Press the <span className="font-bold">UPDATE</span> button in the{" "}
          <span className="font-bold">License</span> section.
        </h4>
        <h4 className="py-2">
          4. The Terminal&apos;s <span className="font-bold">Fill Request Code</span> is displayed
          on screen as both a QR code and a 40-character code.
        </h4>
      </div>
    ) : (
      <div className="text-black text-left">
        <h4 className="pb-2">
          1. Access your Terminal&apos;s <span className="font-bold">Maintenance Menu</span>.
        </h4>
        <h4 className="py-2">
          2. Select <span className="font-bold">Terminal Diagnostics</span>.
        </h4>
        <h4 className="py-2">
          3. Press the <span className="font-bold">UPDATE</span> button in the{" "}
          <span className="font-bold">License</span> section.
        </h4>
        <h4 className="py-2">
          4. Press the <span className="font-bold">ENTER FILL CODE</span> button.
        </h4>
        <h4 className="py-2">
          5. Type your <span className="font-bold">Fill Code</span> using the on-screen keyboard
          then press the <span className="font-bold">APPLY</span> button.
        </h4>
      </div>
    )

  const dialogTitle =
    location.pathname === "/licensing/purchase-fills"
      ? "How do I get my terminal's Fill Request Code?"
      : "How do I use my terminal's Fill Codes?"

  return (
    <div className="w-full sm:max-w-4xl mx-auto">
      <div className="flex justify-between items-center py-2 px-4 bg-[#14213D]">
        <div className="text-center flex-grow">
          <h1 className="text-white  font-bold text-2xl">{title}</h1>
        </div>
        <div className="absolute top-0 right-[14px] md:right-[17px] bottom-0 flex items-center h-[48px] overflow-hidden">
          <IconButton onClick={handleOpenDialog} sx={{ color: "#fff", padding: 0 }}>
            <HelpOutlineOutlinedIcon />
          </IconButton>
        </div>
      </div>
      <LicensingInfoDialog
        title={dialogTitle}
        content={dialogContent}
        dialogOpen={openDialog}
        onClose={handleCloseDialog}
        dontShowAgain={dontShowAgain}
        handleDontShowAgainChange={handleDontShowAgainChange}
      />
    </div>
  )
}
