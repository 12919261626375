import React from "react"
import { AccordionCashItem } from "./AccordionCurrentItem"
import { Alert } from "@mui/material"
import { type OutputBusiness } from "../../../types/ICurrent"

interface Props {
  report: OutputBusiness[]
  hasDeviceStatuses: boolean
  hasBusinesses: boolean
}

export function Report({ report, hasBusinesses, hasDeviceStatuses }: Props) {
  const collapseAllBusinesses = report.length === 1

  if (!hasDeviceStatuses || !hasBusinesses) {
    return (
      <Alert severity="error" sx={{ width: "100%" }}>
        No data to show with the current selection!
      </Alert>
    )
  }

  return (
    <div className="shadow-lg">
      {report.map(
        (
          {
            businessId,
            businessName,
            totalBusinessRecycler1Count,
            totalBusinessRecycler1Value,
            totalBusinessRecycler2Count,
            totalBusinessRecycler2Value,
            totalBusinessStackerCount,
            totalBusinessStackerValue,
            locations,
          },
          index
        ) => {
          return (
            <React.Fragment key={index}>
              <AccordionCashItem
                collapseAllBusinesses={collapseAllBusinesses}
                business={{
                  businessId,
                  businessName,
                  totalBusinessRecycler1Count,
                  totalBusinessRecycler1Value,
                  totalBusinessRecycler2Count,
                  totalBusinessRecycler2Value,
                  totalBusinessStackerCount,
                  totalBusinessStackerValue,
                  locations,
                }}
              />
            </React.Fragment>
          )
        }
      )}
    </div>
  )
}
