import { useState, useMemo, useEffect } from "react"

const passwordCriteria = [
  { label: "Add at least one number", regex: /[0-9]/ },
  { label: "Add at least one uppercase letter", regex: /[A-Z]/ },
  { label: "Add at least one lowercase letter", regex: /[a-z]/ },
  {
    label: "Add at least one special character (e.g., !, @, #, $, %, ^, &, *, (, ))",
    regex: /[!@#$%^&*()]/,
  },
  { label: "Password must be at least 6 characters long", regex: /.{6,}/ },
]

export const usePasswordStrength = (password: string) => {
  const [missingCriteria, setMissingCriteria] = useState<string[]>([])

  const calculatePasswordStrength = useMemo(() => {
    const conditions = [
      password.trim().length >= 6,
      /[A-Z]/.test(password),
      /[a-z]/.test(password),
      /[0-9]/.test(password),
      /[!@#$%^&*(),.?":{}|<>]/.test(password),
    ]

    return conditions.reduce((acc, condition) => acc + (condition ? 1 : 0), 0)
  }, [password])

  const strengthColor = useMemo(() => {
    switch (calculatePasswordStrength) {
      case 1:
        return "red"
      case 2:
        return "salmon"
      case 3:
        return "orange"
      case 4:
        return "lightgreen"
      case 5:
        return "green"
      default:
        return "transparent"
    }
  }, [calculatePasswordStrength])

  useEffect(() => {
    const unmetCriteria = passwordCriteria
      .filter((criteria) => !criteria.regex.test(password))
      .map((criteria) => criteria.label)
    setMissingCriteria(unmetCriteria)
  }, [password])

  return {
    calculatePasswordStrength,
    strengthColor,
    missingCriteria,
  }
}
