import { Tooltip } from "@mui/material"

interface TruncateProps {
  input: string
}

export const Truncate: React.FC<TruncateProps> = ({ input }: TruncateProps) => (
  <Tooltip
    placement="top-start"
    title={input}
    PopperProps={{
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, -7, 0, 0],
          },
        },
      ],
    }}
  >
    <span>{input.length > 25 ? `${input.substring(0, 25)}...` : input}</span>
  </Tooltip>
)
