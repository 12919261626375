import { Outlet, useNavigate } from "react-router-dom"

export function AuthLayout() {
  const navigate = useNavigate()

  const token = localStorage.getItem("token")

  if (token === null || token === undefined || token === "") {
    navigate("/login")
    return null
  }

  return <Outlet />
}
