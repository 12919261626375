import React, { useState, memo } from "react"
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded"
import {
  SummarySection,
  SummaryItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../../styles/AccordionStyles"
import { formatAmount } from "../../../utils/formatAmount"
import { TerminalsTable } from "./TerminalsTable"
import { TablePaginationItem } from "../../TablePaginationItem"
import { useLocation } from "react-router-dom"
import { Truncate } from "../../Truncate"

export interface Terminal {
  terminalName: string
  recycler1Count: number
  recycler1Value: number
  recycler2Count: number
  recycler2Value: number
  stackerCount: number
  stackerValue: number
  cashStatusDateTime: string
}

interface Location {
  locationId: string
  softwareName: string
  totalLocationRecycler1Count: number
  totalLocationRecycler1Value: number
  totalLocationRecycler2Count: number
  totalLocationRecycler2Value: number
  totalLocationStackerCount: number
  totalLocationStackerValue: number
  terminals: Terminal[]
}

interface Business {
  businessId: string
  businessName: string
  totalBusinessRecycler1Count: number
  totalBusinessRecycler1Value: number
  totalBusinessRecycler2Count: number
  totalBusinessRecycler2Value: number
  totalBusinessStackerCount: number
  totalBusinessStackerValue: number
  locations: Location[]
}

interface Props {
  business: Business
  collapseAllBusinesses: boolean
}

const AccordionItemComponent = ({ business, collapseAllBusinesses }: Props) => {
  const [expandedBusiness, setExpandedBusiness] = useState<string | false>(
    collapseAllBusinesses ? business.businessId : false
  )
  const [expandedLocation, setExpandedLocation] = useState<string | false>(false)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [page, setPage] = useState(0)

  // Get the selected location ID from the URL
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const selectedLocationId = urlParams.get("locationId")

  const handleBusinessChange =
    (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpandedBusiness(isExpanded ? panel : false)
    }

  const handleLocationChange =
    (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpandedLocation(isExpanded ? panel : false)
      setPage(0)
      setRowsPerPage(5)
    }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  return (
    <Accordion
      expanded={expandedBusiness === business.businessId}
      onChange={handleBusinessChange(business.businessId)}
      TransitionProps={{ timeout: 10 }}
    >
      {/* waterfall effect in accordion, push summary 12px to the left - default 16px */}
      <AccordionSummary
        sx={{
          backgroundColor: "#DEE2E6",
          borderTop: 2,
          borderTopColor: "darkgrey",
          paddingLeft: "4px",
        }}
        expandIcon={<NavigateNextRoundedIcon />}
      >
        {/* compensate the 12px move to left in the summary items to keep columns aligned */}
        <SummarySection sx={{ flexBasis: "100%", paddingLeft: "12px" }}>
          <SummaryItem
            sx={{
              textAlign: "left",
              minWidth: "56px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              flexBasis: "20%",
            }}
          >
            <Truncate input={business.businessName} />
          </SummaryItem>
          <SummaryItem
            sx={{
              textAlign: "right",
              minWidth: "56px",
              flexBasis: "20%",
            }}
          >
            {business.totalBusinessRecycler1Count}
          </SummaryItem>
          <SummaryItem
            sx={{
              textAlign: "right",
              minWidth: "56px",
              flexBasis: "20%",
            }}
          >
            {business.totalBusinessRecycler2Count}
          </SummaryItem>
          <SummaryItem
            sx={{
              textAlign: "right",
              minWidth: "56px",
              flexBasis: "20%",
            }}
          >
            {business.totalBusinessStackerCount}
          </SummaryItem>
          <SummaryItem
            sx={{
              textAlign: "right",
              minWidth: "56px",
              flexBasis: "20%",
            }}
          >
            <span className="hidden sm:block">
              {formatAmount(business.totalBusinessStackerValue).full}
            </span>
            <span className="block sm:hidden">
              {formatAmount(business.totalBusinessStackerValue).short}
            </span>
          </SummaryItem>
        </SummarySection>
      </AccordionSummary>
      <AccordionDetails>
        {business.locations.map((location) => {
          const visibleTerminals = location.terminals.slice(
            page * rowsPerPage,
            (page + 1) * rowsPerPage
          )
          const emptyRows =
            page > 0 ? Math.max(0, (1 + page) * rowsPerPage - location.terminals.length) : 0

          const isSelected = location.locationId.toString() === selectedLocationId

          return (
            <Accordion
              expanded={expandedLocation === location.locationId}
              onChange={handleLocationChange(location.locationId)}
              key={location.locationId}
              TransitionProps={{ timeout: 10 }}
            >
              {/* waterfall effect in accordion, push summary 6px to the left - default 16px */}
              <AccordionSummary
                sx={{
                  backgroundColor: isSelected ? "#FFF6E8" : "#E9ECEF",
                  paddingLeft: "10px",
                }}
                expandIcon={<NavigateNextRoundedIcon />}
              >
                {/* compensate the 6px move to left in the summary items to keep columns aligned */}
                <SummarySection sx={{ flexBasis: "100%", paddingLeft: "6px" }}>
                  <SummaryItem
                    sx={{
                      textAlign: "left",
                      minWidth: "56px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      flexBasis: "20%",
                    }}
                  >
                    {location.locationId} {location.softwareName}
                  </SummaryItem>
                  <SummaryItem
                    sx={{
                      textAlign: "right",
                      minWidth: "56px",
                      flexBasis: "20%",
                    }}
                  >
                    {location.totalLocationRecycler1Count}
                  </SummaryItem>
                  <SummaryItem
                    sx={{
                      textAlign: "right",
                      minWidth: "56px",
                      flexBasis: "20%",
                    }}
                  >
                    {location.totalLocationRecycler2Count}
                  </SummaryItem>
                  <SummaryItem
                    sx={{
                      textAlign: "right",
                      minWidth: "56px",
                      flexBasis: "20%",
                    }}
                  >
                    {location.totalLocationStackerCount}
                  </SummaryItem>
                  <SummaryItem
                    sx={{
                      textAlign: "right",
                      minWidth: "56px",
                      flexBasis: "20%",
                    }}
                  >
                    <span className="hidden sm:block">
                      {formatAmount(location.totalLocationStackerValue).full}
                    </span>
                    <span className="block sm:hidden">
                      {formatAmount(location.totalLocationStackerValue).short}
                    </span>
                  </SummaryItem>
                </SummarySection>
              </AccordionSummary>
              <AccordionDetails>
                <AccordionDetails>
                  <TerminalsTable terminals={visibleTerminals} emptyRows={emptyRows} />
                  <TablePaginationItem
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={location.terminals.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </AccordionDetails>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </AccordionDetails>
    </Accordion>
  )
}

export const AccordionCashItem = memo(AccordionItemComponent)
