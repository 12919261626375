import React, { useRef, useState } from "react"
import ZoomInIcon from "@mui/icons-material/ZoomIn"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import type { MachineData, PurchaseDetailsProps } from "../../../types/paymentStatusTypes"
import { useNavigate } from "react-router-dom"
import ReactToPrint from "react-to-print"
import toast from "react-hot-toast"
import { formatAmount } from "../../../utils/formatAmount"
import { formatDate } from "../../../utils/formatDate"
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import PrintIcon from "@mui/icons-material/Print"
import { formatFillCode } from "../../../utils/formatFillCode"
import { ShareButton } from "../../ShareButton"
import type { PrintDataTypes } from "../../../types/IPrint"
import Print from "../Print"
import { CopyButton } from "../../CopyButton"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

export function PurchaseDetails({
  transactionId,
  purchaseDate,
  purchaseTotal,
  machineData,
}: PurchaseDetailsProps) {
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"))
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedMachine, setSelectedMachine] = useState<MachineData | null>(null)
  const navigate = useNavigate()
  const [printData, setPrintData] = useState<PrintDataTypes>()
  const printComponentRef = useRef<HTMLElement>(null)

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleOkClick = () => {
    toast.dismiss()
    toast.success("Transaction completed successfully.")
    navigate("/licensing")
  }

  const getTransactionShareText = () => {
    const parts = [
      `Transaction Completed`,
      `\n`,
      `Transaction ID: ${String(transactionId)}`,
      `\n`,
      `Purchase Date: ${String(formatDate(purchaseDate))}`,
      `\n`,
      `Purchase Total: ${String(formatAmount(+purchaseTotal).full)}`,
      `\n`,
    ]

    const machineParts = machineData
      .flatMap((machine) => [
        `Machine ID: ${String(machine.machineId)}`,
        `\n`,
        `Fill Code: ${formatFillCode(machine.fillCode)}`,
        `\n`,
        `Fill Count: ${String(machine.fillCount)}`,
        `\n`,
        `Amount: ${formatAmount(machine.amount).full}`,
        `\n`,
        `Price: ${formatAmount(machine.price).full}`,
        ``,
      ])
      .flat()

    return parts.concat(machineParts).join("")
  }

  const prepareDataForPrint = (): PrintDataTypes => {
    const transactionData = [
      {
        key: `transaction-completed`,
        label: "Transaction Completed",
        value: "",
      },
      {
        key: `transactionId-${String(transactionId)}`,
        label: "Transaction ID",
        value: transactionId,
      },
      {
        key: `purchaseDate-${String(transactionId)}`,
        label: "Purchase Date",
        value: formatDate(purchaseDate),
      },
      {
        key: `purchaseTotal-${String(transactionId)}`,
        label: "Purchase Total",
        value: formatAmount(+purchaseTotal).full,
      },
      {
        key: `divider`,
        label: "",
        value: "",
      },
    ]

    const machinePrintData = machineData.flatMap((machine) => [
      {
        key: `machineId-${String(machine.machineId)}`,
        label: "Machine ID",
        value: machine.machineId,
      },
      {
        key: `fillCode-${String(machine.machineId)}`,
        label: "Fill Code",
        value: formatFillCode(machine.fillCode),
      },
      {
        key: `fillCount-${String(machine.machineId)}`,
        label: "Fill Count",
        value: String(machine.fillCount),
      },
      {
        key: `amount-${String(machine.amount)}`,
        label: "Amount",
        value: formatAmount(machine.amount).full,
      },
      {
        key: `price-${String(machine.machineId)}`,
        label: "Price",
        value: formatAmount(machine.price).full,
      },
      {
        key: `divider`,
        label: "",
        value: "",
      },
    ])
    return [...transactionData, ...machinePrintData]
  }

  return (
    <div className="bg-white mt-10">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          minWidth: "100%",
          padding: "16px",
        }}
      >
        <Box position="absolute" top="-16px" zIndex="1">
          <CheckCircleIcon
            style={{
              fontSize: "64px",
              color: "#4CAF50",
              backgroundColor: "#e9f5e9",
              borderRadius: "50%",
              padding: "16px",
            }}
          />
        </Box>
        <Card
          variant="outlined"
          sx={{ minWidth: "100%", borderRadius: "10px", borderColor: "rgba(20, 33, 61, 0.31)" }}
        >
          <CardContent sx={{ minWidth: "100%", paddingX: 2, marginTop: "32px" }}>
            <Typography variant="h5" align="center">
              Transaction Complete
            </Typography>
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
              color="textSecondary"
            >
              Thank you for your purchase
            </Typography>
            <hr
              style={{
                border: "none",
                borderTop: "2px dashed rgba(20, 33, 61, 0.21)",
                margin: "8px 0px",
              }}
            />
            <Grid
              sx={{
                display: "grid",
                gridDirection: "row",
                paddingTop: "8px",
              }}
              container
            >
              <Grid
                sx={{
                  display: "grid",
                  gridDirection: "column",
                }}
                container
                gridTemplateColumns={"1fr 1fr"}
              >
                <Grid
                  sx={{
                    display: "grid",
                    justifyItems: "start",
                  }}
                >
                  Transaction ID:
                </Grid>
                <Grid
                  sx={{
                    display: "grid",
                    justifyItems: "end",
                  }}
                >
                  <Typography align="right" fontWeight={"550"}>
                    {transactionId}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: "grid",
                  gridDirection: "column",
                }}
                container
                gridTemplateColumns={"1fr 1fr"}
              >
                <Grid
                  sx={{
                    display: "grid",
                    justifyItems: "start",
                  }}
                >
                  Purchase Date:
                </Grid>
                <Grid
                  sx={{
                    display: "grid",
                    justifyItems: "end",
                  }}
                >
                  <Typography align="right" fontWeight={"550"}>
                    {formatDate(purchaseDate)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: "grid",
                  gridDirection: "column",
                }}
                container
                gridTemplateColumns={"1fr 1fr"}
              >
                <Grid
                  sx={{
                    display: "grid",
                    justifyItems: "start",
                  }}
                >
                  {" "}
                  Purchase Total:
                </Grid>
                <Grid
                  sx={{
                    display: "grid",
                    justifyItems: "end",
                  }}
                >
                  <Typography align="right" fontWeight={"550"}>
                    {formatAmount(+purchaseTotal).full}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {machineData.map((machine, index) => (
          <React.Fragment key={`${index}-${machine.machineId}`}>
            <Card
              variant="outlined"
              sx={{
                minWidth: "100%",
                borderRadius: "10px",
                marginTop: "16px",
                borderColor: "rgba(20, 33, 61, 0.31)",
              }}
              key={machine?.machineId}
            >
              <CardContent sx={{ padding: 2 }}>
                <Grid
                  sx={{
                    display: "grid",
                    gridDirection: "row",
                    paddingTop: "8px",
                    alignItems: "baseline",
                    // gridTemplateRows: "1fr 1fr 1fr 1fr 1fr",
                  }}
                  container
                >
                  <Grid
                    sx={{
                      display: "grid",
                      gridDirection: "column",
                    }}
                    container
                    gridTemplateColumns={"1fr 1fr"}
                  >
                    <Grid
                      sx={{
                        display: "grid",
                        justifyItems: "start",
                      }}
                    >
                      Machine ID:
                    </Grid>
                    <Grid
                      sx={{
                        display: "grid",
                        justifyItems: "end",
                      }}
                    >
                      <Typography align="right" fontWeight={"550"}>
                        {machine.machineId}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      display: "grid",
                      gridDirection: "column",
                    }}
                    container
                    gridTemplateColumns={"1fr 2fr"}
                  >
                    <Grid
                      sx={{
                        display: "grid",
                        justifyItems: "start",
                      }}
                    >
                      Fill Code:
                    </Grid>
                    <Grid
                      sx={{
                        display: "grid",
                        justifyItems: "end",
                      }}
                    >
                      <Typography align="right" fontWeight={"550"}>
                        <IconButton
                          sx={{
                            alignItems: "baseline",
                            justifyContent: "flex-end",
                            padding: 0,
                            marginX: 1,
                          }}
                          onClick={() => {
                            setSelectedMachine(machine)
                            handleOpenDialog()
                          }}
                        >
                          <ZoomInIcon />
                        </IconButton>
                        {formatFillCode(machine.code)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      display: "grid",
                      gridDirection: "column",
                    }}
                    container
                    gridTemplateColumns={"1fr 1fr"}
                  >
                    <Grid
                      sx={{
                        display: "grid",
                        justifyItems: "start",
                      }}
                    >
                      Fill Count:{" "}
                    </Grid>
                    <Grid
                      sx={{
                        display: "grid",
                        justifyItems: "end",
                      }}
                    >
                      <Typography align="right" fontWeight={"550"}>
                        {machine.fillCount}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      display: "grid",
                      gridDirection: "column",
                    }}
                    container
                    gridTemplateColumns={"1fr 1fr"}
                  >
                    <Grid
                      sx={{
                        display: "grid",
                        justifyItems: "start",
                      }}
                    >
                      {" "}
                      Amount:{" "}
                    </Grid>{" "}
                    <Grid
                      sx={{
                        display: "grid",
                        justifyItems: "end",
                      }}
                    >
                      <Typography align="right" fontWeight={"550"}>
                        {formatAmount(+machine.amount).full}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      display: "grid",
                      gridDirection: "column",
                    }}
                    container
                    gridTemplateColumns={"1fr 1fr"}
                  >
                    <Grid
                      sx={{
                        display: "grid",
                        justifyItems: "start",
                      }}
                    >
                      {" "}
                      Price:{" "}
                    </Grid>{" "}
                    <Grid
                      sx={{
                        display: "grid",
                        justifyItems: "end",
                      }}
                    >
                      <Typography align="right" fontWeight={"550"}>
                        {formatAmount(+machine.price).full}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </React.Fragment>
        ))}

        <div className="fixed bottom-20 shadow-top w-full z-50 pt-5 flex justify-between sm:static sm:pb-3 sm:shadow-none">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "flex-start",
            }}
          >
            {matchesXS ? (
              <ReactToPrint
                trigger={() => (
                  <Tooltip placement="left" title="Print">
                    <IconButton>
                      <PrintIcon sx={{ fontSize: "20px", color: "black" }} />
                    </IconButton>
                  </Tooltip>
                )}
                content={() => printComponentRef.current}
                removeAfterPrint={false}
                onBeforeGetContent={async () => {
                  const preparedData = prepareDataForPrint()
                  setPrintData(preparedData)
                }}
              />
            ) : (
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ marginRight: matchesXS ? 0 : "8px" }}
                    className="w-full max-w-[120px]"
                  >
                    Print
                  </Button>
                )}
                content={() => printComponentRef.current}
                removeAfterPrint={false}
                onBeforeGetContent={async () => {
                  const preparedData = prepareDataForPrint()
                  setPrintData(preparedData)
                }}
              />
            )}
            <ShareButton
              fontSize="20px"
              shareText={getTransactionShareText()}
              button={!matchesXS}
            />
          </div>

          <Button
            onClick={handleOkClick}
            variant="contained"
            color="primary"
            className="w-full max-w-[120px]"
          >
            Ok
          </Button>
        </div>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div style={{ flexBasis: "40px" }}></div>
              <div style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
                <DialogTitle
                  sx={{
                    fontWeight: "700",
                    textAlign: "center",
                    letterSpacing: "0.1em",
                  }}
                >
                  {selectedMachine?.machineId}
                </DialogTitle>
              </div>
              <IconButton aria-label="close" onClick={handleCloseDialog}>
                <CloseIcon />
              </IconButton>
            </div>

            <DialogContent sx={{ paddingTop: 0 }}>
              {selectedMachine !== null && selectedMachine !== undefined && (
                <div className="text-center text-2xl">
                  <hr
                    style={{
                      border: "none",
                      borderTop: "2px dashed rgba(20, 33, 61, 0.21)",
                      margin: "0px 16px",
                    }}
                  />
                  <h4 className="font-bold xs:text-xl sm:text-2xl py-4 tracking-widest">
                    {formatFillCode(selectedMachine.code)}{" "}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CopyButton
                      button={true}
                      onCopy={handleCloseDialog}
                      copyText={selectedMachine.code}
                      fontSize="24px"
                    />
                  </div>
                </div>
              )}
            </DialogContent>
          </div>
        </Dialog>

        <div style={{ display: "none" }}>
          {printData !== undefined && <Print ref={printComponentRef} printData={printData} />}
        </div>
      </Grid>
    </div>
  )
}
