import React, { useState, memo } from "react"
import { Typography } from "@mui/material"
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded"

import {
  SummarySection,
  SummaryItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../../styles/AccordionStyles"
import { TerminalsTable } from "./TerminalsTable"
import { formatAmount, formatNegative } from "../../../utils/formatAmount"
import { TablePaginationItem } from "../../TablePaginationItem"
import { useLocation } from "react-router-dom"
import { Truncate } from "../../Truncate"
import type { Business } from "../../../types/performanceTypes"
import { TerminalTypesNameMapping } from "../../../data/terminalTypesNameMapping"
import { getNetColor } from "../../../utils/getNetColor"
import { TerminalIcon } from "../../TerminalRoleIcon"
import { formatDate } from "../../../utils/formatDate"

const AccordionPerformanceItemComponent = ({
  collapseAllBusinesses,
  businessId,
  businessName,
  play,
  win,
  net,
  locations,
}: Business) => {
  const [expandedBusiness, setExpandedBusiness] = useState<string | false>(
    collapseAllBusinesses ? businessId : false
  )
  const [expandedLocation, setExpandedLocation] = useState<string | false>(false)
  const [expandedTerminalTypes, setExpandedTerminalTypes] = useState<string | false>(false)

  // Get the selected location ID from the URL
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const selectedLocationId = urlParams.get("locationId")

  const handleBusinessChange =
    (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpandedBusiness(isExpanded ? panel : false)
    }

  const handleLocationChange =
    (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpandedLocation(isExpanded ? panel : false)
      setPage(0)
      setRowsPerPage(5)
    }

  const handleTerminalTypesChange =
    (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpandedTerminalTypes(isExpanded ? panel : false)
      setPage(0)
      setRowsPerPage(5)
    }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [page, setPage] = useState(0)

  // filter that checks that we have terminals

  const hasTerminals = locations.some((location) =>
    location.terminalTypes.some((terminalTypes) => terminalTypes.terminals.length > 0)
  )

  if (!hasTerminals) {
    return null
  }

  return (
    <Accordion
      expanded={expandedBusiness === businessId}
      onChange={handleBusinessChange(businessId)}
      key={businessId}
      TransitionProps={{ timeout: 10 }}
    >
      {/* waterfall effect in accordion, push summary 12px to the left - default 16px */}
      <AccordionSummary
        sx={{
          backgroundColor: "#DEE2E6",
          borderTop: 2,
          borderTopColor: "darkgrey",
          paddingLeft: "4px",
        }}
        expandIcon={<NavigateNextRoundedIcon />}
      >
        {/* compensate the 12px move to left in the summary items to keep columns aligned */}
        <SummarySection sx={{ flexBasis: "100%", paddingLeft: "12px" }}>
          <SummaryItem
            sx={{
              textAlign: "left",
              minWidth: "68px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              flexBasis: "25%",
            }}
          >
            <Truncate input={businessName} />
          </SummaryItem>
          <SummaryItem
            sx={{
              textAlign: "right",
              minWidth: "68px",
              flexBasis: "25%",
            }}
          >
            <span className="hidden sm:block">{formatAmount(play).full}</span>
            <span className="block sm:hidden">{formatAmount(play).short}</span>
          </SummaryItem>
          <SummaryItem
            sx={{
              textAlign: "right",
              minWidth: "68px",
              flexBasis: "25%",
            }}
          >
            <span className="hidden sm:block">{formatAmount(win).full}</span>
            <span className="block sm:hidden">{formatAmount(win).short}</span>
          </SummaryItem>
          <SummaryItem
            sx={{
              textAlign: "right",
              minWidth: "68px",
              flexBasis: "25%",
              color: getNetColor(net),
            }}
          >
            <span className="hidden sm:block">{formatNegative(net).full}</span>
            <span className="block sm:hidden">{formatNegative(net).short}</span>
          </SummaryItem>
        </SummarySection>
      </AccordionSummary>
      <AccordionDetails>
        {locations.map(
          ({ locationId, lastUpdated, softwareName, play, win, net, terminalTypes }) => {
            const isSelected = locationId === selectedLocationId

            return (
              <Accordion
                expanded={expandedLocation === locationId}
                onChange={handleLocationChange(locationId)}
                key={locationId}
                TransitionProps={{ timeout: 10 }}
              >
                {/* waterfall effect in accordion, push summary 6px to the left - default 16px */}
                <AccordionSummary
                  sx={{
                    backgroundColor: isSelected ? "#FFF6E8" : "#E9ECEF",
                    paddingLeft: "10px",
                  }}
                  expandIcon={<NavigateNextRoundedIcon />}
                >
                  {/* compensate the 6px move to left in the summary items to keep columns aligned */}
                  <SummarySection sx={{ flexBasis: "100%", paddingLeft: "6px" }}>
                    <SummaryItem
                      sx={{
                        textAlign: "left",
                        minWidth: "68px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        flexBasis: "25%",
                      }}
                    >
                      {locationId} {softwareName}
                      <Typography
                        sx={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          fontSize: "0.75rem",
                          fontWeight: 500,
                        }}
                        variant="subtitle2"
                        color="textSecondary"
                      >
                        {formatDate(lastUpdated)}
                      </Typography>
                    </SummaryItem>
                    <SummaryItem
                      sx={{
                        textAlign: "right",
                        minWidth: "68px",
                        flexBasis: "25%",
                      }}
                    >
                      <span className="hidden sm:block">{formatAmount(play).full}</span>
                      <span className="block sm:hidden">{formatAmount(play).short}</span>
                    </SummaryItem>
                    <SummaryItem
                      sx={{
                        textAlign: "right",
                        minWidth: "68px",
                        flexBasis: "25%",
                      }}
                    >
                      <span className="hidden sm:block">{formatAmount(win).full}</span>
                      <span className="block sm:hidden">{formatAmount(win).short}</span>
                    </SummaryItem>
                    <SummaryItem
                      sx={{
                        textAlign: "right",
                        minWidth: "68px",
                        flexBasis: "25%",
                        color: getNetColor(net),
                      }}
                    >
                      <span className="hidden sm:block">{formatNegative(net).full}</span>
                      <span className="block sm:hidden">{formatNegative(net).short}</span>
                    </SummaryItem>
                  </SummarySection>
                </AccordionSummary>
                <AccordionDetails>
                  {terminalTypes.map(({ type, play, win, net, terminals }) => {
                    const visibleTerminals = terminals.slice(
                      page * rowsPerPage,
                      (page + 1) * rowsPerPage
                    )

                    const emptyRows =
                      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - terminals.length) : 0

                    if (terminals.length === 0) return null
                    return (
                      <Accordion
                        expanded={expandedTerminalTypes === type}
                        onChange={handleTerminalTypesChange(type)}
                        key={type}
                        TransitionProps={{ timeout: 10 }}
                      >
                        <AccordionSummary
                          sx={{
                            backgroundColor: "#F8F9FA",
                          }}
                          expandIcon={<NavigateNextRoundedIcon />}
                        >
                          <SummarySection sx={{ flexBasis: "100%" }}>
                            <SummaryItem
                              sx={{
                                alignItems: "center",
                                textAlign: "left",
                                minWidth: "68px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                flexBasis: "25%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <div style={{ display: "block" }}>
                                  <TerminalIcon roleDisplayName={type} width={24} height={24} />
                                </div>
                                <span
                                  // className="hidden sm:block"
                                  style={{
                                    marginLeft: "4px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {
                                    TerminalTypesNameMapping[
                                      type as keyof typeof TerminalTypesNameMapping
                                    ]
                                  }
                                </span>
                              </div>
                            </SummaryItem>

                            <Typography
                              component="div"
                              variant="body1"
                              sx={{
                                textAlign: "right",
                                minWidth: "68px",
                                flexBasis: "25%",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <span className="hidden sm:block">{formatAmount(play).full}</span>
                              <span className="block sm:hidden">{formatAmount(play).short}</span>
                            </Typography>
                            <SummaryItem
                              sx={{
                                textAlign: "right",
                                minWidth: "68px",
                                flexBasis: "25%",
                              }}
                            >
                              <span className="hidden sm:block">{formatAmount(win).full}</span>
                              <span className="block sm:hidden">{formatAmount(win).short}</span>
                            </SummaryItem>
                            <SummaryItem
                              sx={{
                                textAlign: "right",
                                minWidth: "68px",
                                flexBasis: "25%",
                                color: getNetColor(net),
                              }}
                            >
                              <span className="hidden sm:block">{formatNegative(net).full}</span>
                              <span className="block sm:hidden">{formatNegative(net).short}</span>
                            </SummaryItem>
                          </SummarySection>
                        </AccordionSummary>
                        <AccordionDetails>
                          {/* Table of transactions */}
                          <TerminalsTable
                            // terminal object goes here
                            visibleTerminals={visibleTerminals}
                            emptyRows={emptyRows}
                          />

                          <TablePaginationItem
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={terminals.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </AccordionDetails>
              </Accordion>
            )
          }
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export const AccordionPerformanceItem = memo(AccordionPerformanceItemComponent)
