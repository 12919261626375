import { useState, useEffect } from "react"
import { useGetProfileQuery, useUpdatePasswordRequestMutation } from "../../redux/user/user.api"
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import { toast } from "react-hot-toast"
import { PasswordTextField } from "../../components/PasswordTextField"
import { AccountSettingsSkeleton } from "../../layouts/Skeletons/AccountSettingsSkeleton"
import { isValidPassword } from "../../utils/isValidPassword"
import { ConfirmPasswordTextField } from "../../components/ConfirmPasswordTextField"
import { NewPasswordTextField } from "../../components/NewPasswordTextField"

export function AccountSettings() {
  const { data: userInfo, error, isLoading } = useGetProfileQuery("")
  const [updatePassword] = useUpdatePasswordRequestMutation()
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")

  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (userInfo !== null && userInfo !== undefined) {
      setFirstName(userInfo.data.userProfile.firstName)
      setLastName(userInfo.data.userProfile.lastName)
      setEmail(userInfo.data.userProfile.email)
    }
  }, [userInfo])
  const passwordsMatch = newPassword === confirmNewPassword && confirmNewPassword !== ""
  const updatePasswordHandler = async () => {
    setIsSubmitting(true)

    try {
      if (currentPassword === "") {
        toast.dismiss()
        toast.error("Please enter Current Password")
        return
      }

      if (newPassword === "") {
        toast.dismiss()
        toast.error("Please enter New Password")
        return
      }

      if (confirmNewPassword === "") {
        toast.dismiss()
        toast.error("Please enter Confirm New Password")
        return
      }
      if (!passwordsMatch) {
        toast.dismiss()
        toast.error("Passwords do not match")
        return
      }

      // Add a check to see if the new password is the same as the current one
      if (newPassword === currentPassword) {
        toast.dismiss()
        toast.error("New password cannot be the same as the existing password")
        return
      }

      // Check if new password is valid
      if (!isValidPassword(newPassword)) {
        toast.dismiss()
        toast.error("New password doesn't meet the password criteria")
        return
      }

      const res: any = await updatePassword({
        currentPassword,
        newPassword,
      })

      if (res.data.rc === 1005) {
        toast.dismiss()
        toast.error("Incorrect Current Password")
      } else if (res.data.rc === 1003) {
        toast.dismiss()
        toast.error("Please enter Current Password")
      } else {
        toast.dismiss()
        toast.success("Password successfully updated")
        setCurrentPassword("")
        setNewPassword("")
        setConfirmNewPassword("")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  if (isLoading) {
    return <AccountSettingsSkeleton />
  }

  if (error !== null && error !== undefined && !isLoading) {
    console.log(error)
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 20 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" fontWeight="bold" textAlign="center" sx={{ mb: 2 }}>
          Account Settings
        </Typography>
        <Box
          component="form"
          onSubmit={(event) => {
            event.preventDefault()
            updatePasswordHandler().catch((error) => {
              console.error("An error occurred during login:", error)
            })
          }}
          noValidate
          sx={{ width: "100%", mt: 1 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                value={firstName}
                disabled
                onChange={(e) => {
                  setFirstName(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                disabled
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                disabled
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordTextField
                label="Current Password"
                value={currentPassword}
                onChange={(e) => {
                  setCurrentPassword(e.target.value)
                }}
                show={showPassword}
                setShow={setShowPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <NewPasswordTextField
                label="New Password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value)
                }}
                show={showNewPassword}
                setShow={setShowNewPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <ConfirmPasswordTextField
                label="Confirm New Password"
                value={confirmNewPassword}
                onChange={(e) => {
                  setConfirmNewPassword(e.target.value)
                }}
                show={showConfirmNewPassword}
                setShow={setShowConfirmNewPassword}
                match={passwordsMatch}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            sx={{
              mt: 3,
              mb: 2,
              position: "relative",
            }}
          >
            {isSubmitting && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
            Update Password
          </Button>
        </Box>
      </Box>
    </Container>
  )
}
