import React, { useState, useEffect } from "react"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Grid, IconButton, InputAdornment, TextField, List, Collapse, Box } from "@mui/material"
import { PasswordStrengthIndicator } from "./PasswordStrengthIndicator"
import { usePasswordStrength } from "../hooks/usePasswordStrength"
import { PasswordCriteriaList } from "./PasswordCriteriaList"

interface Props {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  label: string
  helperText?: string | null
  error?: boolean
  required?: boolean
}

export const NewPasswordTextField: React.FC<Props> = ({
  value,
  onChange,
  show,
  setShow,
  label,
  helperText,
  error,
  required,
}) => {
  const { strengthColor, missingCriteria } = usePasswordStrength(value)
  const [visibleCriteria, setVisibleCriteria] = useState(missingCriteria)

  useEffect(() => {
    const newCriteria = missingCriteria.filter((criterion) => visibleCriteria.includes(criterion))
    const removedCriteria = visibleCriteria.filter(
      (criterion) => !missingCriteria.includes(criterion)
    )
    if (removedCriteria.length > 0) {
      setTimeout(() => {
        setVisibleCriteria(newCriteria)
      }, 500)
    } else {
      setVisibleCriteria(missingCriteria)
    }
  }, [missingCriteria, visibleCriteria])

  return (
    <>
      <TextField
        required={required}
        type={show ? "text" : "password"}
        label={label}
        value={value}
        onChange={onChange}
        helperText={helperText}
        error={error}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                display: "flex",
                minWidth: value !== "" ? "120px" : "40px",
                alignItems: "center",
              }}
            >
              {value !== "" && (
                <Grid item xs={8}>
                  <PasswordStrengthIndicator password={value} />
                </Grid>
              )}
              <Grid item xs={value !== "" ? 4 : 12}>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShow(!show)
                  }}
                >
                  {show ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Grid>
            </InputAdornment>
          ),
        }}
      />

      <Collapse in={value !== "" && visibleCriteria.length > 0} sx={{ mt: 1 }} timeout={500}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ borderLeft: `4px solid ${strengthColor}`, mr: 1 }} />
          <List>
            {visibleCriteria.map((criteria) => (
              <PasswordCriteriaList
                key={criteria}
                criteria={criteria}
                isRemoving={!missingCriteria.includes(criteria)}
              />
            ))}
          </List>
        </Box>
      </Collapse>
    </>
  )
}
