import { createApi } from "@reduxjs/toolkit/query/react"
import baseQueryWithRejection from "../api/baseQueryWithRejection"

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithRejection,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/User/Login",
        body,
      }),
    }),

    submitSupportRequest: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/User/SubmitSupportRequest",
        body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }),
    }),

    updatePasswordRequest: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/User/UpdatePassword",
        body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }),
    }),

    getProfile: builder.query({
      query: () => ({
        method: "GET",
        url: "/api/User/GetUserProfile",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    refreshToken: builder.mutation({
      query: (currentToken) => ({
        method: "POST",
        url: "/api/User/Refresh",
        body: {
          currentToken,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }),
    }),

    verifyToken: builder.query({
      query: () => ({
        method: "GET",
        url: "/api/User/VerifyToken",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }),
    }),

    requestPasswordReset: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/User/PasswordResetRequest",
        body,
      }),
    }),

    validateResetToken: builder.query({
      query: (token: string) => ({
        method: "POST",
        url: `/api/User/VerifyPasswordResetToken`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    resetPassword: builder.mutation({
      query: ({ body, token }: { body: any; token: string }) => ({
        method: "POST",
        url: "/api/User/PasswordReset",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body,
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useGetProfileQuery,
  useRefreshTokenMutation,
  useVerifyTokenQuery,
  useUpdatePasswordRequestMutation,
  useSubmitSupportRequestMutation,
  useRequestPasswordResetMutation,
  useValidateResetTokenQuery,
  useResetPasswordMutation,
} = userApi
