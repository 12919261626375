/*
  We're disabling ESLint's react/prop-types rule in this file because it can
  give false positives when used with TypeScript. This rule checks that all props
  used in the component are validated with PropTypes. However, in TypeScript,
  we're already type-checking our props using interfaces or types, which
  provides a level of safety that's at least as good, and typically better,
  than what PropTypes provides. Moreover, PropTypes doesn't support some
  TypeScript constructs like enum, which can lead to unnecessary errors.
  Therefore, we turn off this rule for this file.
*/
/* eslint-disable react/prop-types */

import dayjs, { type Dayjs } from "dayjs"
import { DatePickerBasic } from "./DatePickerBasic"
import { DateRangePicker } from "./DateRangePicker"
import { CalendarInputType } from "../types/reportTypes"

interface RenderCalendarProps {
  calendarInput: CalendarInputType
  headerDateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null]
  setReportDateRange: React.Dispatch<React.SetStateAction<[dayjs.Dayjs | null, dayjs.Dayjs | null]>>
  disabled?: boolean
  setGiveMargin: React.Dispatch<React.SetStateAction<boolean>>
}

export const RenderCalendar: React.FC<RenderCalendarProps> = ({
  calendarInput,
  headerDateRange,
  setReportDateRange,
  disabled = false,
  setGiveMargin,
}) => {
  const from = headerDateRange[0] ?? dayjs()
  const to = headerDateRange[1] ?? dayjs()

  switch (calendarInput) {
    case CalendarInputType.Date:
      return (
        <DatePickerBasic
          disabled={disabled}
          value={from} // Pass the current date value or today's date if it's null
          onChange={(date) => {
            setReportDateRange([date, date]) // Set the selected date as the start and end of the range
          }}
        />
      )
    case CalendarInputType.DateRange:
      return (
        <DateRangePicker
          disabled={disabled}
          value={[from, to]}
          onChange={(dates: Array<Dayjs | null> | null) => {
            setReportDateRange(dates as [Dayjs | null, Dayjs | null])
          }}
        />
      )
    default:
      return null
  }
}
//  delete the code above and uncomment the code below for browser back and forwart button

// import dayjs, { type Dayjs } from "dayjs"
// import { DatePickerBasic } from "./DatePickerBasic"
// import { DateRangePicker } from "./DateRangePicker"
// import { CalendarInputType } from "../types/reportTypes"
// import { useSearchParams } from "react-router-dom"

// interface RenderCalendarProps {
//   calendarInput: CalendarInputType
//   headerDateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null]
//   setReportDateRange: React.Dispatch<React.SetStateAction<[dayjs.Dayjs | null, dayjs.Dayjs | null]>>
//   disabled?: boolean
//   setGiveMargin: React.Dispatch<React.SetStateAction<boolean>>
// }

// export const RenderCalendar: React.FC<RenderCalendarProps> = ({
//   calendarInput,
//   headerDateRange,
//   setReportDateRange,
//   disabled = false,
//   setGiveMargin,
// }) => {
//   const [searchParams] = useSearchParams()

//   const startDateParam = searchParams.get("startDate")
//   const endDateParam = searchParams.get("endDate")

//   const startDate =
//     startDateParam !== null && startDateParam !== ""
//       ? dayjs(startDateParam)
//       : dayjs().startOf("day")
//   const endDate =
//     endDateParam !== null && endDateParam !== "" ? dayjs(endDateParam) : dayjs().endOf("day")

//   switch (calendarInput) {
//     case CalendarInputType.Date:
//       return (
//         <DatePickerBasic
//           disabled={disabled}
//           value={startDate} // Pass the current date value or today's date if it's null
//           onChange={(date) => {
//             setReportDateRange([date, date]) // Set the selected date as the start and end of the range
//           }}
//         />
//       )
//     case CalendarInputType.DateRange:
//       return (
//         <DateRangePicker
//           disabled={disabled}
//           value={[startDate, endDate]}
//           onChange={(dates: Array<Dayjs | null> | null) => {
//             setReportDateRange(dates as [Dayjs | null, Dayjs | null])
//           }}
//           setGiveMargin={setGiveMargin}
//         />
//       )
//     default:
//       return null
//   }
// }
