import { createApi } from "@reduxjs/toolkit/query/react"
import baseQueryWithRejection from "../api/baseQueryWithRejection"

export const businessApi = createApi({
  reducerPath: "businessApi",
  baseQuery: baseQueryWithRejection,
  endpoints: (builder) => ({
    getLocations: builder.query({
      query: () => ({
        url: "/api/Business/GetAllLocation",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    getDecodeCode: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/License/DecodeRequestCode",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getAddItemToCart: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/License/AddItemToCart",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getRemoveCartItem: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/License/RemoveCartItem",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getUpdateCartItem: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/License/UpdateCartItem",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getFillPricing: builder.query({
      query: () => ({
        method: "GET",
        url: "/api/License/GetFillPricing",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    fillPricing: builder.mutation({
      query: () => ({
        method: "POST",
        url: "/api/License/GetFillPricing",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }),
    }),

    PaymentStatus: builder.query({
      query: (body) => ({
        method: "POST",
        url: "/api/License/PaymentStatus",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
      keepUnusedDataFor: 0,
    }),

    getCreateCart: builder.query({
      query: (body) => ({
        method: "GET",
        url: "/api/License/CreateCart",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
      keepUnusedDataFor: 0,
    }),

    GetUserCartItems: builder.query({
      query: (params) => ({
        method: "GET",
        url: "/api/License/GetUserCartItems",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        params,
      }),
      keepUnusedDataFor: 0,
    }),

    GetSummaryCartItems: builder.query({
      query: (params) => ({
        method: "GET",
        url: "/api/License/GetUserCartItems",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        params,
      }),
      keepUnusedDataFor: 0,
    }),

    GetPaymentMethods: builder.query({
      query: (params) => ({
        method: "GET",
        url: "/api/License/GetPaymentMethods",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        params,
      }),
      keepUnusedDataFor: 0,
    }),

    getCreateCheckout: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/License/CreateCheckout",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getCreateInvoice: builder.mutation({
      query: (params) => ({
        method: "GET",
        url: "/api/License/CreateInvoice",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        params,
      }),
    }),

    getAllBusinesses: builder.query({
      query: () => ({
        method: "POST",
        url: "/api/Business/GetAllBusiness",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    getCashAdjustments: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Business/GetCashAdjustments",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getPerformance: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Business/GetBusinessPerformanceReport",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getTerminals: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Business/GetTerminals",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getRevenue: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Business/GetBusinessRevenueReport",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getSales: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Business/GetBusinessSalesReport",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getCurrentCash: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Business/GetDeviceStatus",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getPeriod: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Store/GetStoreSummary",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getOverview: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/Business/GetOverView",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),

    getBusinessDetails: builder.query<any, string>({
      query: (businessId) => ({
        url: `/api/Business/GetBusinessDetails`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: { businessId },
      }),
      keepUnusedDataFor: 0,
    }),

    getTerminalDetails: builder.query<any, string>({
      query: (terminalId) => ({
        url: `/api/Store/GetTerminalDetails`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: { terminalId },
      }),
    }),

    getMachines: builder.query({
      query: () => ({
        url: `/api/License/GetMachines`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    getFillHistoryByMachineId: builder.query<
      any,
      { machineId: string; numberOfRecords: number; currentPage: number }
    >({
      query: (body) => ({
        url: `/api/License/GetFillHistoryByMachineId`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
      keepUnusedDataFor: 0,
    }),

    getFillHistoryByDateRange: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/api/License/GetFillHistoryByDateRange",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body,
      }),
    }),
  }),
})

export const {
  useGetLocationsQuery,
  useGetDecodeCodeMutation,
  useGetAddItemToCartMutation,
  useGetRemoveCartItemMutation,
  useGetUpdateCartItemMutation,
  useGetFillPricingQuery,
  useFillPricingMutation,
  usePaymentStatusQuery,
  useGetCreateCartQuery,
  useGetUserCartItemsQuery,
  useGetSummaryCartItemsQuery,
  useGetPaymentMethodsQuery,
  useGetCreateCheckoutMutation,
  useGetCreateInvoiceMutation,
  useGetTerminalsMutation,
  useGetAllBusinessesQuery,
  useGetCashAdjustmentsMutation,
  useGetPerformanceMutation,
  useGetPeriodMutation,
  useGetOverviewMutation,
  useGetRevenueMutation,
  useGetCurrentCashMutation,
  useGetSalesMutation,
  useGetBusinessDetailsQuery,
  useGetTerminalDetailsQuery,
  useGetMachinesQuery,
  useGetFillHistoryByMachineIdQuery,
  useGetFillHistoryByDateRangeMutation,
} = businessApi
