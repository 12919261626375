import { useState, useEffect, useCallback, useRef } from "react"

import dayjs, { type Dayjs } from "dayjs"
import utc from "dayjs/plugin/utc"

import { Pagination, useMediaQuery, useTheme } from "@mui/material"

import Alert, { type AlertColor } from "@mui/material/Alert"
import { LocationInputType, type HandleSnackBarType } from "../../../types/reportTypes"
import { ReportLayout } from "../../../layouts/ReportLayout"
import { ReportSkeleton } from "../../../layouts/Skeletons/ReportSkeleton"
import { getReportsHandler } from "../../../utils/ReportHandlerInput"
import { CalendarInputType } from "../../../types/reportTypes"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useGetFillHistoryByDateRangeMutation } from "../../../redux/business/business.api"
import type { Transactions } from "../../../types/IFillHistory"
import { PurchaseReport } from "../../../components/Licensing/PurchaseHistory"

interface Props {
  openFiltersHandler?: () => void
}

export function PurchaseHistory(props: Props) {
  const [fetchReport] = useGetFillHistoryByDateRangeMutation()
  const [location, setLocation] = useState<string | null>(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showHeaderDate, setShowHeaderDate] = useState(false)
  const hasHeaderDate = true
  const requiresAPICall = true
  const [reportDateRange, setReportDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs(),
    dayjs(),
  ])
  const [headerDateRange, setHeaderDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null])
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("error")

  const isMountedRef = useRef(true)

  const [report, setReport] = useState<Transactions | null>(null)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(700))
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const [showForm, setShowForm] = useState(isMobile)
  const [isFetchingReport, setIsFetchingReport] = useState(false)
  const rowPerPage = isMobile ? 3 : 5
  const [page, setPage] = useState(1)
  const allLocationsOption = { name: "All Locations", id: null, businessId: null }
  const [selectedValue, setSelectedValue] = useState<any>(allLocationsOption)
  const [searchParams] = useSearchParams()
  const [firstSelectedValue, setFirstSelectedValue] = useState<any>(null)
  dayjs.extend(utc)
  const formatToISOString = (date: Dayjs | null) => {
    return date !== null ? dayjs.utc(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : ""
  }
  const paginationSize = isSmallScreen ? "small" : "medium"

  const newSearchParams = new URLSearchParams(searchParams.toString())

  const handleChange = async (value: number) => {
    newSearchParams.set("page", value.toString())
    navigate(`?${newSearchParams.toString()}`, { replace: false })
    // await handlePagination(value)
    setPage(value)
    window.scrollTo({ top: 0 })
  }
  const navigate = useNavigate()

  useEffect(() => {
    // When the component mounts
    isMountedRef.current = true

    return () => {
      // When the component unmounts
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (!isMobile) {
      setShowForm(true)
    }
  }, [isMobile])

  const toggleForm = useCallback(
    (show: boolean) => {
      if (!isMobile) {
        setShowForm(true)
      } else {
        setShowForm(show)
      }
    },
    [isMobile]
  )

  const handleLocationChange = (e: React.SyntheticEvent<Element, Event>, value: number | null) => {
    setLocation(value !== null ? value.toString() : null)
  }

  const handleSetSelectedValue = (value: any | null) => {
    setSelectedValue(value)
    if (firstSelectedValue === null) {
      setFirstSelectedValue(value)
    }
  }
  const handleSnackBar: HandleSnackBarType = (isOpen, severity, message) => {
    setSnackbarSeverity(severity)
    setSnackbarMessage(message)
    setSnackbarOpen(isOpen)
  }

  useEffect(() => {
    const handleOrientationChange = () => {
      // Explicitly check if report and its machines property are not null or undefined.
      if (
        report !== null &&
        report !== undefined &&
        Object.keys(report?.transactions ?? {}).length > 0
      ) {
        if (Object.keys(report?.transactions).length > 0) {
          setShowForm(false)
        }
      }
    }

    window.addEventListener("orientationchange", handleOrientationChange)

    // Cleanup the event listener when the component unmounts or the report changes.
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange)
    }
  }, [report?.transactions])

  useEffect(() => {
    if (
      isMobile &&
      report != null &&
      report?.transactions != null &&
      Object.keys(report?.transactions).length !== 0
    ) {
      toggleForm(false)
    }
  }, [report, isMobile, report?.transactions, toggleForm])

  // 360 menu redirect

  useEffect(() => {
    const startDateParam = searchParams.get("startDate")
    const endDateParam = searchParams.get("endDate")
    const page = searchParams.get("page")

    const startDate =
      startDateParam !== null && startDateParam !== ""
        ? dayjs(startDateParam)
        : dayjs().startOf("day")
    const endDate =
      endDateParam !== null && endDateParam !== "" ? dayjs(endDateParam) : dayjs().endOf("day")

    // Fetch the locationId from URL search parameters
    const pageParam = page !== null && page !== undefined && page !== "" ? page : 1

    if (!searchParams.has("startDate")) {
      return
    }

    setPage(+pageParam)

    setReportDateRange([startDate, endDate])
    setHeaderDateRange([startDate, endDate])
    getReportsHandler(
      fetchReport,
      setIsFetchingReport,
      handleSnackBar,
      setReport,
      "",
      hasHeaderDate,
      requiresAPICall,
      setShowHeaderDate,
      "data.data",
      {
        startDate: formatToISOString(startDate?.hour(4).minute(0).second(0).millisecond(0) ?? null),
        endDate: formatToISOString(
          endDate?.hour(3).minute(59).second(59).millisecond(999).add(1, "day") ?? null
        ),
        numberOfRecords: rowPerPage,
        currentPage: +pageParam,
      },
      isMountedRef
    ).catch((error) => {
      console.log(error)
    })
  }, [searchParams])

  console.log("report", report)

  return (
    <ReportLayout
      reportName={"Purchase History"}
      calendarInput={CalendarInputType.DateRange}
      locationInput={LocationInputType.NoLocation}
      showHeaderDate={showHeaderDate}
      hasHeaderDate={hasHeaderDate}
      headerDateRange={headerDateRange}
      showForm={showForm}
      accordionHeadings={[]}
      showAccordionHeadings={false}
      showSummaries={false}
      toggleForm={toggleForm}
      handleLocationChange={handleLocationChange}
      selectedValue={selectedValue}
      handleSetSelectedValue={handleSetSelectedValue}
      setReportDateRange={setReportDateRange}
      openFiltersHandler={props.openFiltersHandler}
      location={location}
      accordionSummaries={[]}
      getReportHandler={async () => {
        if (selectedValue.id === null) {
          // Create a new URLSearchParams object with the current search params

          // Delete the parameters you want to clear
          newSearchParams.set("page", page.toString())
          newSearchParams.set("startDate", reportDateRange[0]?.format("YYYY-MM-DD") ?? "")
          newSearchParams.set("endDate", reportDateRange[1]?.format("YYYY-MM-DD") ?? "")
          newSearchParams.delete("businessId")
          newSearchParams.delete("locationName")

          // Use the 'navigate' method with the 'replace' option
          navigate(`?${newSearchParams.toString()}`, { replace: true })
        } else if (selectedValue.id !== null && selectedValue.id !== undefined) {
          // Create a new URLSearchParams object with the current search params

          // Set the search params as you had before
          newSearchParams.set("startDate", reportDateRange[0]?.format("YYYY-MM-DD") ?? "")
          newSearchParams.set("endDate", reportDateRange[1]?.format("YYYY-MM-DD") ?? "")
          newSearchParams.set("page", page.toString())

          // Use the 'navigate' method with the 'replace' option
          navigate(`?${newSearchParams.toString()}`, { replace: true })
        }

        try {
          await getReportsHandler(
            fetchReport,
            setIsFetchingReport,
            handleSnackBar,
            setReport,
            location,
            hasHeaderDate,
            requiresAPICall,
            setShowHeaderDate,
            "data.data",

            {
              startDate: formatToISOString(
                reportDateRange[0]?.hour(4).minute(0).second(0).millisecond(0) ?? null
              ),
              endDate: formatToISOString(
                reportDateRange[1]?.hour(3).minute(59).second(59).millisecond(999).add(1, "day") ??
                  null
              ),
              numberOfRecords: rowPerPage,
              currentPage: 1,
            },
            isMountedRef
          )

          setHeaderDateRange([reportDateRange[0], reportDateRange[1]])
          setFirstSelectedValue(selectedValue)
          setPage(1)
          localStorage.removeItem("option")
        } catch (error) {
          console.log(error)
        }
      }}
    >
      <>
        {isFetchingReport && <ReportSkeleton />}
        {!isFetchingReport &&
          snackbarOpen &&
          (report === null || report.transactionsTotal === 0) && (
            <Alert severity={snackbarSeverity} sx={{ width: "100%" }}>
              {snackbarMessage}
            </Alert>
          )}
        {!isFetchingReport &&
          report !== null &&
          report !== undefined &&
          report.transactions !== null &&
          report.transactions !== undefined && (
            <>
              <PurchaseReport report={report} />{" "}
              {report.transactionsTotal !== 0 && (
                <div className="flex my-8 justify-center">
                  <Pagination
                    size={paginationSize}
                    count={Math.ceil(report?.transactionsTotal / rowPerPage)}
                    page={page}
                    boundaryCount={1}
                    siblingCount={1}
                    onChange={(event, value) => {
                      handleChange(value).catch((error) => {
                        console.error("Error during pagination:", error)
                      })
                    }}
                    sx={{
                      "& .MuiPagination-ul": {
                        flexWrap: "nowrap",
                      },
                      "& .MuiPaginationItem-root": {
                        "& .MuiTouchRipple-root": {
                          display: "none",
                        },
                        "&:hover": {
                          backgroundColor: isSmallScreen ? "transparent" : "undefined",
                        },
                      },
                    }}
                  />
                </div>
              )}{" "}
            </>
          )}
      </>
    </ReportLayout>
  )
}
