import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material"
import { PasswordsMatchIndicator } from "./PasswordsMatchIndicator"

interface Props {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  label: string
  helperText?: string | null
  error?: boolean
  required?: boolean
  match: boolean
}

export function ConfirmPasswordTextField(props: Props) {
  return (
    <TextField
      required={props.required}
      type={props.show ? "text" : "password"}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      helperText={props.helperText}
      error={props.error}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{
              display: "flex",
              minWidth: props.value !== "" ? "120px" : "40px",
              alignItems: "center",
            }}
          >
            {" "}
            {props.value !== "" && (
              <Grid item xs={8}>
                {props.value !== "" && <PasswordsMatchIndicator match={props.match} />}
              </Grid>
            )}
            <Grid item xs={props.value !== "" ? 4 : 12}>
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  props.setShow(!props.show)
                }}
              >
                {props.show ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Grid>
          </InputAdornment>
        ),
      }}
    />
  )
}
