// External libraries
import { Table, TableBody, TableContainer, TableRow } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import Box from "@mui/material/Box"

// Absolute imports
import type { SalesStore } from "../../../types/ISalesReportTypes"
import { formatAmount, formatNegative, formatNegativePercent } from "../../../utils/formatAmount"
import { formatDate } from "../../../utils/formatDate"
import { StatusCircleIcon } from "../../StatusCircleIcon"
import { SalesTypographyStyled } from "../../../styles/SalesTypographyStyled"
import { SalesBoxStyled } from "../../../styles/SalesBoxStyled"
import { useLocation } from "react-router-dom"

interface IRevenueTableProps {
  stores: SalesStore[]
  name: string
  emptyRows: number
}

export function SalesTable({ stores, name, emptyRows }: IRevenueTableProps) {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const selectedLocationId = urlParams.get("locationId")

  const getNetColor = (amount: number) => {
    return amount < 0 ? "red" : "inherit"
  }

  const sortedStores = [...stores]

  sortedStores.sort((a, b) => Number(a.store.id) - Number(b.store.id))

  return (
    <Box>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table component="div" sx={{ tableLayout: "fixed" }} stickyHeader={true} size="small">
          <TableBody component="div">
            {sortedStores.map((storeData, storeIndex) => {
              const isSelected = storeData.store.id.toString() === selectedLocationId
              return (
                <TableRow component="div" key={`${storeData.store.id}${storeIndex}`}>
                  <Box
                    // component="td"
                    sx={{
                      display: "flex",
                      flexWrap: "nowrap",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                      flexBasis: "100%",
                      backgroundColor: isSelected ? "#FFF6E8" : "none",
                    }}
                  >
                    <Tooltip placement="left" title={storeData.store.statusMsg}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexBasis="3%"
                      >
                        <StatusCircleIcon size={14} status={storeData.store.statusMsg} />
                      </Box>
                    </Tooltip>
                    <Box
                      sx={{
                        flexBasis: "97%",
                        paddingLeft: "8px",
                        display: "flex",
                      }}
                    >
                      <SalesBoxStyled textAlign="left">
                        <SalesTypographyStyled>
                          {storeData.store.id} {storeData.store.sofwareName}
                        </SalesTypographyStyled>
                        <div className="flex flex-wrap">
                          <SalesTypographyStyled
                            className="pr-1"
                            variant="subtitle2"
                            color="textSecondary"
                          >
                            {formatDate(storeData.lastUpdate, true).formattedDate}
                          </SalesTypographyStyled>
                          <SalesTypographyStyled variant="subtitle2" color="textSecondary">
                            {formatDate(storeData.lastUpdate, true).formattedTime}
                          </SalesTypographyStyled>
                        </div>
                      </SalesBoxStyled>
                      <SalesBoxStyled>
                        <SalesTypographyStyled>
                          <span className="hidden sm:block">
                            {formatAmount(storeData.summary.s).full}
                          </span>
                          <span className="block sm:hidden">
                            {formatAmount(storeData.summary.s).short}
                          </span>
                        </SalesTypographyStyled>
                      </SalesBoxStyled>

                      <SalesBoxStyled>
                        <SalesTypographyStyled>
                          {storeData.store.isSweeps ? (
                            <>
                              <span className="hidden sm:block">
                                {formatAmount(storeData.summary.f).full}
                              </span>
                              <span className="block sm:hidden">
                                {formatAmount(storeData.summary.f).short}
                              </span>
                            </>
                          ) : (
                            "-"
                          )}
                        </SalesTypographyStyled>
                      </SalesBoxStyled>

                      <SalesBoxStyled color={getNetColor(storeData.summary.p)}>
                        <SalesTypographyStyled>
                          <span className="hidden sm:block">
                            {formatNegative(storeData.summary.p).full}
                          </span>
                          <span className="block sm:hidden">
                            {formatNegative(storeData.summary.p).short}
                          </span>
                        </SalesTypographyStyled>
                      </SalesBoxStyled>

                      <SalesBoxStyled hideOnMobile={true} color={getNetColor(storeData.summary.p)}>
                        <SalesTypographyStyled>
                          {formatNegativePercent(storeData.summary.percentage)}
                        </SalesTypographyStyled>
                      </SalesBoxStyled>
                    </Box>
                  </Box>
                </TableRow>
              )
            })}
            {emptyRows > 0 &&
              Array.from({ length: emptyRows }, (_, i) => (
                <TableRow style={{ height: 33 }} key={`emptyRow-${i}`}></TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
