import React, { useState } from "react"
import DateRangeIcon from "@mui/icons-material/DateRange"
import { DatePicker, Button } from "antd"
import dayjs, { type Dayjs } from "dayjs"

import "./DatePickerBasic.css"
import { Typography } from "@mui/material"

interface Props {
  value: Dayjs | null
  onChange: (date: Dayjs, dateString: string | string[]) => void
  disabled?: boolean
}

interface CustomFooterProps {
  onClick: (value: Dayjs) => void
  closePicker: () => void
}

const adjustWeekStart = (date: Dayjs) => {
  if (dayjs().day() === 0) {
    return date.startOf("week").day() === 0
      ? date.startOf("week").subtract(6, "day")
      : date.startOf("week")
  } else {
    return date.startOf("week").day() === 0
      ? date.startOf("week").add(1, "day")
      : date.startOf("week")
  }
}

const getDatePresets = (): Array<{
  label: string
  value: Dayjs
}> => [
  { label: "Today", value: dayjs().startOf("day") },
  { label: "Yesterday", value: dayjs().subtract(1, "day").startOf("day") },
  { label: "Start of This Week", value: adjustWeekStart(dayjs()) },
  { label: "Start of Last Week", value: adjustWeekStart(dayjs().subtract(1, "week")) },
  { label: "Start of This Month", value: dayjs().startOf("month") },
  { label: "Start of Last Month", value: dayjs().subtract(1, "month").startOf("month") },
  { label: "Start of This Year", value: dayjs().startOf("year") },
]

const disabledDate = (current: Dayjs) => {
  // Can not select days after today
  return dayjs().endOf("day") < current
}

const CustomFooter: React.FC<CustomFooterProps> = ({ onClick, closePicker }) => {
  return (
    <div className="presets">
      {getDatePresets().map(({ label, value }) => (
        <Button
          key={label}
          size="large"
          onClick={() => {
            onClick(value)
            closePicker()
          }}
        >
          {label}
        </Button>
      ))}
      <Typography variant="caption" sx={{ color: "#000000", opacity: 0.6, fontSize: "0.65rem" }}>
        Transactions before 4:00am belong to previous day
      </Typography>
    </div>
  )
}

export function DatePickerBasic({ value, onChange, disabled = false }: Props) {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(value)
  const [isOpen, setIsOpen] = useState(false)

  const handleDateChange = (date: Dayjs, dateString: string | string[]) => {
    setSelectedDate(date)
    onChange(date, dateString)
  }

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open)
  }

  return (
    <DatePicker
      placement="bottomRight"
      suffixIcon={
        <DateRangeIcon
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        />
      }
      className="date-picker"
      size="large"
      inputReadOnly
      showNow={false}
      open={isOpen}
      allowClear={false}
      value={selectedDate}
      onChange={handleDateChange}
      onOpenChange={handleOpenChange}
      disabledDate={disabledDate}
      disabled={disabled}
      renderExtraFooter={() => (
        <CustomFooter
          onClick={(value) => {
            handleDateChange(value, value.format("YYYY-MM-DD"))
          }}
          closePicker={() => {
            setIsOpen(false)
          }}
        />
      )}
    />
  )
}
