import { Alert, Box, Link, Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"

export const ErrorPasswordUpdate = () => {
  const navigate = useNavigate()

  return (
    <Box sx={{ textAlign: "left", maxWidth: 400, mx: "auto", mt: 4 }}>
      <Alert
        severity="error"
        className="rounded-[4px] animate-shake"
        iconMapping={{
          error: (
            <ErrorOutlineIcon
              sx={{
                fontSize: 20,
                mt: "4px",
              }}
            />
          ),
        }}
      >
        <Typography variant="body1" fontWeight={700}>
          Your reset link is either invalid or has expired, please ensure to:
        </Typography>
        <ul
          style={{
            paddingInlineStart: "20px",
            listStyleType: "disc",
          }}
        >
          <li style={{ marginTop: "8px" }}>Request a new link if needed</li>
          <li style={{ marginTop: "8px" }}>Double-check the link if you copied it manually</li>
          <li style={{ marginTop: "8px" }}>Refer to the most recent reset email</li>
        </ul>
      </Alert>
      <Button
        fullWidth
        variant="contained"
        onClick={() => {
          navigate("/recover")
        }}
        sx={{
          mt: 3,
          mb: 2,
        }}
      >
        Request a New Link
      </Button>

      <Box sx={{ textAlign: "center", width: "100%" }}>
        <Typography
          sx={{
            marginBottom: 2,
          }}
          variant="body2"
        >
          Go back to{" "}
          <Link variant="body2" fontWeight={700} underline="hover" href="/login">
            Login
          </Link>{" "}
          page
        </Typography>
      </Box>
    </Box>
  )
}
