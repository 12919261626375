import { type Location } from "../types/reportTypes"

export interface Business {
  businessName: string
  businessId: string
  phone: string
  secondaryPhone: string
  street: string
  city: string
  zipPostal: string
  locality: string
  country: string
  locations: BusinessLocation[]
}

interface BusinessLocation {
  locationId: string
  softwareName: string
  transactions: Transaction[]
}

interface Transaction {
  name: string
  locationId: string
  terminalName: string
  dateTime: string
  userName: string
  device: string
  type: string
  amount: number
  billCount: number
}

export interface InputJson {
  businesses: Business[]
  locations: Location[]
}

type OutputTransaction = Omit<Transaction, "locationId" | "terminalName"> & {
  terminalName?: string
}

export type OutputJson = Array<{
  businessId: string
  businessName: string
  phone: string
  secondaryPhone: string
  street: string
  city: string
  zipPostal: string
  locality: string
  country: string
  totalBusinessAdd: number
  totalBusinessRemove: number
  totalBusinessStack: number
  totalBusinessBill: number
  locations: Array<{
    locationId: string
    softwareName: string
    totalLocationAdd: number
    totalLocationRemove: number
    totalLocationStack: number
    totalLocationBill: number
    productType: string | undefined | null
    terminals: Array<{
      totalTerminalAdd: number
      totalTerminalRemove: number
      totalTerminalStack: number
      totalTerminalBill: number
      terminalName: string
      locationId: string
      transactions: OutputTransaction[]
    }>
  }>
}>

function formatDevice(device: string): string {
  if (device === "recycler1") {
    return "RC1"
  } else if (device === "recycler2") {
    return "RC2"
  } else if (device === "stacker") {
    return "STK"
  } else if (device === "drawer") {
    return "Drawer"
  }
  return "-"
}

function formatType(type: string): string {
  if (type === "add") {
    return "Add"
  } else if (type === "remove") {
    return "Remove"
  } else if (type === "toStacker") {
    return "Stack"
  }
  return ""
}

export function flattenAdjustments(input: InputJson): OutputJson {
  const output: OutputJson = []

  // copy of the businesses array and sort it by businessName
  const sortedBusinesses = [...input.businesses].sort((a, b) =>
    a.businessName.localeCompare(b.businessName, undefined, { numeric: true })
  )

  sortedBusinesses.forEach((business) => {
    const outputBusiness = output.find((biz) => biz.businessId === business.businessId)

    if (outputBusiness == null) {
      output.push({
        businessId: business.businessId,
        businessName: business.businessName,
        phone: business.phone,
        secondaryPhone: business.secondaryPhone,
        street: business.street,
        city: business.city,
        zipPostal: business.zipPostal,
        locality: business.locality,
        country: business.country,
        totalBusinessAdd: 0,
        totalBusinessBill: 0,
        totalBusinessRemove: 0,
        totalBusinessStack: 0,
        locations: [],
      })
    }

    business.locations.forEach((location) => {
      const outputLocation =
        output
          .find((biz) => biz.businessId === business.businessId)
          ?.locations.find((loc) => loc.locationId === location.locationId) ?? null

      const productType = input.locations.find(
        (loc: Location) => loc.id === location.locationId
      )?.productType

      if (outputLocation == null) {
        ;(output.find((biz) => biz.businessId === business.businessId)?.locations ?? []).push({
          locationId: location.locationId,
          softwareName: location.softwareName,
          totalLocationAdd: 0,
          totalLocationBill: 0,
          totalLocationRemove: 0,
          totalLocationStack: 0,
          productType,
          terminals: [],
        })
      }

      const currentBusinessLocations = output.find(
        (biz) => biz.businessId === business.businessId
      )?.locations
      if (currentBusinessLocations != null) {
        currentBusinessLocations.sort((a, b) =>
          a.locationId.localeCompare(b.locationId, undefined, { numeric: true })
        )
      }

      location.transactions.forEach((transaction) => {
        const formattedDateTime = transaction.dateTime
        const formattedDevice = formatDevice(transaction.device)
        const formattedType = formatType(transaction.type)
        const formattedAmount = transaction.amount

        const outputTerminal =
          output
            .find((biz) => biz.businessId === business.businessId)
            ?.locations.find((loc) => loc.locationId === location.locationId)
            ?.terminals.find((term) => term.terminalName === transaction.terminalName) ?? null

        if (outputTerminal == null) {
          ;(
            output
              .find((biz) => biz.businessId === business.businessId)
              ?.locations.find((loc) => loc.locationId === location.locationId)?.terminals ?? []
          ).push({
            terminalName: transaction.terminalName,
            locationId: transaction.locationId,
            totalTerminalAdd: 0,
            totalTerminalBill: 0,
            totalTerminalRemove: 0,
            totalTerminalStack: 0,
            transactions: [],
          })
        }

        const currentLocationTerminals = output
          .find((biz) => biz.businessId === business.businessId)
          ?.locations.find((loc) => loc.locationId === location.locationId)?.terminals
        if (currentLocationTerminals != null) {
          currentLocationTerminals.sort((a, b) =>
            a.terminalName.localeCompare(b.terminalName, undefined, { numeric: true })
          )
        }

        const currentBusiness = output.find((biz) => biz.businessId === business.businessId)
        if (currentBusiness != null) {
          if (transaction.type === "add") {
            currentBusiness.totalBusinessAdd += transaction.amount
            currentBusiness.totalBusinessBill =
              currentBusiness.totalBusinessBill !== -1
                ? (currentBusiness.totalBusinessBill += transaction.billCount)
                : -1
          } else if (transaction.type === "remove") {
            currentBusiness.totalBusinessRemove += transaction.amount
            currentBusiness.totalBusinessBill =
              currentBusiness.totalBusinessBill !== -1
                ? (currentBusiness.totalBusinessBill += transaction.billCount)
                : -1
          } else if (transaction.type === "toStacker") {
            currentBusiness.totalBusinessStack += transaction.amount
            currentBusiness.totalBusinessBill =
              currentBusiness.totalBusinessBill !== -1
                ? (currentBusiness.totalBusinessBill += transaction.billCount)
                : -1
          }
        }

        const currentLocation =
          output
            .find((biz) => biz.businessId === business.businessId)
            ?.locations.find((loc) => loc.locationId === location.locationId) ?? null
        if (currentLocation != null) {
          if (transaction.type === "add") {
            currentLocation.totalLocationAdd += transaction.amount
            currentLocation.totalLocationBill = currentLocation.totalLocationBill =
              currentLocation.totalLocationBill !== -1
                ? (currentLocation.totalLocationBill += transaction.billCount)
                : -1
          } else if (transaction.type === "remove") {
            currentLocation.totalLocationRemove += transaction.amount
            currentLocation.totalLocationBill =
              currentLocation.totalLocationBill !== -1
                ? (currentLocation.totalLocationBill += transaction.billCount)
                : -1
          } else if (transaction.type === "toStacker") {
            currentLocation.totalLocationStack += transaction.amount
            currentLocation.totalLocationBill =
              currentLocation.totalLocationBill !== -1
                ? (currentLocation.totalLocationBill += transaction.billCount)
                : -1
          }
        }

        const currentTerminal =
          output
            .find((biz) => biz.businessId === business.businessId)
            ?.locations.find((loc) => loc.locationId === location.locationId)
            ?.terminals.find((term) => term.terminalName === transaction.terminalName) ?? null

        if (currentTerminal !== null) {
          if (transaction.type === "add") {
            currentTerminal.totalTerminalAdd += transaction.amount
            currentTerminal.totalTerminalBill += transaction.billCount
            currentTerminal.totalTerminalBill =
              currentTerminal.totalTerminalBill !== -1
                ? (currentTerminal.totalTerminalBill += transaction.billCount)
                : -1
          } else if (transaction.type === "remove") {
            currentTerminal.totalTerminalRemove += transaction.amount
            currentTerminal.totalTerminalBill =
              currentTerminal.totalTerminalBill !== -1
                ? (currentTerminal.totalTerminalBill += transaction.billCount)
                : -1
          } else if (transaction.type === "toStacker") {
            currentTerminal.totalTerminalStack += transaction.amount
            currentTerminal.totalTerminalBill =
              currentTerminal.totalTerminalBill !== -1
                ? (currentTerminal.totalTerminalBill += transaction.billCount)
                : -1
          }

          currentTerminal.transactions.push({
            name: transaction.name,
            dateTime: formattedDateTime, // Formatted dateTime value
            userName: transaction.userName !== "" ? transaction.userName : "-",
            device: formattedDevice,
            type: formattedType,
            amount: formattedAmount,
            billCount: transaction.billCount,
            terminalName: transaction.terminalName,
          })

          // sort transaction
          // currentTerminal.transactions.sort(
          //   (a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
          // )
        } else {
          console.warn("Current terminal not found.")
        }
      })
    })
  })
  console.log("Flattened adjustments: ", output)
  return output
}
