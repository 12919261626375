import React from "react"
import { CustomDialog } from "./CustomDialog"

interface InactiveSessionDialogProps {
  timeLeft: number
  open: boolean
  onPrimaryAction: () => void
  onSecondaryAction: () => void
}

const InactiveSessionDialog: React.FC<InactiveSessionDialogProps> = ({
  timeLeft,
  open,
  onPrimaryAction,
  onSecondaryAction,
}) => {
  return (
    <CustomDialog
      open={open}
      title="Session Timeout Warning"
      dialogContentText={`Your session is about to expire due to inactivity. Do you want to stay logged in?`}
      primaryActionText={`Yes (${timeLeft})`}
      secondaryActionText="No"
      onPrimaryAction={onPrimaryAction}
      onSecondaryAction={onSecondaryAction}
      fullScreen={false}
      disableHandleClose={true}
      hideCloseIcon={true}
    />
  )
}

export default InactiveSessionDialog
